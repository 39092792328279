import React, { useState, useEffect } from 'react';
import { t } from 'react-i18nify';
import { apiBaseUrl } from '../utils';
import VpsHeader from './VpsHeader';

export default function Signup(props) {
    const { setLastScreen, language, notify } = props;
    useEffect(() => {  
        setLastScreen('SIGN_UP');
    }, [setLastScreen]);

    const initialSubmitButtonText = t('SIGN_UP');
    const [submitButtonText, setSubmitButtonText] = useState("");
    useEffect(() => { 
      setSubmitButtonText(initialSubmitButtonText);
    }, [initialSubmitButtonText])
  
    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmitButtonText(t('sending'));
        const { email, password, displayName } = e.target.elements;
        const details = {
            email: email.value,
            password: password.value,
            displayName: displayName.value,
            locale: language.includes('fr') ? 'fr-FR' : language.includes('es') ? 'es-ES' : 'en-US', 
        };
        fetch(apiBaseUrl + "/auth/signup", {
            method: "POST",
            credentials: "include",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(details),
        })
        .then(async response => {
            if (!response.ok) {
                const result = await response.json();
                notify({
                    message: result.err,
                    level: 'error',
                });
            } else {
                notify({
                    message: t('Activate_account_email_sent_message'),
                    level: 'info',
                });
            }
            setSubmitButtonText(initialSubmitButtonText);
        })
        .catch(err => {
            setSubmitButtonText(initialSubmitButtonText);
            console.log(err);
            notify({
                message: t('GENERIC_ERROR_MESSAGE'),  //err.message,
                level: 'error',
            });
        })
        //setOption({ signupDialog: false });
    };
  
    return (
        <VpsHeader {...props}>

        <section className="flex flex-col max-w-[480px] mx-auto">
            <form className="form-horizontal card p-2 bg-light" onSubmit={handleSubmit}>
                <div className="form-group row m-1">
                    <label className="control-label col-sm-4" htmlFor="email">{ t('Email') }</label>
                    <div className="col-sm-8">
                        <input className="form-control" type="email" name="email" required />
                    </div>
                </div>
                <div className="form-group row m-1">
                    <label className="control-label col-sm-4" htmlFor="password">{ t('Password') }</label>
                    <div className="col-sm-8">
                        <input className="form-control" type="password" name="password" required />
                    </div>
                </div>
                <div className="form-group row m-1">
                    <label className="control-label col-sm-4" htmlFor="displayName">{ t('Display_Name') }</label>
                    <div className="col-sm-8">
                        <input className="form-control" type="text" name="displayName" required />
                    </div>
                </div>
                <div className="col-sm-12 text-center my-3">
                    <button className="btn mb-3 mr-3 btn-info" type="submit">{submitButtonText}</button>
                </div>
            </form>
        </section>

        </VpsHeader>
);
}
