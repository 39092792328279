import React, {useState, useEffect} from 'react';
import { t } from 'react-i18nify';
import { apiBaseUrl } from '../utils';
import VpsHeader from './VpsHeader';
import VpsFooter from './VpsFooter';

export default function ResetPassword(props) {
    const { setLastScreen, language, notify } = props;
    useEffect(() => {  
        setLastScreen('Reset_Password');
    }, [setLastScreen]);
  
    const [submitButtonText, setSubmitButtonText] = useState(undefined);
    const [resendButtonText, setResendButtonText] = useState(undefined);
    const [userId, setUserId] = useState(undefined);
  
    useEffect(() => {
        if (!userId && language) {
            const token = window.location.pathname.split("/").pop();
            fetch(apiBaseUrl + "/auth/resetPasswordCheck", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ 
                    resetPasswordToken: token, 
                    locale: language.includes('fr') ? 'fr-FR' : language.includes('es') ? 'es-ES' : 'en-US', 
                }),
            })
            .then(async response => {
                if (!response.ok) {
                    const result = await response.json();
                    notify({
                        message: result.err,
                        level: 'error',
                    });
                    if (response.status === 406) {
                        setUserId(result.id);
                        setResendButtonText(t('Resend_Password_Reset_Link'));
                        setSubmitButtonText(undefined);
                    }
                } else {
                    const data = await response.json();
                    setUserId(data.id);
                    setSubmitButtonText(t('Submit'));
                }
            })
            .catch(err => {
                console.log(err);
                notify({
                    message: t('GENERIC_ERROR_MESSAGE'),   //err.message
                    level: 'error',
                });
            })
        }
    }, [userId, language, notify]);
  
    const handleResendLink = async (e) => {
        if (!userId) return;
        setResendButtonText(t('sending'));
        fetch(apiBaseUrl + "/auth/resendPasswordResetLink", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ 
                userId: userId, 
                locale: language.includes('fr') ? 'fr-FR' : language.includes('es') ? 'es-ES' : 'en-US',  
            }),
        })
        .then(async response => {
            setResendButtonText(t('Resend_Password_Reset_Link'));
            if (!response.ok) {
                const result = await response.json();
                notify({
                    message: result.err,
                    level: 'error',
                });
            } else {
                setResendButtonText(undefined);
                notify({
                    message: t('Password_reset_email_sent_message'),
                    level: 'info',
                });
            }
        })
        .catch(err => {
            setResendButtonText(t('Resend_Password_Reset_Link'));
            console.log(err);
            notify({
                message: t('GENERIC_ERROR_MESSAGE'),   //err.message
                level: 'error',
            });
        })
    };
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        const { password, password1 } = e.target.elements;
        if (password.value !== password1.value) {
            notify({
                message: t('Passwords_not_match'),
                level: 'error',
            });
            return;
        }
        setSubmitButtonText(t('sending'));
        const token = window.location.pathname.split("/").pop();
        const details = {
            resetPasswordToken: token,
            password: password.value,
            locale: language.includes('fr') ? 'fr-FR' : language.includes('es') ? 'es-ES' : 'en-US', 
        };
        fetch(apiBaseUrl + "/auth/resetPassword", {
            method: "POST",
            credentials: "include",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(details),
        })
        .then(async response => {
            setSubmitButtonText(t('Submit'));
            if (!response.ok) {
                const result = await response.json();
                notify({
                    message: result.err,
                    level: 'error',
                });
            } else {
                setSubmitButtonText(undefined);
                notify({
                    message: t('Password_reset_message'),
                    level: 'info',
                });
            }
        })
        .catch(err => {
            setSubmitButtonText(t('Submit'));
            console.log(err);
            notify({
                message: t('GENERIC_ERROR_MESSAGE'),   //err.message
                level: 'error',
            });
        })
        //setOption({ resetPasswordDialog: false });
    };
    
    return (
        <VpsHeader {...props}>
            <section className="flex flex-col max-w-[480px] mx-auto">
                {submitButtonText && (
                    <form className="form-horizontal card p-3" onSubmit={handleSubmit}>
                        <div className="form-group row m-1">
                            <label className="control-label col-sm-3" htmlFor="password">{ t('New_Password') }</label>
                            <div className="col-sm-9">
                                <input className="form-control" type="password" name="password" required />
                            </div>
                            <label className="control-label col-sm-3 my-2" htmlFor="password">{ t('Retype_Password') }</label>
                            <div className="col-sm-9 my-2">
                                <input className="form-control" type="password" name="password1" required />
                            </div>
                            <div className="col-sm-12 text-center my-3">
                                <button className="btn mb-3 mr-3 btn-info" type="submit">{submitButtonText}</button>
                            </div>
                        </div>
                    </form>
                )}

                {resendButtonText && (
                    <div className="form-group row m-3">
                        <button className="btn mb-3 mr-3 btn-info" onClick={handleResendLink}>{resendButtonText}</button>
                    </div>
                )}
                
                {!submitButtonText && (
                    <div className="form-group row m-3">
                        <form><p/>
                            <button className="btn mb-3 mr-3 btn-info" formaction="/Signin">{ t('Go_sign_in') }</button>
                        </form>
                    </div>
                )}
            </section>
            <VpsFooter {...props} />
        </VpsHeader>
    );
}
