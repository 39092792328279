import React from "react";
import { useNavigate } from 'react-router-dom';
import { t } from 'react-i18nify';
//import { LazyLoadComponent } from 'react-lazy-load-image-component';
import { MdOutlineFilter, MdOutlineMenuBook, MdMusicVideo, MdOutlineVideoCameraFront } from "react-icons/md";
import{ Space, Button } from 'antd';

const About1 = () => {
  //const videoplusStudioVideo1 = "https://www.youtube.com/embed/d6Rh_0A9YHw";
  const navigate = useNavigate();
  
  return (
    <>
      <section id="about">
        <div className="container mx-auto">
          <div className="-mx-4 flex flex-wrap">
            <div className="w-full px-4">
              <div className="mx-auto mb-4 text-center">
                <h2 className="m-5 text-2xl font-bold">
                  { t('app_headline0') }
                </h2>

                <div className="-mx-4 flex flex-col 2xl:flex-row justify-between items-center">
                  <div className="p-4 max-w-md m-2 bg-sky-100 rounded-xl shadow-lg flex flex-col items-center text-black">
                    <div className="my-1">
                      <Space wrap>
                        <MdOutlineFilter size={30} />
                        <Button className="text-xl font-medium" type="link" onClick={() => navigate('/NewVideoEffectTask')}>{t('Video_Effect')}</Button>
                      </Space>
                    </div>
                    <div>
                      {t('app_highlight1')}
                    </div>
                  </div>
                  <div className="p-4 max-w-md m-2 bg-sky-100 rounded-xl shadow-lg flex flex-col items-center text-black">
                    <div className="my-1">
                      <Space wrap>
                        <MdOutlineMenuBook size={30} />
                        <Button className="text-xl font-medium" type="link" onClick={() => navigate('/NewStorybookTask')}>{t('Storybook')}</Button>
                      </Space>
                    </div>
                    <div>
                      {t('app_highlight2')}
                    </div>
                  </div>
                  <div className="p-4 max-w-md m-2 bg-sky-100 rounded-xl shadow-lg flex flex-col items-center text-black">
                    <div className="my-1">
                      <Space wrap>
                        <MdMusicVideo size={30} />
                        <Button className="text-xl font-medium" type="link" onClick={() => navigate('/NewTranscriptionTask')}>{t('Transcription')}</Button>
                      </Space>
                    </div>
                    <div>
                      {t('app_highlight3')}
                    </div>
                  </div>
                  <div className="p-4 max-w-md m-2 bg-sky-100 rounded-xl shadow-lg flex flex-col items-center text-black">
                    <div className="my-1">
                      <Space wrap>
                        <MdOutlineVideoCameraFront size={33} />
                        <Button className="text-xl font-medium" type="link" onClick={() => navigate('/VideoSubtitleToSpeech')}>{t('TTS')}</Button>
                      </Space>
                    </div>
                    <div>
                      {t('app_highlight4')}
                    </div>
                  </div>
                </div>
                {/*<span className="mb-4 block text-lg font-semibold">
                  { t('app_headline2') }
                </span>
                <a href="/app" className="btn btn-info btn-lg">
                  { t('app_cta1') }
                </a>*/}
              </div>
            </div>
            {/*
            <div className="w-full px-4 lg:w-6/12">
              <div className="-mx-3 flex items-center sm:-mx-4">
                <div className="mt-5 embed-responsive embed-responsive-16by9"> 
                  <LazyLoadComponent>
                    <iframe width="560" height="315" src={videoplusStudioVideo1} title={ t('Videooplus_Studio_demo_video') } allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>             
                  </LazyLoadComponent>
                </div>
              </div>
            </div>
            */}
          </div>
        </div>
        {/*
        <div className="flex flex-row items-center">
          <div className="mx-auto mb-4 text-center">
            <a href="https://www.producthunt.com/posts/videoplus-studio-2-0?embed=true&utm_source=badge-featured&utm_medium=badge&utm_souce=badge-videoplus&#0045;studio&#0045;2&#0045;0" target="_blank" rel="noreferrer"><img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=461840&theme=light" alt="VideoPlus&#0032;Studio&#0032;2&#0046;0 - Cartoonize&#0032;videos&#0044;&#0032;add&#0032;AI&#0032;voiceover&#0032;in&#0032;80&#0032;languages&#0032;and&#0032;more | Product Hunt" width="250" height="54" /></a>
          </div>
        </div>
        */}
      </section>
    </>
  );
};

export default About1;
