import React, { useState, useMemo, useCallback } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { t } from 'react-i18nify';
import styled from 'styled-components';
import Carousel from 'react-bootstrap/Carousel';
import { MAX_DOCUMENT_FILE_SIZE, apiBaseUrl, openDocument } from '../utils';

const Style = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    padding: 1% 2% 3% 2%;

    .video {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        position: relative;

        video {
            position: relative;
            /*z-index: 10;*/
            outline: none;
            max-height: 100%;
            max-width: 100%;
            box-shadow: 0px 5px 25px 5px rgb(0 0 0 / 80%);
            background-color: #000;
            cursor: pointer;
        }

        .subtitle {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: absolute;
            /*z-index: 20;*/
            left: 0;
            right: 0;
            bottom: 27px;
            width: 100%;
            padding: 0 20px;
            user-select: none;
            pointer-events: none;

            .operate {
                padding: 5px 15px;
                color: #fff;
                font-size: 13px;
                border-radius: 3px;
                margin-bottom: 5px;
                background-color: rgb(0 0 0 / 75%);
                border: 1px solid rgb(255 255 255 / 20%);
                cursor: pointer;
                pointer-events: all;
            }

            .textarea {
                width: 100%;
                outline: none;
                resize: none;
                text-align: start;
                line-height: 1.2;
                border: none;
                color: #fff;
                font-size: 20px;
                padding: 5px 10px;
                user-select: all;
                pointer-events: all;
                background-color: rgb(0 0 0 / 70%);
                text-shadow: rgb(0 0 0) 1px 0px 1px, rgb(0 0 0) 0px 1px 1px, rgb(0 0 0) -1px 0px 1px,
                    rgb(0 0 0) 0px -1px 1px;
            }
        }
    }
`;

export default function ImagePlayer(props) {
    const [currentSub, setCurrentSub] = useState(null);
    const [focusing, setFocusing] = useState(false);
    const [inputItemCursor, setInputItemCursor] = useState(0);

    const handleSelect = (selectedIndex) => {
        props.setCurrentIndex(selectedIndex);
    };

    useMemo(() => {
        setCurrentSub(props.subtitle[props.currentIndex]);
    }, [props.subtitle, props.currentIndex]);

    const onChange = useCallback(
        (event) => {
            props.updateSub(currentSub, { text: event.target.value });
            if (event.target.selectionStart) {
                setInputItemCursor(event.target.selectionStart);
            }
        },
        [props, currentSub],
    );

    const onClick = useCallback(
        (event) => {
            if (event.target.selectionStart) {
                setInputItemCursor(event.target.selectionStart);
            }
        },
        [props],
    );

    const onFocus = useCallback((event) => {
        setFocusing(true);
        if (event.target.selectionStart) {
            setInputItemCursor(event.target.selectionStart);
        }
    }, []);

    const onBlur = useCallback(() => {
        setTimeout(() => setFocusing(false), 500);
    }, []);

    return (
        <Style className="player">
            <div className="video">
                {(props.carouselData && props.currentIndex >= 0) ? (
                    <Carousel data-bs-theme={props.carouselData.type.split('/')[0] === 'image' ? "light" : "dark"} interval={null} activeIndex={props.currentIndex} onSelect={handleSelect}>
                        {props.subtitle.map((item, index) => (
                        <Carousel.Item key={index}>
                            <img crossOrigin="anonymous" 
                                className="img-fluid fit-height"
                                src={`${apiBaseUrl}/files/${props.carouselData.dir}/${item.fname}?${Date.now()}`}
                                alt={t('slide_num', {num: index+1})}
                            />
                            {/*<Carousel.Caption>
                                {item.text}
                            </Carousel.Caption>
                            */}
                        </Carousel.Item>
                        ))}                
                    </Carousel>
                ) : (
                    <div id="openImage" className="flex flex-col items-center max-w-[480px] mx-auto">
                        <div className="file">
                            <input type="file" accept="application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, 
                                application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, 
                                application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, 
                                application/vnd.oasis.opendocument.presentation, application/vnd.oasis.opendocument.spreadsheet, application/vnd.oasis.opendocument.text, 
                                application/pdf, application/rtf, application/xml, text/csv, text/html, text/plain, image/*" 
                                onChange={event => {if (event.target.files) openDocument(event.target.files[0], props)}} />
                        </div>
                        <div className="info">
                            { t('open_document_info', {maxSize: MAX_DOCUMENT_FILE_SIZE/1048576}) }
                            <p/>{ t('open_document_extra_info') }
                        </div>
                    </div>
                )}
                {props.carouselData && currentSub && (
                    <div className="subtitle">
                        <TextareaAutosize
                            className="textarea"
                            value={currentSub.text}
                            onChange={onChange}
                            onClick={onClick}
                            onFocus={onFocus}
                            onBlur={onBlur}
                            onKeyDown={onFocus}
                            spellCheck={false}
                            maxRows={3}
                        />
                    </div>
                )}
            </div>
        </Style>
    );
}
