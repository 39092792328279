import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import { t } from 'react-i18nify';
import { Select } from 'antd';
import { sub2vtt, sub2srt, sub2txt } from '../libs/readSub';
import sub2ass from '../libs/readSub/sub2ass';
import { download } from '../utils';

const Style = styled.div`
    .export {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 6px;
        border-bottom: 1px solid rgb(255 255 255 / 20%);

        .title {
            color: #fff;
            font-size: 14px;
            padding: 5px 10px;
            margin-bottom: 10px;
            border-left: 2px solid #03a9f4;
        }

        .btn {
            position: relative;
            opacity: 0.85;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 35px;
            width: 88%;
            border-radius: 10px;
            margin: 20px;
            color: #fff;
            cursor: pointer;
            font-size: 13px;
            background-color: #4169E1;
            transition: all 0.2s ease 0s;

            &:hover {
                opacity: 1;
            }
        }

        .warning {
            font-size: 12px;
            padding: 5px;
            color: #fff;
            background-color: rgba(199, 81, 35, 0.5);
            border: 1px solid rgba(199, 81, 35, 1);
        }
    }
`;

export default function ExportSub({ subtitle, setOption }) {
    const types = {'json': '.json', 'srt': '.srt', 'vtt': '.vtt', 'ass': '.ass', 'txt': '.txt'}; 
    const [type, setType] = useState('json');
    const downloadSub = useCallback(() => {
        let text = '';
        const name = `${Date.now()}.${type}`;
        switch (type) {
            case 'vtt':
                text = sub2vtt(subtitle);
                break;
            case 'srt':
                text = sub2srt(subtitle);
                break;
            case 'ass':
                text = sub2ass(subtitle);
                break;
            case 'txt':
                text = sub2txt(subtitle);
                break;
            case 'json':
                text = JSON.stringify(subtitle);
                break;
            default:
                break;
        }
        const url = URL.createObjectURL(new Blob([text]));
        download(url, name);
        setOption({ exportSubDialog: false });
    }, [subtitle, type, setOption]);

    return (
        <Style>
            <div className="export">
                <div className="title">
                    {t("EXPORT_hint")}
                </div>
                <div className="row m-1 text-white">
                    {t("Subtitle_Format")}
                </div>
                <Select popupMatchSelectWidth={false} value={type} onChange={(value) => setType(value)}>
                    {Object.keys(types).map(key => (
                        <Select.Option key={key} value={key}>{types[key]}</Select.Option>
                    ))}
                </Select>
                <div className="warning">
                    {t("Json_note")}
                </div>
                <div className="btn" onClick={downloadSub}>
                    {t("Confirm")}
                </div>
            </div>
        </Style>
    );
}
