import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { t } from 'react-i18nify';
import RangeSlider from 'react-bootstrap-range-slider';
import{ Radio, Space, Tooltip, InputNumber, Select } from 'antd';
import { MdHelpOutline } from "react-icons/md";
 //import debounce from 'lodash/debounce';
import { axiosInstance, AUDIO_STEMS_OPTIONS } from '../utils';
import VpsHeader from './VpsHeader';

export default function SubmitVideoSTSTask(props) {
    const { setLastScreen, setResultMessage, authenticated, sid, language, notify, options, videoPlayer, subtitle } = props;
    useEffect(() => {  
        setLastScreen('Submit_Task');
    }, [setLastScreen]);
    const navigate = useNavigate();

    const [keepNaturalSpeedOfVideo, setKeepNaturalSpeedOfVideo] = useState(true);
    const [keepExistingAudio, setKeepExistingAudio] = useState(true);
    const [audioStemsToKeep, setAudioStemsToKeep] = useState('all');
    const [suppressUnderlyingAudio, setSuppressUnderlyingAudio] = useState(false);
    const [burnSubtitles, setBurnSubtitles] = useState(false);
    const [fontSize, setFontSize] = useState(15);
    const [description, setDescription] = useState('');
    const [creating, setCreating] = useState(false);
    const [outputLength, setOutputLength] = useState(0);

    const minLength = 1.0;
    const maxLength = useRef(1.0);
    useEffect(() => {
        setDescription(options.videoUrl);
        maxLength.current = +videoPlayer.current?.duration.toFixed(3);
        setOutputLength(maxLength.current);
    }, [videoPlayer, options]);
  
    const onChangeOfKeepNaturalSpeedOfVideo = event => {
        setKeepNaturalSpeedOfVideo(event.target.value);
      };
    
    const onChangeOfKeepExistingAudio = event => {
        setKeepExistingAudio(event.target.checked);
    } 
    
    const onChangeOfSuppressUnderlyingAudio = event => {
        setSuppressUnderlyingAudio(event.target.checked);
    } 
    
    const onChangeOfBurnSubtitles = event => {
        setBurnSubtitles(event.target.checked);
    } 
    
    const onChangeOfFontSize = event => {
        setFontSize(+event.target.value);
    } 

    const onChangeOfDescription = event => {
        setDescription(event.target.value);
    } 

    const saveTask = async () => {
        if (!videoPlayer.current.src) {
            notify({
                message: t('ERROR_MESSAGE_MISSING_VIDEO_FILE'),
                level: 'error',
            });
            return;
        }
        if (subtitle.length < 1) {
            notify({
                message: t('ERROR_MESSAGE_MISSING_SUBTITLES'),
                level: 'error',
            });
            return;
        }
        if (!outputLength || outputLength < minLength || outputLength > maxLength.current) {
            notify({
                message: t('ERROR_MESSAGE_INVALID_OUTPUT_LENGTH', {'minLength': minLength, 'maxLength': maxLength.current}),
                level: 'error',
            });
            return;
        }

        setCreating(true);

        let formData = new FormData();
        formData.append("sid", sid);
        formData.append("locale", language.includes('fr') ? 'fr-FR' : language.includes('es') ? 'es-ES' : 'en-US');
        formData.append("burnSubtitles", burnSubtitles);
        if (burnSubtitles) {
            formData.append("fontSize", fontSize);
        }
        formData.append("outputLength", outputLength);
        formData.append("description", description);
    
        const text = JSON.stringify(subtitle);
        const subtitleFile = new File([text], 'subtitle.json');
        formData.append("file", subtitleFile);

        const result = await fetch(videoPlayer.current.src);
        const blob = await result.blob();
        const videoFile = new File([blob], 'video.mp4');
        formData.append("keepNaturalSpeedOfVideo", keepNaturalSpeedOfVideo);
        formData.append("keepExistingAudio", keepExistingAudio);
        formData.append("audioStemsToKeep", audioStemsToKeep);
        formData.append("suppressUnderlyingAudio", suppressUnderlyingAudio);
        formData.append("file", videoFile);

        axiosInstance.post("/saveTask", formData, {  
            headers: {
                "Content-Type": "multipart/form-data",
            },
        }).then((response) => {
            //notify({
            //    message: response.data.message,
            //    level: 'success',
            //});
            setCreating(false);
            //setOption({ newTaskDialog: false, taskDialog: true });
            setResultMessage(response.data.message);
            navigate('/TaskResultSuccess');
        })
        .catch((reason) => {
            if (typeof reason.response != 'undefined' && typeof reason.response.data != 'undefined') {
                notify({
                    message: reason.response.data.err ? reason.response.data.err : reason.response.data.message,
                    level: 'error',
                });
            } else {
                notify({
                    message: t('ERROR_MESSAGE_UNKNOWN_REASON', { reason: reason }),
                    level: 'error',
                });
            }
            setCreating(false);
        })
    }
    //const saveTask2 = debounce(saveTask, 3000);
    //const saveTask3 = useCallback(saveTask2, []);   //to prevent duplicate submits
    
    return (
        <VpsHeader {...props}>
            <div>
                <div className="flex mx-2 mb-2">
                    { t("Save_video_STS_task_info") }&nbsp;{ t('Save_task_info_extra') }
                </div>
                {/*<div className="row m-2">
                    { t("Save_task_instruction") }
                </div>*/}
                
                <div className="row m-2">
                    <div className="col-md-3">
                        <Space wrap>
                            { t('Keep_Natural_Speed_of_Video_or_Speech') }
                        </Space>
                    </div>
                    <div className="col-md-9">
                        <Radio.Group onChange={onChangeOfKeepNaturalSpeedOfVideo} value={keepNaturalSpeedOfVideo}>
                            <Space direction="vertical">
                                <Radio value={true}>{ t('Adjust_speech_speed_to_fit_video') }</Radio>
                                <Radio value={false}>{ t('Adjust_video_speed_to_fit_speech') }</Radio>
                            </Space>
                        </Radio.Group>
                    </div>
                </div>

                <div className="row m-2">
                    <div className="col-md-3">
                        <Space wrap>
                            { t('Keep_Existing_Audio') }
                            <Tooltip title={t('Keep_Existing_Audio_tip')} color='purple'><MdHelpOutline /></Tooltip>
                        </Space>
                    </div>
                    <div className="col-md-9">
                        <input type="checkbox" checked={keepExistingAudio} onChange={onChangeOfKeepExistingAudio} />
                    </div>
                </div>
                {keepExistingAudio && (
                    <div className="row m-2">
                        <div className="col-md-3">
                            <Space wrap>
                                &nbsp;&nbsp;&nbsp;&nbsp;{ t('Audio_Stems_to_Keep') }
                                <Tooltip title={t('Audio_Stems_to_Keep_tip')} color='purple'><MdHelpOutline /></Tooltip>
                            </Space>
                        </div>
                        <div className="col-md-9">
                            <Select popupMatchSelectWidth={false} value={audioStemsToKeep} onChange={(value) => setAudioStemsToKeep(value)}>
                                {AUDIO_STEMS_OPTIONS.map(item => (
                                    <Select.Option key={item.value} value={item.value}>{ t('AUDIO_STEMS_OPTIONS_' + item.value) }</Select.Option>
                                ))}
                            </Select>
                        </div>
                    </div>
                )}
                {keepExistingAudio && (
                    <div className="row m-2">
                        <div className="col-md-3">
                            <Space wrap>
                                &nbsp;&nbsp;&nbsp;&nbsp;{ t('Suppress_Underlying_Audio') }
                                <Tooltip title={t('Suppress_Underlying_Audio_tip')} color='purple'><MdHelpOutline /></Tooltip>
                            </Space>
                        </div>
                        <div className="col-md-9">
                            <input type="checkbox" checked={suppressUnderlyingAudio} onChange={onChangeOfSuppressUnderlyingAudio} />
                        </div>
                    </div>
                )}
                
                <div className="row m-2">
                    <div className="col-md-3">
                        <Space wrap>
                            { t('Burn_Subtitles') }
                            <Tooltip title={t('Burn_Subtitles_tip')} color='purple'><MdHelpOutline /></Tooltip>
                        </Space>
                    </div>
                    <div className="col-md-9">
                        <input type="checkbox" checked={burnSubtitles} onChange={onChangeOfBurnSubtitles} />
                    </div>
                </div>
                {burnSubtitles && (
                    <div className="row m-2">
                        <div className="col-md-3">
                            &nbsp;&nbsp;&nbsp;&nbsp;{ t('Font_Size') }
                        </div>
                        <div className="col-md-9">
                            <div className="input-group mb-3">
                                <RangeSlider min={10} max={20} step={1} value={fontSize} onChange={onChangeOfFontSize} />&nbsp;&nbsp;
                                <div className="input-group-append">
                                    <span className="input-group-text ml-2">
                                        {fontSize}
                                    </span>
                                </div>&nbsp;&nbsp;&nbsp;&nbsp;
                                <p style={{fontSize: `${fontSize}px`}}>{t('sample_text')}</p>
                            </div>
                        </div>
                    </div>
                )}

                <div className="row m-2">
                    <div className="col-md-3">
                        <Space wrap>
                            { t('Output_Length') }
                            <Tooltip title={t('Output_Length_tip')} color='purple'><MdHelpOutline /></Tooltip>
                        </Space>
                    </div>
                    <div className="col-md-9">
                        {/*
                        <div className="input-group mb-3">
                            <div className="input-group-append">
                                <span className="input-group-text ml-2">
                                    <input className="text-black" type="number" min={minLength} max={maxLength.current} step={0.001} value={outputLength} onChange={onChangeOfOutputLength} />&nbsp;&nbsp;{t('seconds')}
                                </span>
                            </div>
                        </div>*/}
                        <InputNumber
                            min={minLength} max={maxLength.current} step={0.01}
                            value={outputLength}
                            addonAfter={t('seconds')}
                            onChange={(value) => setOutputLength(value)}
                        />
                    </div>
                </div>

                <div className="row m-2">
                    <div className="col-md-3">
                        <Space wrap>
                            { t('Description') }
                            <Tooltip title={t('Description_tip')} color='purple'><MdHelpOutline /></Tooltip>
                        </Space>
                    </div>
                    <div className="col-md-9">
                        <input type="text" className="form-control w-75" value={description} onChange={onChangeOfDescription} />
                    </div>
                </div>

                <div className="row m-3">
                    <div className="col-12 text-center">
                        <button
                            className="btn mb-3 mr-3 btn-info btn-lg"
                            onClick={() => saveTask()}
                            disabled={!authenticated || creating}
                        >
                            { creating ? t('sending') : t('Submit') }
                        </button><br/>
                        {!authenticated && (
                            t("Sign_in_to_submit_task")
                        )}
                    </div>
                </div>
            </div>
        </VpsHeader>
    );
}
