import React, { useState } from "react";
import { t } from 'react-i18nify';
import { LazyLoadComponent } from 'react-lazy-load-image-component';

const UseCases = (props) => {
  return (
    <section id="useCases" className="relative overflow-hidden py-10">
      <div className="container mx-auto">
        <div className="-mx-4 flex flex-wrap">
          <div className="w-full px-4">
            <div className="mx-auto mb-5 max-w-[520px] text-center">
              <h4 className="mb-4 text-xl font-bold">
                { t('Use_Cases') }
              </h4>
            </div>
          </div>
        </div>

        <div className="-mx-4 flex flex-wrap">
          <div className="w-full px-4 lg:w-1/2">
            <AccordionItem
              header={ t('Use_Case3_title') }
              text={ t('Use_Case3') }
            />
            <AccordionItem
              header={ t('Use_Case7_title') }
              text={ t('Use_Case7') }
            />
            <AccordionItem
              header={ t('Use_Case8_title') }
              text={ t('Use_Case8') }
            />
            <AccordionItem
              header={ t('Use_Case6_title') }
              text={ t('Use_Case6') }
            />
            <AccordionItem
              header={ t('Use_Case5_title') }
              text={ t('Use_Case5') }
            />
            <AccordionItem
              header={ t('Use_Case1_title') }
              text={ t('Use_Case1') }
            />
            <AccordionItem
              header={ t('Use_Case2_title') }
              text={ t('Use_Case2') }
            />
            <AccordionItem
              header={ t('Use_Case4_title') }
              text={ t('Use_Case4') }
            />
          </div>
          <div className="w-full px-4 lg:w-1/2">
            <AccordionItem
              header={ t('Use_Case9_title') }
              text={ t('Use_Case9') }
            />
            <AccordionItem
              header={ t('Use_Case10_title') }
              text={ t('Use_Case10') }
            />
            <AccordionItem
              header={ t('Use_Case11_title') }
              text={ t('Use_Case11') }
            />
            <AccordionItem
              header={ t('Use_Case12_title') }
              text={ t('Use_Case12') }
            />
            <AccordionItem
              header={ t('Use_Case13_title') }
              text={ t('Use_Case13') }
            />
            <AccordionItem
              header={ t('Use_Case14_title') }
              text={ t('Use_Case14') }
            />
            <AccordionItem
              header={ t('Use_Case15_title') }
              text={ t('Use_Case15') }
            />
            <AccordionItem
              header={ t('Use_Case16_title') }
              text={ t('Use_Case16') }
            />
          </div>
        </div>
      </div>

      {props.videoFeeds.length > 0 && (
        <div className="container mx-auto pt-10">
          <div className="-mx-4 flex flex-wrap">
            <div className="w-full px-4">
              <div className="mx-auto mb-5 max-w-[520px] text-center">
                <h4 className="text-xl font-bold">
                  { t('Special_Use_Case') }
                </h4>
              </div>
            </div>
          </div>
          <div className="flex lg:flex-row flex-col">
            <div className="w-full p-2 lg:w-1/2 text-center">
              <div className="overflow-hidden rounded-lg">
                <div className="embed-responsive embed-responsive-16by9"> 
                  <LazyLoadComponent>
                    <iframe width="560" height="315" src={props.videoFeeds[0].href} title={props.videoFeeds[0].title} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>             
                  </LazyLoadComponent>
                </div>
              </div>
              <p align="left">{props.videoFeeds[0].title}</p>
            </div>
            <div className="w-full p-2 lg:w-1/2 text-center">
              <div className="overflow-hidden rounded-lg">
                <div className="embed-responsive embed-responsive-16by9"> 
                  <LazyLoadComponent>
                    <iframe width="560" height="315" src={props.videoFeeds[1].href} title={props.videoFeeds[1].title} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>             
                  </LazyLoadComponent>
                </div>
              </div>
              <p align="left">{props.videoFeeds[1].title}</p>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default UseCases;

const AccordionItem = ({ header, text }) => {
  const [active, setActive] = useState(false);

  const handleToggle = () => {
    //event.preventDefault();
    setActive(!active);
  };
  return (
    <div className="mb-2 w-full rounded-lg bg-white p-2 shadow-[0px_20px_95px_0px_rgba(201,203,204,0.30)] dark:bg-dark-2 dark:shadow-[0px_20px_95px_0px_rgba(0,0,0,0.30)] sm:p-8 lg:px-6 xl:px-8">
      <button
        className={`faq-btn flex w-full text-left`}
        onClick={() => handleToggle()}
      >
        <div className="mr-5 flex h-10 w-full max-w-[40px] items-center justify-center rounded-lg bg-primary/5 dark:text-white dark:bg-white/5">
          <svg
            className={`fill-primary stroke-primary duration-200 ease-in-out ${
              active ? "rotate-180" : ""
            }`}
            width="17"
            height="10"
            viewBox="0 0 17 10"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.28687 8.43257L7.28679 8.43265L7.29496 8.43985C7.62576 8.73124 8.02464 8.86001 8.41472 8.86001C8.83092 8.86001 9.22376 8.69083 9.53447 8.41713L9.53454 8.41721L9.54184 8.41052L15.7631 2.70784L15.7691 2.70231L15.7749 2.69659C16.0981 2.38028 16.1985 1.80579 15.7981 1.41393C15.4803 1.1028 14.9167 1.00854 14.5249 1.38489L8.41472 7.00806L2.29995 1.38063L2.29151 1.37286L2.28271 1.36548C1.93092 1.07036 1.38469 1.06804 1.03129 1.41393L1.01755 1.42738L1.00488 1.44184C0.69687 1.79355 0.695778 2.34549 1.0545 2.69659L1.05999 2.70196L1.06565 2.70717L7.28687 8.43257Z"
              fill=""
              stroke=""
            />
          </svg>
        </div>

        <div className="w-full">
          <h4 className="mt-1 text-lg font-semibold text-dark dark:text-white">
            {header}
          </h4>
        </div>
      </button>

      <div
        className={`pl-[62px] duration-200 ease-in-out ${
          active ? "block" : "hidden"
        }`}
      >
        <p className="py-2 text-base leading-relaxed text-body-color dark:text-dark-6">
          {text}
        </p>
      </div>
    </div>
  );
};
