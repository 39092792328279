import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { t } from 'react-i18nify';
import DT from 'duration-time-conversion';
import RangeSlider from 'react-bootstrap-range-slider';
import{ Space, Tooltip, InputNumber } from 'antd';
import { MdHelpOutline } from "react-icons/md";
//import debounce from 'lodash/debounce';
import { axiosInstance } from '../utils';
import VpsHeader from './VpsHeader';

export default function SubmitImageSTSTask(props) {
    const { setLastScreen, setResultMessage, authenticated, sid, language, notify, options, subtitle, carouselData, userPresenters } = props;
    useEffect(() => {  
        setLastScreen('Submit_Task');
    }, [setLastScreen]);
    const navigate = useNavigate();

    const [useFilmMode, setUseFilmMode] = useState(false);
    const [animateStillImages, setAnimateStillImages] = useState(false);
    const [burnSubtitles, setBurnSubtitles] = useState(false);
    const [fontSize, setFontSize] = useState(15);
    const [description, setDescription] = useState('');
    const [creating, setCreating] = useState(false);
    const [outputLength, setOutputLength] = useState(0);

    const minLength = 1.0;
    const maxLength = useRef(1.0);
    useEffect(() => {
        if (authenticated && userPresenters.length) {
            let duration, totalDuration = 0.0;
            let map = new Map();
            subtitle.forEach(async sub => {
                if (sub.useAudio) {
                    if (!sub.audioType) {
                        sub.audioType = 'driving';
                    }
                    if (sub.draudio) {
                        //const url = `${apiBaseUrl}/mediaDuration/${carouselData.dir}/${sub.draudio}`;
                        //const response = await axiosInstance.get(url);
                        //duration = Number(response.data);
                        duration = sub.draudio.duration;
                    } else {
                        duration = 0.1;
                    }
                } else {
                    sub.text = sub.text.trim();
                    const charPerSecond = userPresenters.filter(x => x.key === parseInt(sub.presenter))[0]?.charPerSecond;
                    duration = sub.text.length / charPerSecond;
                }
                sub.start = DT.d2t(totalDuration);
                totalDuration += duration;
                sub.end = DT.d2t(totalDuration);
                let counter = map.get(sub.presenter);
                if (counter === undefined) {
                    counter = 0;
                } else {
                    counter += 1;
                }
                sub.id = sub.presenter + '_' + counter;
                map.set(sub.presenter, counter);
            });
            if (totalDuration > 1)
                maxLength.current = totalDuration;
            setDescription(options.documentUrl);
        }
        maxLength.current = +maxLength.current.toFixed(3);
        setOutputLength(maxLength.current);
    }, [carouselData, authenticated, userPresenters, subtitle, options]);
  
    const onChangeOfUseFilmMode = event => {
        setUseFilmMode(event.target.checked);
    } 
    
    const onChangeOfAnimateStillImages = event => {
        setAnimateStillImages(event.target.checked);
    } 
    
    const onChangeOfBurnSubtitles = event => {
        setBurnSubtitles(event.target.checked);
    } 
    
    const onChangeOfFontSize = event => {
        setFontSize(+event.target.value);
    } 
/*
    const onChangeOfOutputLength = event => {
        if (isNaN(event.target.value)) {
            setOutputLength(undefined);
        } else {
            setOutputLength(+event.target.value);
        }
    } 
*/
    const onChangeOfDescription = event => {
        setDescription(event.target.value);
    } 

    const saveTask = async () => {
        if (!carouselData || !carouselData.dir) {
            notify({
                message: t('ERROR_MESSAGE_MISSING_IMAGE_FILE'),
                level: 'error',
            });
            return;
        }
        if (subtitle.length < 1) {
            notify({
                message: t('ERROR_MESSAGE_MISSING_SUBTITLES'),
                level: 'error',
            });
            return;
        }
        if (!outputLength || outputLength < minLength || outputLength > maxLength.current) {
            notify({
                message: t('ERROR_MESSAGE_INVALID_OUTPUT_LENGTH', {'minLength': minLength, 'maxLength': maxLength.current}),
                level: 'error',
            });
            return;
        }

        setCreating(true);

        let formData = new FormData();
        formData.append("sid", sid);
        formData.append("locale", language.includes('fr') ? 'fr-FR' : language.includes('es') ? 'es-ES' : 'en-US');
        formData.append("burnSubtitles", burnSubtitles);
        if (burnSubtitles) {
            formData.append("fontSize", fontSize);
        }
        formData.append("outputLength", outputLength);
        formData.append("description", description);
    
        const text = JSON.stringify(subtitle);
        const subtitleFile = new File([text], 'subtitle.json');
        formData.append("file", subtitleFile);
        formData.append("imagesFolder", carouselData.dir);
        formData.append("filmMode", useFilmMode);
        formData.append("animateStillImages", animateStillImages);

        axiosInstance.post("/saveTask", formData, {  
            headers: {
                "Content-Type": "multipart/form-data",
            },
        }).then((response) => {
            //notify({
            //    message: response.data.message,
            //    level: 'success',
            //});
            setCreating(false);
            //setOption({ newTaskDialog: false, taskDialog: true });
            setResultMessage(response.data.message);
            navigate('/TaskResultSuccess');
        })
        .catch((reason) => {
            if (typeof reason.response != 'undefined' && typeof reason.response.data != 'undefined') {
                notify({
                    message: reason.response.data.err ? reason.response.data.err : reason.response.data.message,
                    level: 'error',
                });
            } else {
                notify({
                    message: t('ERROR_MESSAGE_UNKNOWN_REASON', { reason: reason }),
                    level: 'error',
                });
            }
            setCreating(false);
        })
    }
    //const saveTask2 = debounce(saveTask, 3000);
    //const saveTask3 = useCallback(saveTask2, []);   //to prevent duplicate submits
    
    return (
        <VpsHeader {...props}>
            <div>
                <div className="flex mx-2 mb-2">
                    { t("Save_image_STS_task_info") }&nbsp;{ t('Save_task_info_extra') }
                </div>
                {/*<div className="row m-2">
                    { t("Save_task_instruction") }
                </div>*/}
                
                <div className="row m-2">
                    <div className="col-md-3">
                        <Space wrap>
                            { t('Use_Film_Mode') }
                            <Tooltip title={t('Use_Film_Mode_tip')} color='purple'><MdHelpOutline /></Tooltip>
                        </Space>
                    </div>
                    <div className="col-md-9">
                        <input type="checkbox" checked={useFilmMode} onChange={onChangeOfUseFilmMode} />
                    </div>
                </div>
                
                <div className="row m-2">
                    <div className="col-md-3">
                        <Space wrap>
                            { t('Animate_Still_Images') }
                            <Tooltip title={t('Animate_Still_Images_tip')} color='purple'><MdHelpOutline /></Tooltip>
                        </Space>
                    </div>
                    <div className="col-md-9">
                        <input type="checkbox" checked={animateStillImages} onChange={onChangeOfAnimateStillImages} />
                    </div>
                </div>
                
                <div className="row m-2">
                    <div className="col-md-3">
                        <Space wrap>
                            { t('Burn_Subtitles') }
                            <Tooltip title={t('Burn_Subtitles_tip')} color='purple'><MdHelpOutline /></Tooltip>
                        </Space>
                    </div>
                    <div className="col-md-9">
                        <input type="checkbox" checked={burnSubtitles} onChange={onChangeOfBurnSubtitles} />
                    </div>
                </div>
                {burnSubtitles && (
                    <div className="row m-2">
                        <div className="col-md-3">
                            &nbsp;&nbsp;&nbsp;&nbsp;{ t('Font_Size') }
                        </div>
                        <div className="col-md-9">
                            <div className="input-group mb-3">
                                <RangeSlider min={10} max={20} step={1} value={fontSize} onChange={onChangeOfFontSize} />&nbsp;&nbsp;
                                <div className="input-group-append">
                                    <span className="input-group-text ml-2">
                                        {fontSize}
                                    </span>
                                </div>&nbsp;&nbsp;&nbsp;&nbsp;
                                <p style={{fontSize: `${fontSize}px`}}>{t('sample_text')}</p>
                            </div>
                        </div>
                    </div>
                )}

                <div className="row m-2">
                    <div className="col-md-3">
                        <Space wrap>
                            { t('Output_Length') }
                            <Tooltip title={t('Image_STS_Output_Length_tip')} color='purple'><MdHelpOutline /></Tooltip>
                        </Space>
                    </div>
                    <div className="col-md-9">
                        {/*
                        <div className="input-group mb-3">
                            <div className="input-group-append">
                                <span className="input-group-text ml-2">
                                    <input className="text-black" type="number" min={minLength} max={maxLength.current} step={0.001} value={outputLength} onChange={onChangeOfOutputLength} />&nbsp;&nbsp;{t('seconds')}
                                </span>
                            </div>
                        </div>*/}
                        <InputNumber
                            min={minLength} max={maxLength.current} step={0.01}
                            value={outputLength}
                            addonAfter={t('seconds')}
                            onChange={(value) => setOutputLength(value)}
                        />
                    </div>
                </div>

                <div className="row m-2">
                    <div className="col-md-3">
                        <Space wrap>
                            { t('Description') }
                            <Tooltip title={t('Description_tip')} color='purple'><MdHelpOutline /></Tooltip>
                        </Space>
                    </div>
                    <div className="col-md-9">
                        <input type="text" className="form-control w-75" value={description} onChange={onChangeOfDescription} />
                    </div>
                </div>

                <div className="row m-3">
                    <div className="col-12 text-center">
                        <button
                            className="btn mb-3 mr-3 btn-info btn-lg"
                            onClick={() => saveTask()}
                            disabled={!authenticated || creating}
                        >
                            { creating ? t('sending') : t('Submit') }
                        </button><br/>
                        {!authenticated && (
                            t("Sign_in_to_submit_task")
                        )}
                    </div>
                </div>
            </div>
        </VpsHeader>
    );
}
