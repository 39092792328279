import React from "react";
import "./style.css";
import PayPalButton from "./payPalButton";

// reference: https://www.npmjs.com/package/pricing-table-react

interface PlanHighlight {
  text: string;
}

interface PlanProps {
  title: string;
  titleDescription?: String;
  duration?: string;
  highlightText?: string;
  price: string;
  guarantee?: string;
  href?: string;
  subscriptionPlanId?: string;
  highlights?: PlanHighlight[];
}

interface IPlanProps {
  Plans: PlanProps[];
  Currency: string;
  BackColor?: string;
  Color?: string;
}

const Plan = ({ setOptions, authenticated, user, sid, language, notify, planProps }) => {

  return (
    <section className="OPlanContainer">
      <div className="OPlan_Group">
        {planProps.Plans?.map((data, index) => {
          return (
            <div className="OPlan" key={index}>
              {planProps.BackColor ? (
                <div
                  className="OPlanHeader"
                  style={{ backgroundColor: planProps.BackColor }}
                >
                  {data.highlightText && (
                    <div className="OHighlight">
                      <p className="OHighlightText">{data.highlightText}</p>
                    </div>
                  )}
                  <p style={{ color: planProps.Color }}>{data.title}</p>
                  {data.titleDescription && (
                    <p style={{ color: planProps.Color }}>
                      {data.titleDescription}
                    </p>
                  )}
                </div>
              ) : (
                <div className="OPlanHeader">
                  {data.highlightText && (
                    <div className="OHighlight">
                      <p className="OHighlightText">{data.highlightText}</p>
                    </div>
                  )}
                  <p style={{ color: planProps.Color }}>{data.title}</p>
                  {data.titleDescription && (
                    <p style={{ color: planProps.Color }}>
                      {data.titleDescription}
                    </p>
                  )}
                </div>
              )}
              <div className="OPlanPrice">
                <span className="OCurrency">{planProps.Currency}</span>
                <p className="OPrice">
                  {data.price}&nbsp;
                  {data.duration && (
                    <span className="OPlanDuration">/ {data.duration}</span>
                  )}
                </p>
              </div>
              {data.href && planProps.BackColor && (
                <div className="OSelect">
                  <a
                    href={data.href}
                    style={{
                      backgroundColor: planProps.BackColor,
                      color: planProps.Color,
                    }}
                  >
                    Select
                  </a>
                  {data.guarantee && (
                    <p className="OGuarantee">{data.guarantee}</p>
                  )}
                </div>
              )}
              {data.href && !planProps.BackColor && (
                <div className="OSelect">
                  <a href={data.href} style={{ color: planProps.Color }}>
                    Select
                  </a>
                  {data.guarantee && (
                    <p className="OGuarantee">{data.guarantee}</p>
                  )}
                </div>
              )}
              {data.subscriptionPlanId && (
                <div className="OSelect">
                  <PayPalButton setOptions={setOptions} authenticated={authenticated} user={user} sid={sid} language={language} notify={notify} subscriptionPlanId={data.subscriptionPlanId} />
                  {data.guarantee && (
                    <p className="OGuarantee">{data.guarantee}</p>
                  )}
                </div>
              )}
              <div className="OChoices">
                {data.highlights?.map((highlight, key) => (
                  <div key={key} className="OChoiceGroup">
                    <svg
                      viewBox="0 0 512 512"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{color: planProps.BackColor}}
                    >
                      <path
                        fill="currentColor"
                        d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
                      />
                    </svg>
                    <p>{highlight.text}</p>
                  </div>
                ))}
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export { Plan, PlanProps };
