import React from 'react';
import { useNavigate } from 'react-router-dom';
import { t } from 'react-i18nify';
import { Result, Button } from 'antd';
import VpsHeader from './VpsHeader';
import VpsFooter from './VpsFooter';

export default function TaskResultSuccess(props) {
    const navigate = useNavigate();
 
    return (
        <VpsHeader {...props}>
            <Result
                status="success"
                title={props.resultMessage}
                subTitle={t('Save_task_info_extra')}
                extra={[
                    <Button type="primary" key="console" onClick={() => navigate('/TaskHistory')}>
                        {t('Go_Task_History')}
                    </Button>,
                ]}
            />
            <VpsFooter {...props} />
        </VpsHeader>
    );
}
