import React, { useEffect } from 'react';
import ImageSubtitles from './ImageSubtitles';
import ImagePlayer from './ImagePlayer';
import VpsHeader from './VpsHeader';

export default function ImageSubtitleToSpeech(props) {
    const { setLastScreen } = props;
    useEffect(() => {  
        setLastScreen('Image_STS');
    }, [setLastScreen]);

    return (
        <VpsHeader {...props}>
            <div className="flex flex-wrap items-center justify-between">
                <div id="player_panel" className="w-full px-2 lg:w-6/12">
                    <ImagePlayer {...props} />
                </div>
                <div id="subtitle_panel" className="w-full px-4 lg:w-6/12">
                    <ImageSubtitles {...props} />
                </div>
            </div>
        </VpsHeader>
    );
}
