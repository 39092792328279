import React, { useEffect, useState, useRef, useMemo, useCallback } from 'react';
import debounce from 'lodash/debounce';
import { t } from 'react-i18nify';
import HashMap from 'hashmap';
import { SearchOutlined, DownloadOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';
import { apiBaseUrl } from '../utils';
import Table from './Table';
import VpsHeader from './VpsHeader';

export default function TaskHistory(props) {
    const { setLastScreen, options, setOption, authenticated, sid, language, notify } = props;
    useEffect(() => {  
        setLastScreen('Task_History');
    }, [setLastScreen]);

    const [pageCount, setPageCount] = useState(0);
    const [data, setData] = useState([]);
    const fetchIdRef = useRef(0);
    const [loading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [deleteFlags, setDeleteFlags] = useState(new HashMap());

    const fetchAPIData = async ({ limit, skip, search }) => {
        if (!sid)
            return;
        setLoading(true);
        fetch(apiBaseUrl + "/userTasks", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ 
                sid: sid, 
                locale: language.includes('fr') ? 'fr-FR' : language.includes('es') ? 'es-ES' : 'en-US', 
                limit: limit,
                skip: skip,
                search: search,
            }),
        })
        .then(async response => {
            if (!response.ok) {
                const result = await response.json();
                notify({
                    message: result.err,
                    level: 'error',
                });
            } else {
                const result = await response.json();
                setData(result.data);
                setPageCount(result.paging.pages);
                result.data.forEach(element => {
                    deleteFlags.set(element.number, element.deleteFiles);
                });
            }
            setLoading(false);
        })
        .catch(err => {
            console.log(err);
            notify({
                message: t('GENERIC_ERROR_MESSAGE'),  //err.message,
                level: 'error',
            });
            setLoading(false);
        })
    };

    const fetchData = useCallback(({ pageSize, pageIndex }) => {
        // console.log("fetchData is being called")
        // This will get called when the table needs new data
        // Give this fetch an ID
        const fetchId = ++fetchIdRef.current;
        if (fetchId === fetchIdRef.current) {
            fetchAPIData({
                limit: pageSize,
                skip: pageSize * pageIndex,
                search: searchTerm,
            });
        }
    }, [sid, searchTerm]);

    const handleSearch = debounce((search) => {setSearchTerm(search)}, 1500, {maxWait: 1500});

    const updateDeleteFlag = (number) => {
        if (window.confirm(t('confirm_to_delete_task_files', {number: number}))) {
            const newMap = new HashMap(deleteFlags);
            newMap.set(number, true);
            setDeleteFlags(newMap);
            
            fetch(apiBaseUrl + "/setDeleteTaskFiles", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ 
                    sid: sid, 
                    locale: language.includes('fr') ? 'fr-FR' : language.includes('es') ? 'es-ES' : 'en-US', 
                    number: number,
                }),
            })
            .then(async response => {
                const result = await response.json();
                if (!response.ok) {
                    notify({
                        message: result.err,
                        level: 'error',
                    });
                } else {
                    notify({
                        message: result.message,
                        level: 'success',
                    });
                }
            })
            .catch(err => {
                console.log(err);
                notify({
                    message: t('GENERIC_ERROR_MESSAGE'),  //err.message,
                    level: 'error',
                });
            })
        }
    }

    const columns = useMemo(() => [
        { Header: t("Number"), accessor: "number", show: true },
        { Header: t("Description"), accessor: "description", show: true },
        { Header: t("Status"), accessor: "status", show: true },
        { Header: t("Submit_Time"), accessor: "submittedAt", show: true, 
            Cell: (cellInfo) => { return cellInfo.row.original.submittedAt ? new Date(cellInfo.row.original.submittedAt).toLocaleString() : ''; }
        },
        { Header: t("Start_Time"), accessor: "startedAt", show: true,
            Cell: (cellInfo) => { return cellInfo.row.original.startedAt ? new Date(cellInfo.row.original.startedAt).toLocaleString() : ''; }
        },
        { Header: t("Finish_Time"), accessor: "finishedAt", show: true,
            Cell: (cellInfo) => { return cellInfo.row.original.finishedAt ? new Date(cellInfo.row.original.finishedAt).toLocaleString() : ''; }
        },
        { Header: t("Result_Video"), accessor: "outputUrl", show: true, disableSortBy: true, 
            Cell: (cellInfo) => {
                if (cellInfo.row.original.outputUrl || cellInfo.row.original.story)
                    if (cellInfo.row.original.deleteFiles || deleteFlags.get(cellInfo.row.original.number)) {
                        return t('deleted');
                    } else {
                        return (
                        <div className="row justify-content-md-center">
                            {cellInfo.row.original.outputUrl && (
                                <div className="col">
                                    {/*<a className="btn btn-info btn-sm" href={cellInfo.row.original.outputUrl}>{t('Download')}</a>*/}
                                    <Button type="primary" shape="round" icon={<DownloadOutlined />} size="small"
                                        onClick={() => window.location.assign(cellInfo.row.original.outputUrl)}
                                    >
                                        {t('Media')}
                                    </Button>
                                </div>
                            )}
                            {cellInfo.row.original.story && (
                                <div className="col">
                                    {/*<a className="btn btn-info btn-sm" href={cellInfo.row.original.story}>{t('Story')}</a>*/}
                                    <Button type="primary" shape="round" icon={<DownloadOutlined />} size="small"
                                        onClick={() => window.location.assign(cellInfo.row.original.story)}
                                    >
                                        {t('Story')}
                                    </Button>
                                </div>
                            )}
                            <div className="col">                                
                                {/*<button className="btn btn-warning btn-sm" onClick={() => updateDeleteFlag(cellInfo.row.original.number)}>{t('Delete')}</button>*/}
                                <Button type="primary" size="small" danger onClick={() => updateDeleteFlag(cellInfo.row.original.number)}>{t('Delete')}</Button>
                            </div>
                        </div>
                    );
                } else {
                    return '';
                }
            },
        },
        { Header: t("Message"), accessor: "errorMessage", show: true },
        { Header: t("Output_Video_Length"), accessor: "outputVideoLength", show: true,
            Cell: (cellInfo) => { return (cellInfo.row.original.outputUrl || cellInfo.row.original.status !== 'failed') ? cellInfo.row.original.outputVideoLength.toFixed(3) + ' ' + t('seconds') : '' }
        },
    ], [deleteFlags]);
/*
    const newTask = () => {
        setOption({ taskDialog: false, newTaskDialog: true });
    }

    const newVideoEffectTask = () => {
        setOption({ taskDialog: false, newVideoEffectTaskDialog: true });
    }

    const newTranscriptionTask = () => {
        setOption({ taskDialog: false, newTranscriptionTaskDialog: true });
    }

    const newStorybookTask = () => {
        setOption({ taskDialog: false, newStorybookTaskDialog: true });
    }
*/
    return (
        <VpsHeader {...props}>
            {!authenticated ? (
                <div className="row m-3">
                    { t("Sign_in_to_edit_task") }
                </div>
            ) : (
                <div className="flex mx-2 mb-2 flex-col">
                    {/* 
                    <div className="row my-2">
                        <div className="col-md-3 my-2">
                            <button className="btn btn-info" onClick={() => newTask()}>{t('New_Task')}</button>
                        </div>
                        <div className="col-md-3 my-2">
                            <button className="btn btn-info" onClick={() => newVideoEffectTask()}>{t('New_Video_Effect_Task')}</button>
                        </div>
                        <div className="col-md-3 my-2">
                            <button className="btn btn-info" onClick={() => newStorybookTask()}>{t('New_Storybook_Task')}</button>
                        </div>
                        <div className="col-md-3 my-2">
                            <button className="btn btn-info" onClick={() => newTranscriptionTask()}>{t('New_Transcription_Task')}</button>
                        </div>
                    </div>
                    */}
                    <div className="flex max-w-[200px]">
                        <Input
                            id="searchTerm"
                            name="searchTerm"
                            type="text"
                            onChange={(e) => handleSearch(e.target.value)}
                            placeholder={ t("Search_by_Number") }
                            addonBefore={<SearchOutlined />}
                        />
                    </div>
                    <div className="flex justify-center mt-3">
                        <Table
                            pageCount={pageCount}
                            fetchData={fetchData}
                            columns={columns}
                            loading={loading}
                            data={data}
                            customPageSize={options.pageSize ?? 5}
                            setOption={setOption}
                        />
                    </div>
                </div>
            )}
        </VpsHeader>
    );

}
