import React, { useRef, useEffect } from "react";
import { PayPalButtons } from "@paypal/react-paypal-js";
import { t } from 'react-i18nify';
import { apiBaseUrl } from '../../utils';

const PayPalButton = ({ setOptions, authenticated, user, sid, language, notify, subscriptionPlanId }) => {
  //console.log(subscriptionPlanId);
  //console.log(sid);

  const mySid = useRef("");
  const myLocale = useRef("");
  const myUserId = useRef("");
  const mySubscriptionId = useRef("");

  useEffect(() => { 
    mySid.current = sid;
    myLocale.current = language.includes('fr') ? 'fr-FR' : language.includes('es') ? 'es-ES' : 'en-US';
    myUserId.current = user?.provider + ' ' + user?.id;
    mySubscriptionId.current = user?.subscriptionId;
  }, [sid, language, user]);

  const createSubscription = (data, actions) => {
    //console.log(mySubscriptionId.current);
    //if (mySubscriptionId.current) {
      // change subscription, the new price is effective starting on the next billing cycle. https://developer.paypal.com/docs/subscriptions/customize/revise-subscriptions/ 
    //  return actions.subscription.revise(mySubscriptionId.current, {
    //    plan_id: subscriptionPlanId,
    //    custom_id: myUserId.current,
    //  })
    //} else {  // new subscription
      return actions.subscription.create({
        plan_id: subscriptionPlanId,
        custom_id: myUserId.current,
        //notify_url: 'https://videoplus.studio/api/processPayPalIPN', -- not working for Subscription and Recurring Payments, which always fall to your profile setting
      })
    //}
  }

  const onClick = (data, actions) => {
    //console.log(mySid.current);
    /*
    if (!authenticated || !mySid.current) {
      alert(`Please login then subscribe to the plan`);
      return actions.reject();
    }
    if (subscriptionPlanId === user.subscriptionPlanId) {
      alert(`You already subscribed to the plan`);
      return actions.reject();
    }
    */
    return actions.resolve();
  }

  const onApprove = (data, actions) => {
    const paymentDetails = {
      sid: mySid.current,
      locale: myLocale.current,
      subscriptionId: data.subscriptionID,
      subscriptionPlanId: subscriptionPlanId,
      //orderId: data.orderId,
    };
    //console.log(paymentDetails);
    fetch(
      apiBaseUrl + "/updateAccountOnPaymentApproval", {
        method: "POST",
        headers: {
          'Accept': 'application/json, text/plain, */*',
          "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify(paymentDetails),
    })
    .then(async response => {
      // result should contain account update successful message
      //alert('You have successfully created subscription ' + data.subscriptionID);
      //window.location.reload();
      if (!response.ok) {
        const result = await response.json();
        notify({
          message: result.err,
          level: 'error',
        });
      } else {
        notify({
          message: t('Subscription_successful'),
          level: 'info',
        });
        //setOptions({ pricingDialog: false });
      }
    })
    .catch((err) => {
      // Handle any error
      //alert(`An error occured while updating your account: ${err}`);
      console.log(err);
      notify({
        message: t('GENERIC_ERROR_MESSAGE'),   //err.message
        level: 'error',
      });
    });
  }

  const onError = (data, actions) => {
    //alert(`An error occured with your payment: ${data}`);
    notify({
      message: t('ERROR_MESSAGE_UNKNOWN_REASON', { reason: data }),
      level: 'error',
    });
  }

  return (
    <React.Fragment>
      {!authenticated && (
        <div className="container-fluid text-center">
            <button className="btn1 mb-3 mr-3 text-black btn-info" onClick={() => alert(t('login_and_subscribe'))}> { t('Select_Plan') } </button>
        </div>
      )}
      {authenticated && subscriptionPlanId === user.subscriptionPlanId && user.serviceType > '0' && (
        <div className="alert alert-warning container-fluid text-center">
          <strong>{ t('current_plan') }</strong> 
        </div>
      )}
      {authenticated && subscriptionPlanId !== user.subscriptionPlanId && subscriptionPlanId === user.nextSubscriptionPlanId && user.serviceType > '0' && (
        <div className="alert alert-warning container-fluid text-center">
          <strong>{ t('next_plan') } </strong>
        </div>
      )}
      {authenticated && (!user.subscriptionPlanId || user.serviceType === '0') && (
        <div id={subscriptionPlanId}>
          <PayPalButtons 
            style={{ label: "subscribe", layout: "vertical" }} 
            createSubscription={createSubscription}
            onClick={onClick}
            onApprove={onApprove}
            onError={onError}
          />
        </div>
      )}
    </React.Fragment>
  );

    //the last condition if subscriptionPlan can be modified
    //authenticated && subscriptionPlanId !== user.subscriptionPlanId && subscriptionPlanId !== user.nextSubscriptionPlanId && (!user.subscriptionPlanId || user.nextSubscriptionPlanId) && (
}

export default PayPalButton;