import React from "react";
import { t } from 'react-i18nify';

const Cta = () => {
  return (
    <>
      <section className="py-12 dark:bg-dark">
        <div className="container mx-auto">
          <div className="relative overflow-hidden rounded bg-blue-500 px-8 py-2">
            <div className="flex flex-wrap items-center">
              <div className="w-full px-4 lg:w-1/2">
                <span className="mb-4 block text-base font-medium text-white">
                  { t('app_cta_headline1') }
                </span>
                <h2 className="mb-6 text-3xl font-bold leading-tight text-white sm:text-[40px]/[48px">
                  <span className="xs:block">
                    { t('app_cta_headline2') }
                  </span>
                </h2>
              </div>
              <div className="w-full px-4 lg:w-1/2">
                <div className="flex flex-wrap lg:justify-end">
                  <a href="/Pricing" className="btn btn-info btn-lg">
                    { t('Pricing') }
                  </a>
                  {/*
                  <a
                    href="javascript:void(0)"
                    className="hover:bg-shadow-1 my-1 mr-4 inline-flex rounded-md bg-white px-7 py-3 text-base font-medium dark:text-white transition"
                  >
                    Sign in 
                  </a>
                  <a
                    href="javascript:void(0)"
                    className="my-1 inline-flex rounded-md bg-secondary px-7 py-3 text-base font-medium text-white transition hover:bg-opacity-90"
                  >
                    Sign up
                  </a>
                  */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Cta;
