import React, { useEffect } from 'react';
import { useLocalStorage } from 'usehooks-ts';
//import Navbar from './Navbar';
import { DashboardLayout } from "../dashboard/Layout.tsx";
import { chatbotBaseUrl } from '../utils';
//import Chatbot, { FloatingActionButtonTrigger, ModalView } from "mongodb-chatbot-ui";
import { ConvoStackWidget } from "convostack/frontend-react";

export default function VpsHeader(props) {
    const [theme] = useLocalStorage('theme', 'light');
    const suggestedPrompts = [
        "What can I do with the app?",
        "How to apply cartoon and filter effects to videos?",
        "How to combine pictures and words into story-telling videos?",
    ];
    
    return (
        <div>
            <DashboardLayout {...props} />
            {/*<Chatbot darkMode={theme !== 'dark'} serverBaseUrl={chatbotBaseUrl + '/api/v1'}>
                <>
                <FloatingActionButtonTrigger text="Your AI Assistant" />
                <ModalView
                    initialMessageText="Welcome to VideoPlus Studio AI Assistant. What can I help you with?"
                    initialMessageSuggestedPrompts={suggestedPrompts}
                />
                </>
            </Chatbot>*/}
            <ConvoStackWidget
                graphqlUrl={ chatbotBaseUrl + '/graphql' }
                websocketUrl={ (chatbotBaseUrl.startsWith('https') ? 'wss://' : 'ws://') + chatbotBaseUrl.split('//')[1] + '/graphql' }
                workspaceId={window.location.href}
                customStyling={{ 
                    widgetLocation: "right", 
                    headerText: "Customer Success Agent", 
                    headerColor: "#8db600",
                    widgetLaunchButtonColor: "#8db600",
                }}
            />
        </div>
    );
}
