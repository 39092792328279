import React, { useEffect } from 'react';
import VideoSubtitles from './VideoSubtitles';
import VideoPlayer from './VideoPlayer';
//import Footer from './Footer';
import VpsHeader from './VpsHeader';

export default function VideoSubtitleToSpeech(props) {
    const { setLastScreen } = props;
    useEffect(() => {  
        setLastScreen('Video_STS');
    }, [setLastScreen]);

    return (
        <VpsHeader {...props}>
            <div className="flex flex-wrap items-center justify-between">
                <div id="player_panel" className="w-full px-2 lg:w-6/12">
                    <VideoPlayer {...props} />
                </div>
                <div id="subtitle_panel" className="w-full px-4 lg:w-6/12">
                    <VideoSubtitles {...props} />
                </div>
            </div>
            {/*
            <div className="-mx-4 pt-5 bg-dark text-white">
                <Footer {...props} />
            </div>
            */}
        </VpsHeader>
    );
}
