import React, { useMemo } from "react";
import { useTable, usePagination, useExpanded, useSortBy } from "react-table";
import { t } from 'react-i18nify';
import Loading from './Loading';
import Styled from "styled-components"
import { InputNumber, Select } from 'antd';

const Styles = Styled.div`
    /* This is required to make the table full-width */
    display: block;
    max-width: 100%;

    /* This will make the table scrollable when it gets too small */
    .tableWrap {
        display: block;
        max-width: 100%;
        overflow-x: auto;
        overflow-y: hidden;
    }

    table {
        width: 100%;
        border-spacing: 0;
        

        th {
        border-bottom : 1px solid #af9efd;
        vertical-align: initial;
        }

        th,
        td {
        margin: 0;
        padding: 0.5rem;
        border-bottom: 1px solid rgba(219, 221, 227, 0.5);
        /* border-right: 1px solid black; */

        /* The secret sauce */
        /* Each cell should grow equally */
        width: 1%;
        /* But "collapsed" cells should be as small as possible */
        &.collapse {
            width: 0.0000000001%;
        }


        :last-child {
            border-right: 0;
        }
      }
    }

    .pagination {
      padding: 0.5rem;
    }
`
/*
const Pagination = Styled.div`
    display :flex;
    margin : 14px 0;  
    justify-content: flex-end;
`

const PaginationIndex = Styled.div`
    display: flex;
    font-family: "Proxima Nova";
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.85;
    letter-spacing: normal;
    color: #6495ED;
`

const PagincationButtonContainer = Styled.div`
    display: flex;
    min-width: 130px;
    align-items: center;
    justify-content: space-around;
    margin: 0 40px 0 16px;
`

const PaginationButton = Styled.div`
    width: 100%;
    cursor: pointer;
    display : flex;
    max-width : 30px;
    height: 18px;
    font-family: "Proxima Nova";
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: #00FFFF;
`

const RightIconSpan = Styled.span`
    margin-left : 6px;
`

const LeftIconSpan = Styled.span`
    margin-right : 6px;
`

const BackButtonIcon = Styled.i`
    border: solid 0.5px #00FFFF;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(135deg);
`

const NextButtonIcon = Styled.i`
    border: solid 0.5px #00FFFF;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(-45deg);
`
*/
const TableComponent = ({
    columns,
    data,
    fetchData,
    pageCount: controlledPageCount,
    customPageSize,
    setOption,
    loading,
    isPaginated = true,
    ...props
}) => {
    const defaultColumn = useMemo(
        () => ({
        // minWidth: 20,
        // maxWidth: 115
        }),
        []
    );
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        setHiddenColumns,
        state: { pageIndex, pageSize },
    } = useTable(
    {
        columns,
        data,
        defaultColumn,
        initialState: {
            pageIndex: 0,
            pageSize: customPageSize ?? 10,
            hiddenColumns: columns
            .filter((column) => !column.show)
            .map((column) => column.id),
        },
        manualPagination: true,
        disableSortRemove: true,
        //defaultCanSort: true,
        autoResetPage: false,
        pageCount: controlledPageCount,
    },
    useSortBy,
    useExpanded,
    usePagination
  );

  React.useEffect(() => {
      fetchData && fetchData({ pageIndex, pageSize });
  }, [fetchData, pageIndex, pageSize]);

  const pageSizeOptions = [5, 10, 15, 20, 30, 50];

  return (
    <Styles>
      {loading ? (
        <Loading loading={loading} />
      ) : (
        <div className="table-responsive">
          <div className="-my-0 py-0">
            <table className="table"
              {...getTableProps()}
            >
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        className="px-2 py-2"
                        {...column.getHeaderProps(column.getSortByToggleProps())}
                      >
                        {column.render("Header")}
                        <span>
                          {column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""}
                        </span>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>

              <tbody
                {...getTableBodyProps()}
              >
                {page.map((row, i) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td
                            className="px-2 py-2"
                            {...cell.getCellProps()}
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {/* Boolean(isPaginated) && (
              <Pagination>
                <PaginationIndex>
                  {t('page')}&nbsp;&nbsp; 
                  <select value={pageIndex} onChange={(e) => gotoPage(e.target.value)}>
                    {Array.from(Array(pageCount).keys()).map((x, i) =>
                      <option key={i} value={i}>
                          {i + 1}
                      </option>
                    )}
                  </select>
                  &nbsp;&nbsp;{t('of')}&nbsp;&nbsp;{pageOptions.length}
                </PaginationIndex>{" "}
                <PagincationButtonContainer>
                  {canPreviousPage ? (
                    <PaginationButton onClick={() => previousPage()}>
                      <LeftIconSpan>
                        <BackButtonIcon />
                      </LeftIconSpan>
                      {t('Previous')}&nbsp;&nbsp;&nbsp;&nbsp;
                    </PaginationButton>
                  ) : null}
                  {canNextPage ? (
                    <PaginationButton onClick={() => nextPage()}>
                      {t('Next')}
                      <RightIconSpan>
                        <NextButtonIcon />
                      </RightIconSpan>
                    </PaginationButton>
                  ) : null}
                </PagincationButtonContainer>
              </Pagination>
            ) */}
            <div className="pagination">
              <button className="btn btn-info btn-sm" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                {t("First")}
              </button>&nbsp;
              <button className="btn btn-info btn-sm" onClick={() => previousPage()} disabled={!canPreviousPage}>
                {"<"}
              </button>&nbsp;
              <button className="btn btn-info btn-sm" onClick={() => nextPage()} disabled={!canNextPage}>
                {">"}
              </button>&nbsp;
              <button className="btn btn-info btn-sm" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                {t("Last")}
              </button>&nbsp;&nbsp;
              {t('page')}&nbsp;
              <InputNumber
                min={1} max={pageOptions.length}
                defaultValue={pageIndex + 1}
                onChange={value => {
                  const page = value ? value - 1 : 0;
                  gotoPage(page);
                }}
                style={{ width: "50px" }}
              />&nbsp;
              {t('of')}&nbsp;{pageOptions.length}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Select popupMatchSelectWidth={false} 
                value={pageSize}
                onChange={e => {
                  const size = Number(e.target.value);
                  setPageSize(size);
                  setOption({ pageSize: size });
                }}
              >
                {/*pageSizeOptions.map(pageSize => (
                  <option key={pageSize} value={pageSize}>
                    {t("Show_per_page", {"pageSize": pageSize})}
                  </option>
                ))*/}
                {pageSizeOptions.map(size => 
                  (<Select.Option key={size} value={size}>{ t("Show_per_page", {"pageSize": size}) }</Select.Option>)
                )}
              </Select>
            </div>
          </div>
        </div>
      )}
    </Styles>
  );
};

export default TableComponent;
