import 'core-js';
import 'normalize.css';
import './libs/contextmenu.css';
import './index.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { setLocale, setTranslations } from 'react-i18nify';
import i18n from './i18n';
import App from './App';
//import { DashboardLayout } from "./dashboard/Layout.tsx";
import { ConfigProvider } from "antd";
import { ConvoStackWrapper } from "convostack/frontend-react";

setTranslations(i18n);
const language = navigator.language.toLowerCase();
const defaultLang = i18n[language] ? language : 'en';
setLocale(defaultLang);

ReactDOM.createRoot(document.getElementById("root")).render(
    <React.StrictMode>
        <BrowserRouter>
            <ConvoStackWrapper>
            <ConfigProvider
                theme={{
                    components: {
                        InputNumber: {
                            addonBg: "lightgrey",
                        },
                    },
            }}>            
                <App defaultLang={defaultLang} />
            </ConfigProvider>
            </ConvoStackWrapper>
        </BrowserRouter>
    </React.StrictMode>
);

  
