import React, { useCallback } from 'react';
import styled from 'styled-components';
import { t } from 'react-i18nify';
import googleTranslate from '../libs/googleTranslate';
import { Select } from 'antd';
import { compareLanguages } from '../utils';

const Style = styled.div`
    .translate {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 6px;
        border-bottom: 1px solid rgb(255 255 255 / 20%);

        .title {
            color: #fff;
            font-size: 14px;
            padding: 5px 10px;
            margin-bottom: 10px;
            border-left: 2px solid #03a9f4;
        }

        select {
            width: 100%;
            outline: none;
            padding: 0 5px;
            border-radius: 3px;
        }

        .btn {
            opacity: 0.85;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 35px;
            width: 88%;
            border-radius: 10px;
            margin: 20px;
            color: #fff;
            cursor: pointer;
            font-size: 13px;
            background-color: #4169E1;
            transition: all 0.2s ease 0s;

            &:hover {
                opacity: 1;
            }
        }
    }
`;

export default function Translator({ subtitle, setLoading, setSubtitle, formatSub, translate, setTranslate, ttsLanguages, notify, setOption }) {
    //const [translate, setTranslate] = useState('en');
    const onTranslate = useCallback(() => {
        setLoading(t('TRANSLATING'));
        googleTranslate(formatSub(subtitle), translate)
            .then((res) => {
                setLoading('');
                setSubtitle(formatSub(res));
                notify({
                    message: t('TRANSLAT_SUCCESS'),
                    level: 'success',
                });
            })
            .catch((err) => {
                setLoading('');
                notify({
                    message: err.message,
                    level: 'error',
                });
            });
        setOption({ translatorDialog: false });
    }, [subtitle, setLoading, formatSub, setSubtitle, translate, notify, setOption]);

    return (
        <Style>
            <div className="translate">
                <div className="title">
                    {t("TRANSLATE_hint")}
                </div>
                 <div className="row m-1 text-white">
                    {t("Target_Language")}
                </div>
                <Select popupMatchSelectWidth={false} showSearch value={translate} onChange={(value) => setTranslate(value)}>
                    {ttsLanguages.sort(compareLanguages).map((item) => (
                        <Select.Option key={item['gt_code']} value={item['gt_code']}>{t(item['name']) + ' (' + item['gt_code'] + ')'}</Select.Option>
                    ))}
                </Select>
                <div className="btn" onClick={onTranslate}>
                    {t("Confirm")}
                </div>
            </div>
        </Style>
    );
}
