import React, { useEffect, useState } from 'react';
import { t } from 'react-i18nify';
import { apiBaseUrl, resizeImage, getBase64String } from "../utils";
import VpsHeader from './VpsHeader';
import VpsFooter from './VpsFooter';

export default function Contact(props) {
    const { setLastScreen, language, notify, authenticated, user } = props;
    useEffect(() => {  
        setLastScreen('Contact_Us');
    }, [setLastScreen]);

    const [inputImage, setInputImage] = useState(undefined);
    const [previewInputImage, setPreviewInputImage] = useState(undefined);
    const [sending, setSending] = useState(false);
  
    const selectInputImage = async (event) => {
      let currentFile = event.target.files[0];
      try {
        currentFile = await resizeImage(currentFile);
      } catch(err) {
        setInputImage(undefined);
        setPreviewInputImage(undefined);
        notify({
            message: t('ERROR_MESSAGE_FILE_INVALID_AND_NOT_TO_BE_INCLUDED', { name: currentFile.name }),
            level: 'error',
        });
        return;
      }
    
      setInputImage(currentFile);
      setPreviewInputImage(URL.createObjectURL(currentFile));
    }
  
    const handleSubmit = async (e) => {
        e.preventDefault();
        setSending(true);
        const { name, email, message } = e.target.elements;
        const details = {
            name: name.value,
            email: email.value,
            message: message.value,
            locale: language.includes('fr') ? 'fr-FR' : language.includes('es') ? 'es-ES' : 'en-US', 
        };
        if (inputImage) {
            details.inputImage = await getBase64String(inputImage);
            details.filename = inputImage.name;
            details.encoding = 'base64';
        }
        if (authenticated) {
            details.message += '<p/>Sent by user: <br/>' + JSON.stringify(user);
        }
        const response = await fetch(apiBaseUrl + "/feedback", {
            method: "POST",
            headers: {
                'Accept': 'application/json, text/plain, */*',
                "Content-Type": "application/json;charset=utf-8",
            },
            body: JSON.stringify(details),
        });
        const result = await response.json();
        notify({
            message: result.status,
            level: 'success',
        });
        setSending(false);
        //setOption({ supportDialog: false });
    };
  
    return (
        <VpsHeader {...props}>

        <section id="contact">
            <div className="container">
                <div className="mb-3 flex flex-wrap">
                    { t('Support_info') }
                </div>

                <div className="-mx-4 flex w-full flex-wrap justify-center">
                    <div className="w-full px-4">
                        <form className="form-horizontal card p-3" onSubmit={handleSubmit}>
                            <div className="form-group row m-2">
                                <label className="control-label col-sm-3" htmlFor="name">{ t('Name') } <font color="Red">*</font></label>
                                <div className="col-sm-9">
                                    {authenticated ? (
                                        <input className="form-control" type="text" id="name" defaultValue={user.displayName} required />
                                    ) : (
                                        <input className="form-control" type="text" id="name" required />
                                    )}
                                </div>
                            </div>
                            <div className="form-group row m-2">
                                <label className="control-label col-sm-3" htmlFor="email">{ t('Email') } <font color="Red">*</font></label>
                                <div className="col-sm-9">
                                    {authenticated ? (
                                        <input className="form-control" type="email" id="email" defaultValue={user.email} required />
                                    ) : (
                                        <input className="form-control" type="email" id="email" required />
                                    )}
                                </div>
                            </div>
                            <div className="form-group row m-2">
                                <label className="control-label col-sm-3" htmlFor="message">{ t('Message') } <font color="Red">*</font></label>
                                <div className="col-sm-9">
                                    <textarea className="form-control" type="text" id="message" required />
                                </div>
                            </div>
                            <div className="form-group row m-2">
                                <label className="control-label col-sm-3" htmlFor="message">{ t('Attach_Image') }</label>
                                <div className="col-sm-9">
                                    <label className="btn mb-3 mr-3 btn-light">
                                        <input className="form-control" type="file" accept="image/*" onChange={selectInputImage} />
                                    </label>
                                    {previewInputImage && (
                                        <div>
                                            <img className="preview" src={previewInputImage} alt="" />
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="form-group row m-3">
                                <div className="col-sm-12 text-center">
                                    <button className="btn mb-3 mr-3 btn-info btn-lg" type="submit" disabled={sending}>
                                        { sending ? t('sending') : t('Submit') }
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
        
        <VpsFooter {...props} />
        </VpsHeader>
    );
}
