import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { t } from 'react-i18nify';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import { SocialIcon } from 'react-social-icons';
import CountUp from 'react-countup';

const Portfolio = (props) => {
  const [countLanguages, setCountLanguages] = useState(80);
  const [countVoices, setCountVoices] = useState(458);
  const [countAvatars, setCountAvatars] = useState(528);
  const navigate = useNavigate();
  
  useEffect(() => { 
      if (props.ttsLanguages.length > 0) {
          setCountLanguages(props.ttsLanguages.length);
      }
      if (props.ttsVoices.length > 0) {
          setCountVoices(props.ttsVoices.length);
      }
      if (props.predefinedAvatars.current.length > 0) {
          setCountAvatars(props.predefinedAvatars.current.length);
      }
  }, [props]);

  const [showCard, setShowCard] = useState("tab1");

  const handleProject = (category) => {
    setShowCard(category);
  };

  let videoplusStudioVideo = "https://www.youtube.com/embed/r6ECJUliZLg";
  if (props.language.includes('es')) {
    videoplusStudioVideo = "https://www.youtube.com/embed/cLOGBC4AMSs";
  } else if (props.language.includes('fr')) {
    videoplusStudioVideo = "https://www.youtube.com/embed/EqTXkN6T7f0";
  }
  const videoplusStudioVideo2 = "https://www.youtube.com/embed/N9PN41n6Xpg";
  const videoplusStudioVideo3 = "https://www.youtube.com/embed/-Ck8MRD8kdE";
  const videoplusStudioVideo4 = "https://www.youtube.com/embed/tpUkqp3WaZs";
  const videoplusStudioVideo5 = "https://www.youtube.com/embed/_pRKBfT1Tx4";
  const videoplusStudioVideo6 = "https://www.youtube.com/embed/BKcR5Dr76DA";
  const videoplusStudioVideo9 = "https://www.youtube.com/embed/k_FkxRI7gAg";
  const videoplusStudioVideo10 = "https://www.youtube.com/embed/mG4Urisf-Hg";
  const videoplusStudioVideo11 = "https://www.youtube.com/embed/se3EY9YO4XQ";
  const videoplusStudioVideo12 = "https://www.youtube.com/embed/Zlxr-QwLSmg";
  const videoplusStudioVideo7 = "https://www.youtube.com/embed/9KsV4v0tK8o";
  const videoplusStudioVideo8 = "https://www.youtube.com/embed/Gxk1t1I4Cf4";

  return (
    <>
      <section id="portfolio" className="py-20">
        <div className="container">
          <div className="flex flex-wrap">
            <div className="w-full px-4">
              <div className="mx-auto mb-4 text-center">
                <h4 className="mb-2 text-xl font-bold">
                  { t('Videoplus_Studio_subline') }
                </h4>
                <span className="mb-2 block text-lg font-semibold">
                  <font color="#ff6700"><CountUp end={countLanguages} enableScrollSpy /></font>
                  { t("Videoplus_Studio_highlights_1") }
                  <font color="#ff6700"><CountUp end={countVoices} enableScrollSpy /></font>
                  { t("Videoplus_Studio_highlights_2") }
                  <font color="#ff6700"><CountUp end={countAvatars} enableScrollSpy /></font>
                  { t("Videoplus_Studio_highlights_3") }
                </span>
              </div>
            </div>
          </div>

          <div className="flex w-full flex-wrap justify-center">
            <div className="w-full px-2">
              <ul className="mb-1 flex flex-wrap justify-between">
                <li className="mb-1">
                  <button
                    onClick={() => handleProject("tab1")}
                    className={`inline-block rounded-lg px-2 py-2 text-center text-base font-semibold transition ${
                      showCard === "tab1"
                        ? "bg-primary text-white"
                        : "text-body-color hover:bg-primary hover:text-white dark:text-dark-6"
                    }`}
                  >
                    { t('Product_Service_Demos') }
                  </button>
                </li>
                <li className="mb-1">
                  <button
                    onClick={() => handleProject("tab2")}
                    className={`inline-block rounded-lg px-2 py-2 text-center text-base font-semibold transition ${
                      showCard === "tab2"
                        ? "bg-primary text-white"
                        : "text-body-color hover:bg-primary hover:text-white dark:text-dark-6"
                    }`}
                  >
                    { t('Sales_Marketing') }
                  </button>
                </li>
                <li className="mb-1">
                  <button
                    onClick={() => handleProject("tab3")}
                    className={`inline-block rounded-lg px-2 py-2 text-center text-base font-semibold transition ${
                      showCard === "tab3"
                        ? "bg-primary text-white"
                        : "text-body-color hover:bg-primary hover:text-white dark:text-dark-6"
                    }`}
                  >
                    { t('Podcast_Helper') }
                  </button>
                </li>
                <li className="mb-1">
                  <button
                    onClick={() => handleProject("tab4")}
                    className={`inline-block rounded-lg px-2 py-2 text-center text-base font-semibold transition ${
                      showCard === "tab4"
                        ? "bg-primary text-white"
                        : "text-body-color hover:bg-primary hover:text-white dark:text-dark-6"
                    }`}
                  >
                    { t('Video_Transformer') }
                  </button>
                </li>
                <li className="mb-1">
                  <button
                    onClick={() => handleProject("tab5")}
                    className={`inline-block rounded-lg px-2 py-2 text-center text-base font-semibold transition ${
                      showCard === "tab5"
                        ? "bg-primary text-white"
                        : "text-body-color hover:bg-primary hover:text-white dark:text-dark-6"
                    }`}
                  >
                    { t('Storybook_Maker') }
                  </button>
                </li>
                <li className="mb-1">
                  <button
                    onClick={() => handleProject("tab6")}
                    className={`inline-block rounded-lg px-2 py-2 text-center text-base font-semibold transition ${
                      showCard === "tab6"
                        ? "bg-primary text-white"
                        : "text-body-color hover:bg-primary hover:text-white dark:text-dark-6"
                    }`}
                  >
                    { t('Multilingual_More') }
                  </button>
                </li>
              </ul>
              <span className={`mb-2 font-semibold dark:text-white ${
                  showCard !== "tab1" && "hidden"
                }`}
              >
                { t("Product_Service_Demos_description") }
              </span>
              <span className={`mb-2 font-semibold dark:text-white ${
                  showCard !== "tab2" && "hidden"
                }`}
              >
                { t("Sales_Marketing_description") }
              </span>
              <span className={`mb-2 font-semibold dark:text-white ${
                  showCard !== "tab3" && "hidden"
                }`}
              >
                { t("Podcast_Helper_description") }
              </span>
              <span className={`mb-2 font-semibold dark:text-white ${
                  showCard !== "tab4" && "hidden"
                }`}
              >
                { t("Video_Transformer_description") }
              </span>
              <span className={`mb-2 font-semibold dark:text-white ${
                  showCard !== "tab5" && "hidden"
                }`}
              >
                { t("Storybook_Maker_description") }
              </span>
              <span className={`mb-2 font-semibold dark:text-white ${
                  showCard !== "tab6" && "hidden"
                }`}
              >
                { t("Multilingual_More_description") }
              </span>
            </div>
          </div>
          <div className="flex flex-wrap">
            <PortfolioCard
              VideoHref={videoplusStudioVideo}
              category="tab1"
              showCard={showCard}
            />
            <PortfolioCard
              VideoHref={videoplusStudioVideo2}
              category="tab1"
              showCard={showCard}
            />
            <PortfolioCard
              VideoHref={videoplusStudioVideo3}
              category="tab2"
              showCard={showCard}
            />
            <PortfolioCard
              VideoHref={videoplusStudioVideo4}
              category="tab2"
              showCard={showCard}
            />
            <PortfolioCard
              VideoHref={videoplusStudioVideo5}
              category="tab3"
              showCard={showCard}
            />
            <PortfolioCard
              VideoHref={videoplusStudioVideo6}
              category="tab3"
              showCard={showCard}
            />
            <PortfolioCard
              VideoHref={videoplusStudioVideo9}
              category="tab4"
              showCard={showCard}
            />
            <PortfolioCard
              VideoHref={videoplusStudioVideo10}
              category="tab4"
              showCard={showCard}
            />
            <PortfolioCard
              VideoHref={videoplusStudioVideo11}
              category="tab5"
              showCard={showCard}
            />
            <PortfolioCard
              VideoHref={videoplusStudioVideo12}
              category="tab5"
              showCard={showCard}
            />
            <PortfolioCard
              VideoHref={videoplusStudioVideo7}
              category="tab6"
              showCard={showCard}
            />
            <PortfolioCard
              VideoHref={videoplusStudioVideo8}
              category="tab6"
              showCard={showCard}
            />
          </div>
        </div>

        <div className="row container-fluid text-center">
          <div className="col-xl-6">
            { t('View_YouTube_channel') }&nbsp;&nbsp;<SocialIcon url="https://www.youtube.com/@aitransformer" target="_blank" rel="noreferrer" />
          </div>
          <div className="col-xl-6">
            <button className="btn btn-info" onClick={() => navigate('/VoiceSamples')}>{t('Voice_Samples_hint')}</button>
          </div>
        </div>
      </section>
    </>
  );
};

export default Portfolio;

const PortfolioCard = ({
  showCard,
  category,
  VideoHref,
}) => {
  return (
    <>
      <div
        className={`w-full px-4 lg:w-1/2 ${
          showCard === category.toLowerCase()
            ? "block"
            : "hidden"
        }`}
      >
        <div className="relative my-6">
          <div className="overflow-hidden rounded-lg">
            <div className="embed-responsive embed-responsive-16by9"> 
              <LazyLoadComponent>
                <iframe width="560" height="315" src={VideoHref} title={ t('Videooplus_Studio_demo_video') } allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>             
              </LazyLoadComponent>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
