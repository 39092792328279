import React, {useState, useEffect} from 'react';
import { t } from 'react-i18nify';
import { apiBaseUrl } from '../utils';
import VpsHeader from './VpsHeader';

export default function SendPasswordResetLink(props) {
    const { setLastScreen, language, notify } = props;
    useEffect(() => {  
        setLastScreen('Send_Password_Reset_Link');
    }, [setLastScreen]);

    const initialSubmitButtonText = t('Send_Password_Reset_Link');
    const [submitButtonText, setSubmitButtonText] = useState("");
    useEffect(() => { 
      setSubmitButtonText(initialSubmitButtonText);
    }, [initialSubmitButtonText])
  
    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmitButtonText(t('sending'));
        const { email } = e.target.elements;
        const details = {
            email: email.value,
            locale: language.includes('fr') ? 'fr-FR' : language.includes('es') ? 'es-ES' : 'en-US', 
        };
        fetch(apiBaseUrl + "/auth/sendPasswordResetLink", {
            method: "POST",
            credentials: "include",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(details),
        })
        .then(async response => {
            if (!response.ok) {
                const result = await response.json();
                setSubmitButtonText(initialSubmitButtonText);
                notify({
                    message: result.err,
                    level: 'error',
                });
            } else {
                setSubmitButtonText(undefined);
                notify({
                    message: t('Password_reset_email_sent_message'),
                    level: 'info',
                });
            }
        })
        .catch(err => {
            setSubmitButtonText(initialSubmitButtonText);
            console.log(err);
            notify({
                message: t('GENERIC_ERROR_MESSAGE'),  //err.message,
                level: 'error',
            });
        })
    };
  
    return (
        <VpsHeader {...props}>
            <section className="flex flex-col max-w-[480px] mx-auto">
                {submitButtonText && (
                    <form className="form-horizontal card p-3 bg-light" onSubmit={handleSubmit}>
                        <div className="form-group row">
                            <div className="col-sm-12 my-3">
                                { t('send_password_reset_link_message') }
                            </div>
                            <label className="control-label col-sm-3" htmlFor="email">{ t('Email') }</label>
                            <div className="col-sm-9">
                                <input className="form-control" type="email" name="email" required />
                            </div>
                            <div className="col-sm-12 text-center my-3">
                                <button className="btn mb-3 mr-3 btn-info" type="submit">{submitButtonText}</button>
                            </div>
                        </div>
                    </form>
                )}
            </section>
        </VpsHeader>
    );
}
