import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { t } from 'react-i18nify';
import RangeSlider from 'react-bootstrap-range-slider';
import{ Space, Tooltip, Select } from 'antd';
import { MdHelpOutline } from "react-icons/md";
//import debounce from 'lodash/debounce';
import { MAX_DOCUMENT_FILE_SIZE, GENERATION_METHOD_OPTIONS, STORY_GENRE_OPTIONS, PICTURE_STYLE_OPTIONS, PICTURE_RESOLUTION_OPTIONS,
    PRESENTER_OPTIONS, STORY_OUTPUT_OPTIONS, axiosInstance, isUrlValid } from '../utils';
import VpsHeader from './VpsHeader';

export default function NewStorybookTask(props) {
    const { setResultMessage, setLastScreen, authenticated, sid, language, notify, userPresenters } = props;
    useEffect(() => {  
        setLastScreen('Storybook');
    }, [setLastScreen]);
    const navigate = useNavigate();
    
    const [generationMethod, setGenerationMethod] = useState("prompt");
    const [prompt, setPrompt] = useState(undefined);
    const [url, setUrl] = useState(undefined);
    const [documentFile, setDocumentFile] = useState(undefined);
    const [customStory, setCustomStory] = useState(undefined);
    const [genre, setGenre] = useState("");
    const [pictureStyle, setPictureStyle] = useState("base");
    const [pictureResolution, setPictureResolution] = useState("1");
    const [customStyle, setCustomStyle] = useState(undefined);
    const [selectedPresenter, setSelectedPresenter] = useState(undefined);
    const [description, setDescription] = useState('');
    const [creating, setCreating] = useState(false);
    const [burnSubtitles, setBurnSubtitles] = useState(false);
    const [fontSize, setFontSize] = useState(10);
    const [outputLength, setOutputLength] = useState(10);
    const [myPresenters, setMyPresenters] = useState([]);
    const [outputFormat, setOutputFormat] = useState("video");

    const customValue = 'custom';
    const minStoryLength = 10;
    const maxStoryLength = 600;

    useEffect(() => {
        if (authenticated && userPresenters.length) {
            setMyPresenters(userPresenters);
        } else {
            setMyPresenters(PRESENTER_OPTIONS);
        }
        if (myPresenters && myPresenters.length > 0) {
            setSelectedPresenter(myPresenters[0].key);
        }
    }, [authenticated, userPresenters, myPresenters]);

    const onChangeOfGenerationMethod = value => {
        setGenerationMethod(value);
        if (value === customValue) {
          setGenre('');
        }
    } 
    
    const onChangeOfPrompt = event => {
        setPrompt(event.target.value);
    } 
    
    const onChangeOfUrl = event => {
        setUrl(event.target.value);
    } 
    
    const selectDocumentFile = async event => {
        let currentFile = event.target.files[0];
        if (currentFile.size > MAX_DOCUMENT_FILE_SIZE) {  
            setDocumentFile(undefined);
            notify({
                message: t('ERROR_MESSAGE_FILE_FORMAT_WRONG_OR_SIZE_TOO_LARGE', { filename: currentFile.name }),
                level: 'warning',
            });
            return;
        }
      
        setDocumentFile(currentFile);
    }
    
    const onChangeOfCustomStory = event => {
        setCustomStory(event.target.value);
    } 
    
    const onChangeOfGenre = value => {
        setGenre(value);
    } 
    
    const onChangeOfPictureStyle = value => {
        setPictureStyle(value);
    } 
    
    const onChangeOfPictureResolution = value => {
        setPictureResolution(value);
    } 
    
    const onChangeOfCustomStyle = event => {
        setCustomStyle(event.target.value);
    } 
    
    const onChangeOfSelectedPresenter = value => {
        setSelectedPresenter(value);
    } 
    
    const onChangeOfOutputFormat = value => {
        setOutputFormat(value);
        setBurnSubtitles(false);
    } 

    const onChangeOfBurnSubtitles = event => {
        setBurnSubtitles(event.target.checked);
    } 
    
    const onChangeOfFontSize = event => {
        setFontSize(+event.target.value);
    } 

    const onChangeOfOutputLength = event => {
        if (isNaN(event.target.value)) {
            setOutputLength(undefined);
        } else {
            setOutputLength(+event.target.value);
        }
    } 

    const onChangeOfDescription = event => {
        setDescription(event.target.value);
    } 

    const saveTask = async () => {
        let storybookInput = undefined;
        if (generationMethod === 'prompt') {
            storybookInput = prompt?.trim();
            if (!storybookInput) {
                notify({
                    message: t('ERROR_MESSAGE_MISSING_PROMPT'),
                    level: 'error',
                });
                return;
            }
        } else if (generationMethod === 'url') {
            storybookInput = url?.trim();
            if (!storybookInput || !isUrlValid(storybookInput)) {
                notify({
                    message: t('ERROR_MESSAGE_MISSING_OR_INVALID_URL'),
                    level: 'error',
                });
                return;
            }
        } else if (generationMethod === 'document') {
            if (!documentFile) {
                notify({
                    message: t('ERROR_MESSAGE_MISSING_DOCUMENT_FILE'),
                    level: 'error',
                });
                return;
            }
        } else if (generationMethod === customValue) {
            storybookInput = customStory?.trim().replaceAll('{', '').replaceAll('}', '');
            if (!storybookInput) {
                notify({
                    message: t('ERROR_MESSAGE_MISSING_CUSTOM_STORY'),
                    level: 'error',
                });
                return;
            }
        }
        let style = 'base';
        if (pictureStyle === customValue) {
            let mystyle = customStyle.trim().replaceAll('{', '').replaceAll('}', '');
            if (mystyle) {
                style = customValue + '#' + mystyle;
            }
        } else {
            style = pictureStyle;
        }
        let storyTeller = selectedPresenter;
        if (!storyTeller && myPresenters && myPresenters.length > 0) {
            storyTeller = myPresenters[0].key;
        }
        /*
        if (!outputLength || outputLength < minLength || outputLength > maxLength.current) {
            notify({
                message: t('ERROR_MESSAGE_INVALID_OUTPUT_LENGTH', {'minLength': minLength, 'maxLength': maxLength.current}),
                level: 'error',
            });
            return;
        }
        */
        setCreating(true);

        let formData = new FormData();
        formData.append("sid", sid);
        formData.append("locale", language.includes('fr') ? 'fr-FR' : language.includes('es') ? 'es-ES' : 'en-US');
        formData.append("storybookMethod", generationMethod);
        formData.append("storybookInput", storybookInput);
        formData.append("storybookGenre", genre);
        formData.append("storybookPictureStyle", style);
        formData.append("storybookPictureResolution", pictureResolution);
        if (generationMethod === 'document' && documentFile) {
            formData.append("file", documentFile);
            formData.append("documentIsPdf", documentFile['type'] === 'application/pdf');
        }
        formData.append("storyTeller", storyTeller);
        formData.append("outputFormat", outputFormat);
        if (outputFormat === "video") {
            formData.append("burnSubtitles", burnSubtitles);
            if (burnSubtitles) {
                formData.append("fontSize", fontSize);
            }
        }
        formData.append("outputLength", outputLength);
        let myDescription = description;
        if (!myDescription) {
            myDescription = t('default_storybook_description', { method: generationMethod, genre: genre, style: style });
        }
        formData.append("description", myDescription);
  
        axiosInstance.post("/saveTask", formData, {  
            headers: {
                "Content-Type": "multipart/form-data",
            },
        }).then((response) => {
            //notify({
            //    message: response.data.message,
            //    level: 'success',
            //});
            setCreating(false);
            //setOption({ newStorybookTaskDialog: false, taskDialog: true });
            setResultMessage(response.data.message);
            navigate('/TaskResultSuccess');
        })
        .catch((reason) => {
            if (typeof reason.response != 'undefined' && typeof reason.response.data != 'undefined') {
                notify({
                    message: reason.response.data.err ? reason.response.data.err : reason.response.data.message,
                    level: 'error',
                });
            } else {
                notify({
                    message: t('ERROR_MESSAGE_UNKNOWN_REASON', { reason: reason }),
                    level: 'error',
                });
            }
            setCreating(false);
        })
    }
    //const saveTask2 = debounce(saveTask, 3000);
    //const saveTask3 = useCallback(saveTask2, []);   //to prevent duplicate submits
    
    return (
        <VpsHeader {...props}>
            <div>
                <div className="flex mx-2 mb-2">
                    { t("Save_storybook_task_info") }&nbsp;{ t('Save_task_info_extra') }
                </div>
                
                <div className="row m-2">
                    <div className="col-md-3">
                        { t('Story_Generation_Method') }
                    </div>
                    <div className="col-md-9">
                    <Select popupMatchSelectWidth={false} value={generationMethod} onChange={onChangeOfGenerationMethod}>
                        {GENERATION_METHOD_OPTIONS.map(item => 
                            (<Select.Option key={item.value} value={item.value}>{ t('GENERATION_METHOD_OPTIONS_' + item.value) }</Select.Option>)
                        )}
                    </Select>
                    {(generationMethod === 'prompt') && (
                        <textarea className="form-control" value={prompt} onChange={onChangeOfPrompt} />
                    )}
                    {(generationMethod === 'url') && (
                        <input className="form-control" type="text" value={url} onChange={onChangeOfUrl} />
                    )}
                    {(generationMethod === 'document') && (
                        <div>
                            <label className="btn mb-3 mr-3 btn-light">
                            <input type="file" accept="application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, 
                                application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, 
                                application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, 
                                application/vnd.oasis.opendocument.presentation, application/vnd.oasis.opendocument.spreadsheet, application/vnd.oasis.opendocument.text, 
                                application/pdf, application/rtf, application/xml, text/csv, text/html, text/plain" 
                                onChange={selectDocumentFile} />
                            </label>
                        </div>
                    )}
                    {generationMethod === customValue && (
                        <textarea className="form-control" value={customStory} placeholder={t('Custom_Generation_Method_hint')} onChange={onChangeOfCustomStory} />
                    )}
                    </div>
                </div>
                
                <div className="row m-2">
                    <div className="col-md-3">
                        { t('Story_Genre') }
                    </div>
                    <div className="col-md-9">
                    <Select popupMatchSelectWidth={false} showSearch value={genre} onChange={onChangeOfGenre} disabled={generationMethod === customValue}>
                        {STORY_GENRE_OPTIONS.map(item => 
                            (<Select.Option key={item.value} value={item.value}>{ t('STORY_GENRE_OPTIONS_' + item.value) }</Select.Option>)
                        )}
                    </Select>
                    </div>
                </div>
                
                <div className="row m-2">
                    <div className="col-md-3">
                        <Space wrap>
                            { t('Picture_Style') }
                            <Tooltip title={t('Picture_Style_tip')} color='purple'><MdHelpOutline /></Tooltip>
                        </Space>
                    </div>
                    <div className="col-md-9">
                        <Select popupMatchSelectWidth={false} showSearch value={pictureStyle} onChange={onChangeOfPictureStyle}>
                            {PICTURE_STYLE_OPTIONS.map(item => 
                                (<Select.Option key={item.value} value={item.value}>{item.text}</Select.Option>)
                            )}
                        </Select>
                        {pictureStyle === customValue && (
                            <textarea className="form-control" value={customStyle} placeholder={t('Custom_Style_hint')} onChange={onChangeOfCustomStyle} />
                        )}
                    </div>
                </div>
                
                {pictureStyle && pictureStyle !== customValue && (
                    <div className="row m-2">
                        <div className="col-md-3">
                            &nbsp;&nbsp;&nbsp;&nbsp;{ t('Style_Sample') }
                        </div>
                        <div className="col-md-9">
                            <img className="preview" src={'/assets/storybookMakerSamples/aiTransformer-' + pictureStyle.replaceAll(' ', '_').replaceAll(':', '_') + ".jpg"} alt={pictureStyle} />
                        </div>
                    </div>
                )}

                <div className="row m-2">
                    <div className="col-md-3">
                        <Space wrap>
                            { t('Picture_Resolution') }
                            <Tooltip title={t('Picture_Resolution_tip')} color='purple'><MdHelpOutline /></Tooltip>
                        </Space>
                    </div>
                    <div className="col-md-9">
                        <Select popupMatchSelectWidth={false} showSearch value={pictureResolution} onChange={onChangeOfPictureResolution}>
                            {PICTURE_RESOLUTION_OPTIONS.map(item => 
                                (<Select.Option key={item.value} value={item.value}>{item.text}</Select.Option>)
                            )}
                        </Select>
                    </div>
                </div>
      
                <div className="row m-2">
                    <div className="col-md-3">
                        <Space wrap>
                            { t('Select_Presenter') }
                            <Tooltip title={t('Select_Presenter_tip')} color='purple'><MdHelpOutline /></Tooltip>
                        </Space>
                    </div>
                    <div className="col-md-9">
                        <Select popupMatchSelectWidth={false} showSearch value={selectedPresenter} onChange={onChangeOfSelectedPresenter}>
                            {myPresenters.map((item) => (
                                <Select.Option key={item.key} value={item.key}>{item.key + ', ' + item.name + (item.voice ?  ', ' + item.voice : '')}</Select.Option>
                            ))}
                        </Select>
                    </div>
                </div>
    
                <div className="row m-2">
                    <div className="col-md-3">
                        { t('Output_Format') }
                    </div>
                    <div className="col-md-9">
                        <Select popupMatchSelectWidth={false} value={outputFormat} onChange={onChangeOfOutputFormat}>
                            {STORY_OUTPUT_OPTIONS.map(item => 
                                (<Select.Option key={item.value} value={item.value}>{ t('STORY_OUTPUT_OPTIONS_' + item.value) }</Select.Option>)
                            )}
                        </Select>
                    </div>
                </div>
                {outputFormat === 'video' && (
                    <div className="row m-2">
                        <div className="col-md-3">
                            <Space wrap>
                                &nbsp;&nbsp;&nbsp;&nbsp;{ t('Burn_Subtitles') }
                                <Tooltip title={t('Burn_Subtitles_tip')} color='purple'><MdHelpOutline /></Tooltip>
                            </Space>
                       </div>
                        <div className="col-md-9">
                            <input type="checkbox" checked={burnSubtitles} onChange={onChangeOfBurnSubtitles} />
                        </div>
                    </div>
                )}
                {burnSubtitles && (
                    <div className="row m-2">
                        <div className="col-md-3">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{ t('Font_Size') }
                        </div>
                        <div className="col-md-9">
                            <div className="input-group mb-3">
                                <RangeSlider min={10} max={20} step={1} value={fontSize} onChange={onChangeOfFontSize} />&nbsp;&nbsp;
                                <div className="input-group-append">
                                    <span className="input-group-text ml-2">
                                        {fontSize}
                                    </span>
                                </div>&nbsp;&nbsp;&nbsp;&nbsp;
                                <p style={{fontSize: `${fontSize}px`}}>{t('sample_text')}</p>
                            </div>
                        </div>
                    </div>
                )}

                <div className="row m-2">
                    <div className="col-md-3">
                        <Space wrap>
                            { t('Output_Length') }
                            <Tooltip title={t('Storybook_Output_Length_tip')} color='purple'><MdHelpOutline /></Tooltip>
                        </Space>
                    </div>
                    <div className="col-md-9">
                        <div className="input-group mb-3">
                            <RangeSlider min={minStoryLength} max={maxStoryLength} step={1} value={outputLength} onChange={onChangeOfOutputLength} />&nbsp;&nbsp;
                            <div className="input-group-append">
                                <span className="input-group-text ml-2">
                                    {outputLength}
                                </span>
                            </div>&nbsp;&nbsp;
                            <p>{t('seconds')}</p>
                        </div>
                    </div>
                </div>

                <div className="row m-2">
                    <div className="col-md-3">
                        <Space wrap>
                            { t('Description') }
                            <Tooltip title={t('Description_tip')} color='purple'><MdHelpOutline /></Tooltip>
                        </Space>
                    </div>
                    <div className="col-md-9">
                        <input type="text" className="form-control w-75" value={description} onChange={onChangeOfDescription} />
                    </div>
                </div>

                <div className="row m-3">
                    <div className="col-12 text-center">
                        <button
                            className="btn mb-3 mr-3 btn-info btn-lg"
                            onClick={() => saveTask()}
                            disabled={!authenticated || creating}
                        >
                            { creating ? t('sending') : t('Submit') }
                        </button><br/>
                        {!authenticated && (
                            t("Sign_in_to_submit_task")
                        )}
                    </div>
                </div>
            </div>
        </VpsHeader>
    );
}
