import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { t } from 'react-i18nify';
import { GoogleLoginButton, MicrosoftLoginButton } from "react-social-login-buttons";
import { apiBaseUrl } from '../utils';
import VpsHeader from './VpsHeader';

export default function Signin(props) {
    const { setLastScreen, language, notify } = props;
    useEffect(() => {  
        setLastScreen('SIGN_IN');
    }, [setLastScreen]);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        const { email, password } = e.target.elements;
        const details = {
            email: email.value,
            password: password.value,
            locale: language.includes('fr') ? 'fr-FR' : language.includes('es') ? 'es-ES' : 'en-US',
        };
        fetch(apiBaseUrl + '/auth/login', {
            method: "POST",
            credentials: "include",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(details),
        })
        .then(async response => {
            if (!response.ok) {
                const result = await response.json();
                notify({
                    message: result.err,
                    level: 'error',
                });
            } else {
                //const user = await response.json();
                //setMessage(`You are now logged in as ${user.displayName}, welcome!`);
                window.location.replace('/');
            }
        })
        .catch(err => {
            console.log(err);
            notify({
                message: t('GENERIC_ERROR_MESSAGE'),  //err.message,
                level: 'error',
            });
        })
    };

    return (
        <VpsHeader {...props}>

        <section className="flex flex-col max-w-[480px] mx-auto">
            <GoogleLoginButton onClick={() => window.open(`${apiBaseUrl}/auth/google`, "_self")}>
                <span>{ t("Sign_in_with") } Google</span>
            </GoogleLoginButton>
            <MicrosoftLoginButton onClick={() => window.open(`${apiBaseUrl}/auth/microsoft`, "_self")}>
                <span>{ t("Sign_in_with") } Microsoft</span>
            </MicrosoftLoginButton>
            <span className="flex flex-col items-center">{ t("OR") }</span>
            <div>
                <form className="form-horizontal col-sm-12 card p-1 bg-light" onSubmit={handleSubmit}>
                    <div className="form-group row m-1">
                        <label className="control-label col-sm-4" htmlFor="email">{ t("Email") }</label>
                        <div className="col-sm-8">
                            <input className="form-control" type="email" name="email" autoComplete="email" required />
                        </div>
                    </div>
                    <div className="form-group row m-1">
                        <label className="control-label col-sm-4" htmlFor="password">{ t("Password") }</label>
                        <div className="col-sm-8">
                            <input className="form-control" type="password" name="password" autoComplete="password" required />
                        </div>
                    </div>
                    <div className="form-group row m-2">
                        <div className="col-sm-12 text-center">
                            <button className="btn btn-info" type="submit">{ t("SIGN_IN") }</button>
                        </div>
                    </div>
                </form>
            </div>
            <div className="row m-3">
                <div className="col-12 text-center">
                    { t("Forgot_password") }&nbsp;&nbsp;&nbsp;
                    <button className="btn btn-info" onClick={() => navigate('/SendPasswordResetLink')}>{ t("Reset_it") }</button>
                </div>
            </div>
            <div className="row m-3">
                <div className="col-12 text-center">
                    { t("No_account") }&nbsp;&nbsp;&nbsp;
                    <button className="btn btn-info" onClick={() => navigate('/Signup')}>{ t("SIGN_UP") }</button>
                </div>
            </div>
        </section>

        </VpsHeader>
    );
}
