import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { t } from 'react-i18nify';
import{ Space, Tooltip, Select, InputNumber } from 'antd';
import { MdHelpOutline } from "react-icons/md";
//import debounce from 'lodash/debounce';
import { MAX_VIDEO_FILE_SIZE, QUICK_EDITS_ACTION_OPTIONS, AUDIO_STEMS_OPTIONS, ADJUST_SPEED_OPTIONS, axiosInstance } from '../utils';
import VpsHeader from './VpsHeader';

export default function NewQuickEditsTask(props) {
    const { setResultMessage, setLastScreen, authenticated, sid, language, notify } = props;
    useEffect(() => {  
        setLastScreen('Quick_Edits');
    }, [setLastScreen]);
    const navigate = useNavigate();
    
    const [action, setAction] = useState('removeAudioStems');
    const [mediaFile, setMediaFile] = useState(undefined);
    const [newAudioFile, setNewAudioFile] = useState(undefined);
    const [keepExistingAudio, setKeepExistingAudio] = useState(false);
    const [audioStemsToKeep, setAudioStemsToKeep] = useState('vocals');
    const [adjustSpeed, setAdjustSpeed] = useState('0');
    const [description, setDescription] = useState('');
    const [creating, setCreating] = useState(false);

    const selectMediaFile = async event => {
        let currentFile = event.target.files[0];
        if (currentFile.size < 100 || currentFile.size > MAX_VIDEO_FILE_SIZE) {  //a too small file is probably invalid
            setMediaFile(undefined);
            notify({
                message: t('ERROR_MESSAGE_FILE_FORMAT_WRONG_OR_SIZE_TOO_LARGE', { filename: currentFile.name }),
                level: 'warning',
            });
            return;
        }
        setMediaFile(currentFile);
        setDescription(t('QUICK_EDITS_ACTION_OPTIONS_' + action) + ': ' + currentFile.name);
    }
  
    const selectNewAudioFile = async event => {
        let currentFile = event.target.files[0];
        if (currentFile.size < 100 || currentFile.size > MAX_VIDEO_FILE_SIZE) {  //a too small file is probably invalid
            setNewAudioFile(undefined);
            notify({
                message: t('ERROR_MESSAGE_FILE_FORMAT_WRONG_OR_SIZE_TOO_LARGE', { filename: currentFile.name }),
                level: 'warning',
            });
            return;
        }
        setNewAudioFile(currentFile);
        //setDescription(t('QUICK_EDITS_ACTION_OPTIONS_' + action) + ' ' + currentFile.name);
    }
  
    const saveTask = async () => {
        if (!mediaFile) {
            notify({
                message: t('ERROR_MESSAGE_MISSING_MEDIA_FILE'),
                level: 'error',
            });
            return;
        }
        if (action == 'addAudioToVideo' && !newAudioFile) {
            notify({
                message: t('ERROR_MESSAGE_MISSING_NEW_AUDIO_FILE'),
                level: 'error',
            });
            return;
        }
        setCreating(true);

        let formData = new FormData();
        formData.append("sid", sid);
        formData.append("locale", language.includes('fr') ? 'fr-FR' : language.includes('es') ? 'es-ES' : 'en-US');
        formData.append("quickEditsAction", action);
        formData.append("file", mediaFile);
        if (newAudioFile) {
            formData.append("file", newAudioFile);
        }
        formData.append("keepExistingAudio", keepExistingAudio);
        formData.append("audioStemsToKeep", audioStemsToKeep);
        formData.append("adjustSpeed", adjustSpeed);
        formData.append("description", description);

        axiosInstance.post("/saveTask", formData, {  
            headers: {
                "Content-Type": "multipart/form-data",
            },
        }).then((response) => {
            //notify({
            //    message: response.data.message,
            //    level: 'success',
            //});
            setCreating(false);
            //setOption({ newTranscriptionTaskDialog: false, taskDialog: true });
            setResultMessage(response.data.message);
            navigate('/TaskResultSuccess');
        })
        .catch((reason) => {
            if (typeof reason.response != 'undefined' && typeof reason.response.data != 'undefined') {
                notify({
                    message: reason.response.data.err ? reason.response.data.err : reason.response.data.message,
                    level: 'error',
                });
            } else {
                notify({
                    message: t('ERROR_MESSAGE_UNKNOWN_REASON', { reason: reason }),
                    level: 'error',
                });
            }
            setCreating(false);
        })
    }
    //const saveTask2 = debounce(saveTask, 3000);
    //const saveTask3 = useCallback(saveTask2, []);   //to prevent duplicate submits
    
    return (
        <VpsHeader {...props}>
            <div>
                <div className="flex mx-2 mb-2">
                    { t("Save_quick_edits_task_info") }&nbsp;{ t('Save_task_info_extra') }
                </div>
                {/*<div className="row m-2">
                    { t("Save_transcription_task_instruction") }
                </div>*/}
                
                <div className="row m-2">
                    <div className="col-md-3">
                        { t('Action') }
                    </div>
                    <div className="col-md-9">
                        <Select popupMatchSelectWidth={false} value={action} 
                            onChange={(value) => {
                                setAction(value); 
                                setMediaFile(undefined); 
                                setNewAudioFile(undefined);
                                setKeepExistingAudio(false);
                                setAudioStemsToKeep('vocals');
                                setAdjustSpeed('0');
                                setDescription('');
                            }}
                        >
                            {QUICK_EDITS_ACTION_OPTIONS.map(item => (
                                <Select.Option key={item.value} value={item.value}>{ t('QUICK_EDITS_ACTION_OPTIONS_' + item.value) }</Select.Option>
                            ))}
                        </Select>
                    </div>
                </div>

                <div className="row mx-2 my-3">
                    <div className="col-md-3">
                        { t('Select_Media_File', {maxSize: MAX_VIDEO_FILE_SIZE/1048576}) }
                    </div>
                    <div className="col-md-9">
                        <label className="btn mb-3 mr-3 btn-light">
                            <input type="file" accept={action == 'removeAudioStems' ? "audio/*" : "video/*"} onChange={selectMediaFile} />
                        </label>
                        {mediaFile && mediaFile.type.startsWith('audio') && (
                            <div>
                                <audio id="mymedia" controls src={URL.createObjectURL(mediaFile)} />
                            </div>
                        )}
                        {mediaFile && mediaFile.type.startsWith('video') && (
                            <div>
                                <video id="mymedia" width="320" height="240" controls src={URL.createObjectURL(mediaFile)} />
                            </div>
                        )}
                    </div>
                </div>

                {action == 'removeAudioStems' && (
                    <div className="row m-2">
                        <div className="col-md-3">
                            <Space wrap>
                                { t('Audio_Stems_to_Remove') }
                                <Tooltip title={t('Audio_Stems_to_Remove_tip')} color='purple'><MdHelpOutline /></Tooltip>
                            </Space>
                        </div>
                        <div className="col-md-9">
                            <Select popupMatchSelectWidth={false} value={audioStemsToKeep} onChange={(value) => setAudioStemsToKeep(value)}>
                                {AUDIO_STEMS_OPTIONS.filter(item => item.value !== 'all').map(item => (
                                    <Select.Option key={item.value} value={item.value}>{ t('AUDIO_STEMS_OPTIONS_' + item.value) }</Select.Option>
                                ))}
                            </Select>
                        </div>
                    </div>
                 )}
                 
                 {action == 'removeAudioFromVideo' && (
                    <div className="row m-2">
                        <div className="col-md-3">
                            <Space wrap>
                                { t('Audio_Stems_to_Remove') }
                                <Tooltip title={t('Audio_Stems_to_Remove_tip')} color='purple'><MdHelpOutline /></Tooltip>
                            </Space>
                        </div>
                        <div className="col-md-9">
                            <Select popupMatchSelectWidth={false} value={audioStemsToKeep} onChange={(value) => setAudioStemsToKeep(value)}>
                                {AUDIO_STEMS_OPTIONS.map(item => (
                                    <Select.Option key={item.value} value={item.value}>{ t('AUDIO_STEMS_OPTIONS_' + item.value) }</Select.Option>
                                ))}
                            </Select>
                        </div>
                    </div>
                )}

                 {action == 'addAudioToVideo' && (
                    <div className="row m-2">
                        <div className="col-md-3">
                            <Space wrap>
                                { t('Keep_Existing_Audio') }
                                <Tooltip title={t('Keep_Existing_Audio_tip')} color='purple'><MdHelpOutline /></Tooltip>
                            </Space>
                        </div>
                        <div className="col-md-9">
                            <input type="checkbox" checked={keepExistingAudio} onChange={(e) => setKeepExistingAudio(e.target.checked)} />
                        </div>
                    </div>
                )}

                {action == 'addAudioToVideo' && keepExistingAudio && (
                    <div className="row m-2">
                        <div className="col-md-3">
                            <Space wrap>
                                &nbsp;&nbsp;&nbsp;&nbsp;{ t('Audio_Stems_to_Keep') }
                                <Tooltip title={t('Audio_Stems_to_Keep_tip')} color='purple'><MdHelpOutline /></Tooltip>
                            </Space>
                        </div>
                        <div className="col-md-9">
                            <Select popupMatchSelectWidth={false} value={audioStemsToKeep} onChange={(value) => setAudioStemsToKeep(value)}>
                                {AUDIO_STEMS_OPTIONS.map(item => (
                                    <Select.Option key={item.value} value={item.value}>{ t('AUDIO_STEMS_OPTIONS_' + item.value) }</Select.Option>
                                ))}
                            </Select>
                        </div>
                    </div>
                )}

                {action == 'addAudioToVideo' && (
                    <div className="row mx-2 my-3">
                        <div className="col-md-3">
                            { t('Select_New_Audio_File', {maxSize: MAX_VIDEO_FILE_SIZE/1048576}) }
                        </div>
                        <div className="col-md-9">
                            <label className="btn mb-3 mr-3 btn-light">
                                <input type="file" accept="audio/*" onChange={selectNewAudioFile} />
                            </label>
                            {newAudioFile && (
                                <div>
                                    <audio id="myaudio" controls src={URL.createObjectURL(newAudioFile)} />
                                </div>
                            )}
                        </div>
                    </div>
                )}

                {action == 'addAudioToVideo' && newAudioFile && (
                    <div className="row m-2">
                        <div className="col-md-3">
                            <Space wrap>
                                { t('Speed_Adjustment') }
                            </Space>
                        </div>
                        <div className="col-md-9">
                            <Select popupMatchSelectWidth={false} value={adjustSpeed} onChange={(value) => setAdjustSpeed(value)}>
                                {ADJUST_SPEED_OPTIONS.map(item => (
                                    <Select.Option key={item.value} value={item.value}>{ t('ADJUST_SPEED_OPTIONS_' + item.value) }</Select.Option>
                                ))}
                            </Select>
                        </div>
                    </div>
                )}

                <div className="row m-2">
                    <div className="col-md-3">
                        <Space wrap>
                            { t('Description') }
                            <Tooltip title={t('Description_tip')} color='purple'><MdHelpOutline /></Tooltip>
                        </Space>
                    </div>
                    <div className="col-md-9">
                        <input type="text" className="form-control w-75" value={description} onChange={(e) => setDescription(e.target.value)} />
                    </div>
                </div>

                <div className="row m-3">
                    <div className="col-12 text-center">
                        <button
                            className="btn mb-3 mr-3 btn-info btn-lg"
                            onClick={() => saveTask()}
                            disabled={!authenticated || creating}
                        >
                            { creating ? t('sending') : t('Submit') }
                        </button><br/>
                        {!authenticated && (
                            t("Sign_in_to_submit_task")
                        )}
                    </div>
                </div>
            </div>
        </VpsHeader>
    );
}
