import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { t } from 'react-i18nify';
import{ Space, Tooltip, Select, InputNumber, ColorPicker } from 'antd';
import { MdHelpOutline } from "react-icons/md";
import RangeSlider from 'react-bootstrap-range-slider';
//import debounce from 'lodash/debounce';
import { MAX_VIDEO_FILE_SIZE, EFFECT_OPTIONS, axiosInstance } from '../utils';
import VpsHeader from './VpsHeader';

export default function NewVideoEffectTask(props) {
    const { setResultMessage, setLastScreen, authenticated, sid, language, notify } = props;
    useEffect(() => {  
        setLastScreen('Video_Effect');
    }, [setLastScreen]);
    const navigate = useNavigate();

    const [videoFile, setVideoFile] = useState(undefined);
    const [selectedEffect, setSelectedEffect] = useState(EFFECT_OPTIONS[0].value);
    const [color1, setColor1] = useState('#4484d4');
    const [color2, setColor2] = useState('#b67dfc');
    const [intensity, setIntensity] = useState(100);
    const [description, setDescription] = useState('');
    const [creating, setCreating] = useState(false);
    const [outputLength, setOutputLength] = useState(undefined);

    const selectVideoFile = async event => {
        let currentFile = event.target.files[0];
        if (currentFile.size < 100 || currentFile.size > MAX_VIDEO_FILE_SIZE) {  //a too small file is probably invalid
            setVideoFile(undefined);
            notify({
                message: t('ERROR_MESSAGE_FILE_FORMAT_WRONG_OR_SIZE_TOO_LARGE', { filename: currentFile.name }),
                level: 'warning',
            });
            return;
        }
        setVideoFile(currentFile);
        setOutputLength(URL.createObjectURL(currentFile).duration);
        setDescription(t('To_apply_effect', {effect: selectedEffect, filename: currentFile.name}));
    }
  
    const onChangeOfSelectedEffect = value => {
        setSelectedEffect(value);
        setDescription(t('To_apply_effect', {effect: value, filename: videoFile?.name}));
    } 
    
    const onChangeOfDescription = event => {
        setDescription(event.target.value);
    } 

    const saveTask = async () => {
        if (!videoFile) {
            notify({
                message: t('ERROR_MESSAGE_MISSING_VIDEO_FILE'),
                level: 'error',
            });
            return;
        }
        /*
        if (selectedEffect === 'filter_duotone_custom' && (!color1 || !color2)) {
            notify({
                message: t('ERROR_MESSAGE_MISSING_CUSTOM_COLORS'),
                level: 'error',
            });
            return;
        }
        if (!outputLength || outputLength < minLength || outputLength > maxLength.current) {
            notify({
                message: t('ERROR_MESSAGE_INVALID_OUTPUT_LENGTH', {'minLength': minLength, 'maxLength': maxLength.current}),
                level: 'error',
            });
            return;
        }
        */
        setCreating(true);

        let formData = new FormData();
        formData.append("sid", sid);
        formData.append("locale", language.includes('fr') ? 'fr-FR' : language.includes('es') ? 'es-ES' : 'en-US');
        formData.append("specialEffect", selectedEffect);
        if (selectedEffect === 'filter_duotone_custom') {
            formData.append("color1", typeof color1 === 'string' ? color1 : color1?.toHexString());
            formData.append("color2", typeof color2 === 'string' ? color2 : color2?.toHexString());
            formData.append("intensity", intensity/100);
        }
        if (!outputLength || outputLength < 1) {
            formData.append("outputLength", document.getElementById('mymedia').duration);
        } else {
            formData.append("outputLength", outputLength);
        }
        formData.append("description", description);
        formData.append("file", videoFile);

        axiosInstance.post("/saveTask", formData, {  
            headers: {
                "Content-Type": "multipart/form-data",
            },
        }).then((response) => {
            //notify({
            //    message: response.data.message,
            //    level: 'success',
            //});
            setCreating(false);
            //setOption({ newVideoEffectTaskDialog: false, taskDialog: true });
            setResultMessage(response.data.message);
            navigate('/TaskResultSuccess');
        })
        .catch((reason) => {
            if (typeof reason.response != 'undefined' && typeof reason.response.data != 'undefined') {
                notify({
                    message: reason.response.data.err ? reason.response.data.err : reason.response.data.message,
                    level: 'error',
                });
            } else {
                notify({
                    message: t('ERROR_MESSAGE_UNKNOWN_REASON', { reason: reason }),
                    level: 'error',
                });
            }
            setCreating(false);
        })
    }
    //const saveTask2 = debounce(saveTask, 3000);
    //const saveTask3 = useCallback(saveTask2, []);   //to prevent duplicate submits
    
    return (
        <VpsHeader {...props}>
            <div>
                <div className="flex mx-2 mb-2">
                    { t("Save_video_effect_task_info") }&nbsp;{ t('Save_task_info_extra') }
                </div>
                {/*<div className="row m-2">
                    { t("Save_video_effect_task_instruction") }
                </div>*/}
                
                <div className="row mx-2 my-3">
                    <div className="col-md-3">
                        { t('Select_Video_File', {maxSize: MAX_VIDEO_FILE_SIZE/1048576}) }
                    </div>
                    <div className="col-md-9">
                        <label className="btn mb-3 mr-3 btn-light">
                            <input type="file" accept="video/mp4,video/x-m4v,video/*" onChange={selectVideoFile} />
                        </label>
                        {videoFile && (
                            <div>
                                <video id="mymedia" width="320" height="240" controls src={URL.createObjectURL(videoFile)} />
                            </div>
                        )}
                    </div>
                </div>

                <div className="row m-2">
                    <div className="col-md-3">
                        { t('Select_Effect') }
                    </div>
                    <div className="col-md-9">
                        <Select popupMatchSelectWidth={false} showSearch value={selectedEffect} onChange={onChangeOfSelectedEffect}>
                        {EFFECT_OPTIONS.map(item => 
                            (<Select.Option key={item.value} value={item.value}>{item.text}</Select.Option>)
                        )}
                        </Select>
                    </div>
                </div>
                {selectedEffect && selectedEffect !== 'filter_duotone_custom' && (
                    <div className="row m-2">
                        <div className="col-md-3">
                            &nbsp;&nbsp;&nbsp;&nbsp;{ t('Sample_Effect') }
                        </div>
                        <div className="col-md-9 flex flex-row">
                            <img className="preview" src={"/assets/effectSamples/" + (selectedEffect.startsWith("cartoonizer_") ? "audrey2.jpg" : 
                                ['filter_overall_enhance', 'filter_enhance_with_face_restore'].includes(selectedEffect) ? "taylor1.jpg" : "demo0.jpg")} alt="" />&nbsp;
                            <img className="preview" src={"/assets/effectSamples/" + (selectedEffect.startsWith("cartoonizer_") ? "cartoonized_audrey2_" + selectedEffect.substring(12) + ".jpg" : 
                                ['filter_overall_enhance', 'filter_enhance_with_face_restore'].includes(selectedEffect) ? selectedEffect.replace('filter', 'taylor1') + ".jpg" : "demo0_" + selectedEffect.substring(7) + "_0.jpg")} alt="" />
                        </div>
                    </div>
                )}
                {selectedEffect === 'filter_duotone_custom' && (
                    <div className="row m-2">
                        <div className="col-md-3">
                            <Space wrap>
                                &nbsp;&nbsp;&nbsp;&nbsp;{ t('Color1') }
                                <Tooltip title={t('Color1_tip')} color='purple'><MdHelpOutline /></Tooltip>
                            </Space>
                       </div>
                        <div className="col-md-9">
                            <ColorPicker disabledAlpha={true} showText={true} value={color1} onChange={setColor1} />
                        </div>
                    </div>
                )}
                {selectedEffect === 'filter_duotone_custom' && (
                    <div className="row m-2">
                        <div className="col-md-3">
                            <Space wrap>
                                &nbsp;&nbsp;&nbsp;&nbsp;{ t('Color2') }
                                <Tooltip title={t('Color2_tip')} color='purple'><MdHelpOutline /></Tooltip>
                            </Space>
                       </div>
                        <div className="col-md-9">
                            <ColorPicker disabledAlpha={true} showText={true} value={color2} onChange={setColor2} />
                        </div>
                    </div>
                )}
                {selectedEffect === 'filter_duotone_custom' && (
                    <div className="row m-2">
                        <div className="col-md-3">
                            <Space wrap>
                                &nbsp;&nbsp;&nbsp;&nbsp;{ t('Intensity') }
                                <Tooltip title={t('Intensity_tip')} color='purple'><MdHelpOutline /></Tooltip>
                            </Space>
                       </div>
                        <div className="col-md-9">
                            <div className="input-group mb-3">
                                <RangeSlider min={10} max={100} step={1} value={intensity} onChange={(e) => setIntensity(e.target.value)} />&nbsp;&nbsp;
                                <div className="input-group-append">
                                    <span className="input-group-text ml-2">
                                        {intensity}%
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                <div className="row m-2">
                    <div className="col-md-3">
                        <Space wrap>
                            { t('Output_Length') }
                            <Tooltip title={t('Output_Length_tip')} color='purple'><MdHelpOutline /></Tooltip>
                        </Space>
                    </div>
                    <div className="col-md-9">
                        <InputNumber
                            min={1.0} step={0.01}
                            value={outputLength}
                            addonAfter={t('seconds')}
                            onChange={(value) => setOutputLength(value)}
                        />
                    </div>
                </div>

                <div className="row m-2">
                    <div className="col-md-3">
                        <Space wrap>
                            { t('Description') }
                            <Tooltip title={t('Description_tip')} color='purple'><MdHelpOutline /></Tooltip>
                        </Space>
                    </div>
                    <div className="col-md-9">
                        <input type="text" className="form-control w-75" value={description} onChange={onChangeOfDescription} />
                    </div>
                </div>

                <div className="row m-3">
                    <div className="col-12 text-center">
                        <button
                            className="btn mb-3 mr-3 btn-info btn-lg"
                            onClick={() => saveTask()}
                            disabled={!authenticated || creating}
                        >
                            { creating ? t('sending') : t('Submit') }
                        </button><br/>
                        {!authenticated && (
                            t("Sign_in_to_submit_task") 
                        )}
                    </div>
                </div>
            </div>
        </VpsHeader>
    );
}
