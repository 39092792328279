import React from "react";
import { t } from 'react-i18nify';
import { TopBar } from "./TopBar.tsx";
import { Overlay } from "./Overlay.tsx";
import { Sidebar } from "./sidebar/Sidebar.tsx";
import { DashboardProvider } from "./Provider.tsx";

//interface LayoutProps {
//  children: React.ReactNode;
//}

const style = {
  container: "bg-gray-100 h-screen overflow-hidden relative",
  main: "h-screen overflow-auto px-2 pb-10 dark:bg-dark dark:text-white m-2 text-lg font-semibold",
  mainContainer: "flex flex-col h-screen pl-0 w-full lg:pl-24 lg:space-y-4",
};

export function DashboardLayout(props) {
  return (
    <DashboardProvider>
      <div className={style.container}>
        <div className="flex items-start">
          <Overlay />
          <Sidebar mobileOrientation="end" />
          <div className={style.mainContainer}>
            <TopBar {...props} />
            {props.lastScreen.toUpperCase() !== 'HOME' && (
              <div className="m-2 text-center text-xl font-bold">
                {t(props.lastScreen)}
              </div>
            )}
            <main className={style.main}>{props.children}</main>
          </div>
        </div>
      </div>
    </DashboardProvider>
  );
}
