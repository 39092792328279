import React, { useEffect, useState } from 'react';
import { useLocalStorage } from 'usehooks-ts';
import { t } from 'react-i18nify';
import { Tooltip } from 'antd';

function ThemeSwitch() {
  const [theme, setTheme] = useLocalStorage('theme', 'light');

  useEffect(() => {
    document.body.classList.remove('light', 'dark');
    document.body.classList.add(theme);
  }, [theme])

  const [enabled, setEnabled] = useState(theme === 'light');

  const handleThemeChange = (enabled: boolean) => {
    setTheme(enabled ? 'light' : 'dark');
    setEnabled(enabled);
  }

  return (
    <div
      className="w-12 rounded-full h-6 bg-contrast cursor-pointer flex items-center px-1/2 relative select-none"
      onClick={() => handleThemeChange(!enabled)}
    >
      <Tooltip title={t('toggle_theme')}>
        <span 
          role="img"
          aria-label="theme emoji"
        >
          {enabled ? '🌞' : '🌜'}
        </span>
      </Tooltip>
    </div>
  )
}

export default ThemeSwitch;
