import styled from 'styled-components';
import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Table } from 'react-virtualized';
import TextareaAutosize from 'react-textarea-autosize';
import unescape from 'lodash/unescape';
import debounce from 'lodash/debounce';
import { t } from 'react-i18nify';
import { Select } from 'antd';
import { MdAdd, MdRemove, MdOutlineChangeCircle, MdOutlineNorth, MdOutlineSouth } from "react-icons/md";
import { MAX_TEXT_SIZE2, PRESENTER_OPTIONS, MAX_DOCUMENT_FILE_SIZE, MAX_AUDIO_FILE_SIZE, AUDIO_OPTIONS, resizeImage, axiosInstance, apiBaseUrl } from '../utils';

const Style = styled.div`
    position: relative;
    box-shadow: 0px 5px 25px 5px rgb(0 0 0 / 85%);
    background-color: rgb(0 0 0 / 75%);
    padding-bottom: 10px;

    .ReactVirtualized__Table {
        .ReactVirtualized__Table__Grid {
            outline: none;
        }

        .ReactVirtualized__Table__row {
            .item {
                height: 100%;
                padding: 10px;

                .textarea {
                    border: none;
                    width: 100%;
                    height: 100%;
                    color: #fff;
                    font-size: 15px;
                    padding: 10px;
                    text-align: start;
                    background-color: rgba(255, 255, 255, 0.15);
                    border: 1px solid rgba(255, 255, 255, 0.1);
                    transition: all 0.2s ease;
                    resize: none;
                    outline: none;

                    &.highlight {
                        background-color: rgb(0 87 158);
                        border: 1px solid rgba(255, 255, 255, 0.3);
                    }

                    &.illegal {
                        background-color: rgb(123 29 0);
                        border: 1px solid rgba(255, 255, 255, 0.3);
                    }
                }

                .op {
                    font-size: 12px;
                    cursor: pointer;
                    margin-top: 5px;
                    margin-right: 10px;
                    color: #fff;
                }
            }
        }
    }
`;

export default function ImageSubtitles({ currentIndex, setCurrentTime, subtitle, updateSub, addSub, removeSub, 
    authenticated, userPresenters, carouselData, moveSub, language, sid, notify }) {
        
    const [height, setHeight] = useState(100);
    const [width, setWidth] = useState(document.body.clientWidth * 0.42);
    const [myPresenters, setMyPresenters] = useState([]);
    const tableRef = useRef();
    
    useEffect(() => {
        document.getElementById('subtitle_panel');
    }, []);

    useEffect(() => {
        if (authenticated && userPresenters.length) {
            setMyPresenters(userPresenters);
        } else {
            setMyPresenters(PRESENTER_OPTIONS);
        }
    }, [authenticated, userPresenters]);

    const resize = useCallback(() => {
        //setHeight(document.body.clientHeight - (!carouselData ? 280 : 50));
        //setWidth(Math.floor(document.body.clientWidth * 0.45));    //45% of the screen width
        setHeight(document.body.clientHeight - 185);
        setWidth(Math.floor(document.getElementById('subtitle_panel').clientWidth - 48));
        tableRef.current?.recomputeRowHeights();
      }, [setHeight, setWidth, tableRef]);

    useEffect(() => {
        resize();
        if (!resize.init) {
            resize.init = true;
            const debounceResize = debounce(resize, 500);
            window.addEventListener('resize', debounceResize);
        }
    }, [resize]);

    const getCharPerSecond = (value) => {
        if (!value) value = "1";
        return myPresenters.filter(x => x.key === parseInt(value))[0]?.charPerSecond;
    }

    const onRowClick = (event, rowData) => {
        const elementId = event.target.id || event.target.farthestViewportElement?.id;
        if (!["choose-file", "add-sub", "remove-sub", "move-up", "move-down"].includes(elementId)) {
            setCurrentTime(rowData.startTime + 0.1);
        }
    }
    //const onRowClick2 = debounce(onRowClick, 500);

    const changeImage = async(file) => {
        if (file) {
            if (file.size < 100 || file.size > MAX_DOCUMENT_FILE_SIZE) {  //a too small file is probably invalid
                notify({
                    message: t('ERROR_MESSAGE_FILE_FORMAT_WRONG_OR_SIZE_TOO_LARGE', { filename: file.name }),
                    level: 'error',
                });
            } else {
                try {
                    file = await resizeImage(file);
                } catch(err) {
                    notify({
                        message: t('ERROR_MESSAGE_FILE_FORMAT_WRONG_OR_SIZE_TOO_LARGE', { filename: file.name }),
                        level: 'error',
                    });
                    return;
                }
                let formData = new FormData();
                formData.append("sid", sid);
                formData.append("locale", language.includes('fr') ? 'fr-FR' : language.includes('es') ? 'es-ES' : 'en-US');
                formData.append("type", 'save_image');
                formData.append("imagesFolder", carouselData.dir);
                formData.append("file", file);
        
                axiosInstance.post("/runPresentationMaker", formData, {  
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }).then((response) => {
                    const fname = response.data;
                    updateSub(subtitle[currentIndex], { fname: fname });
                })
                .catch((reason) => {
                    if (typeof reason.response != 'undefined' && typeof reason.response.data != 'undefined') {
                        notify({
                            message: reason.response.data.err ? reason.response.data.err : reason.response.data.message,
                            level: 'error',
                        });
                    } else {
                        notify({
                            message: t('ERROR_MESSAGE_UNKNOWN_REASON', { reason: reason }),
                            level: 'error',
                        });
                    }
                })
            }
        }
    }

    const selectAudioFile = async(file) => {
        if (file.size < 100 || file.size > MAX_AUDIO_FILE_SIZE) {  //a too small file is probably invalid
            notify({
                message: t('ERROR_MESSAGE_FILE_FORMAT_WRONG_OR_SIZE_TOO_LARGE', { filename: file.name }),
                level: 'warning',
            });
            return;
        }
        let formData = new FormData();
        formData.append("sid", sid);
        formData.append("locale", language.includes('fr') ? 'fr-FR' : language.includes('es') ? 'es-ES' : 'en-US');
        formData.append("type", 'save_audio');
        formData.append("imagesFolder", carouselData.dir);
        formData.append("file", file);

        axiosInstance.post("/runPresentationMaker", formData, {  
            headers: {
                "Content-Type": "multipart/form-data",
            },
        }).then((response) => {
            updateSub(subtitle[currentIndex], { draudio: response.data });
        })
        .catch((reason) => {
            if (typeof reason.response != 'undefined' && typeof reason.response.data != 'undefined') {
                notify({
                    message: reason.response.data.err ? reason.response.data.err : reason.response.data.message,
                    level: 'error',
                });
            } else {
                notify({
                    message: t('ERROR_MESSAGE_UNKNOWN_REASON', { reason: reason }),
                    level: 'error',
                });
            }
        })
    }

    return (
        <Style className="subtitles">
            <Table ref={tableRef}
                headerHeight={20}
                width={width}
                height={height}
                rowHeight={200}
                scrollToIndex={currentIndex}
                rowCount={subtitle.length}
                rowGetter={({ index }) => subtitle[index]}
                headerRowRenderer={() => null}
                rowRenderer={(props) => {
                    return (
                        <div
                            key={props.key}
                            className={props.className}
                            style={props.style}
                            onRowsRendered={() => null}
                            onClick={(event) => onRowClick(event, props.rowData)}
                        >
                            <div className="item">
                                <div className="flex flex-row justify-start">
                                    <span className='op' title={t('Add_Subtitle')}>
                                        <MdAdd id="add-sub" size={20} onClick={() => addSub(props.index + 1, null, false)} />
                                    </span>
                                    <span className='op' title={t('Delete_Subtitle')}>
                                        <MdRemove id="remove-sub" size={20} onClick={() => removeSub(props.rowData, false)} />
                                    </span>
                                    <span className='op' title={t('Change_Image')}>
                                        <label htmlFor="choose-file"><MdOutlineChangeCircle size={20} /></label>
                                        <input type="file" id="choose-file" style={{display: "none"}} accept="image/*" onChange={event => changeImage(event.target.files[0])} />
                                    </span>
                                    {props.index > 0 && (
                                        <span className='op' title={t('Move_Subtitle_Up')}>
                                            <MdOutlineNorth id="move-up" size={20} onClick={() => moveSub(props.index, true)} />
                                        </span>
                                    )}
                                    {props.index < subtitle.length - 1 && (
                                        <span className='op' title={t('Move_Subtitle_Down')}>
                                            <MdOutlineSouth id="move-down" size={20} onClick={() => moveSub(props.index, false)} />
                                        </span>
                                    )}
                                    <span className='op' title={t('Toggle_Input_Type')}>
                                        <button onClick={() => updateSub(props.rowData, {useAudio: props.rowData.useAudio ? false : true}, false)}>
                                            { props.rowData.useAudio ? t('Audio') : t('Text') }
                                        </button>
                                    </span>
                                    {/*<MdOutlinePerson size={20} style={{ marginRight: "5px" }}/>*/}
                                    <Select popupMatchSelectWidth={false} showSearch value={String(props.rowData.presenter)} 
                                        onChange={value => {updateSub(props.rowData, { 
                                            presenter: value,
                                            charPerSecond: getCharPerSecond(value),
                                        }, false)
                                    }}>
                                        {myPresenters.map((item) => (
                                            <Select.Option key={item.key} value={String(item.key)}>{item.key + ', ' + item.name + (item.voice ?  ', ' + item.voice : '')}</Select.Option>
                                        ))}
                                    </Select>
                                </div>
                                {props.rowData.useAudio ? (
                                    <div className={[
                                            'textarea',
                                            currentIndex === props.index ? 'highlight' : '',
                                            !props.rowData.draudio ? 'illegal' : '',
                                        ].join(' ').trim()}
                                    >
                                        <div className="row my-1">
                                            <div className="col-md-3">
                                                { t('Audio_Type') }
                                            </div>
                                            <div className="col-md-9">
                                                <Select popupMatchSelectWidth={false} value={props.rowData.audioType} onChange={value => { updateSub(props.rowData, { audioType: value }) }}>
                                                    {AUDIO_OPTIONS.map((item) => (
                                                        <Select.Option key={item.value} value={item.value}>{ t('AUDIO_OPTIONS_' + item.value) }</Select.Option>
                                                    ))}
                                                </Select>
                                            </div>
                                        </div>
                                        <div className="row my-1">
                                            <div className="col-md-3">
                                                { t('Audio_File', {maxSize: MAX_AUDIO_FILE_SIZE/1048576}) }
                                            </div>
                                            <div className="col-md-9">
                                                <label className="btn btn-light">
                                                    <input type="file" accept="audio/*" onChange={event => selectAudioFile(event.target.files[0])} />
                                                </label>
                                                {props.rowData.draudio && carouselData && carouselData.dir && (
                                                    <div>
                                                        <audio controls crossOrigin="anonymous" 
                                                            src={`${apiBaseUrl}/files/${carouselData.dir}/${props.rowData.draudio.name}?${Date.now()}`}
                                                    />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <TextareaAutosize
                                        maxLength={MAX_TEXT_SIZE2}
                                        minRows={5}
                                        maxRows={5}
                                        spellCheck={false}
                                        className={[
                                            'textarea',
                                            currentIndex === props.index ? 'highlight' : '',
                                        ].join(' ').trim()}
                                        value={unescape(props.rowData.text)}
                                        onChange = {(event) => {
                                            updateSub(props.rowData, {
                                                text: event.target.value,
                                                charPerSecond: getCharPerSecond(props.rowData.presenter),
                                            });
                                        }}
                                    />
                                )}
                            </div>
                        </div>
                    );
                }}
            ></Table>
        </Style>
    );
}
