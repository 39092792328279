import React, { useEffect } from 'react';
import { t } from 'react-i18nify';
import VpsHeader from './VpsHeader';
import { Card } from 'antd';
import { LazyLoadComponent } from 'react-lazy-load-image-component';

const { Meta } = Card;

const appsData = [
    {
        title: "OfflineTextRecognizer",
        description: "OfflineTextRecognizer_description",
        imageSrc: "/assets/mobileApps/OfflineTextRecognizer.webp",
        link: "https://play.google.com/store/apps/details?id=studio.videoplus.text_recognizer",
    },
    {
        title: "RussianBlock",
        description: "RussianBlock_description",
        imageSrc: "/assets/mobileApps/RussianBlock.webp",
        link: "https://play.google.com/store/apps/details?id=studio.videoplus.russian_block",
    },
    {
        title: "aiTransformerBackgroundEditor",
        description: "aiTransformerBackgroundEditor_description",
        imageSrc: "/assets/mobileApps/aiTransformerBackgroundEditor.webp",
        link: "https://play.google.com/store/apps/details?id=studio.videoplus.background_editor",
    },
    {
        title: "aiTransformerCartoonizer",
        description: "aiTransformerCartoonizer_description",
        imageSrc: "/assets/mobileApps/aiTransformerCartoonizer.webp",
        link: "https://play.google.com/store/apps/details?id=studio.videoplus.cartoonizer",
    },
    {
        title: "aiTransformerEnhancer",
        description: "aiTransformerEnhancer_description",
        imageSrc: "/assets/mobileApps/aiTransformerEnhancer.webp",
        link: "https://play.google.com/store/apps/details?id=studio.videoplus.enhancer",
    },
    {
        title: "aiTransformerFilter",
        description: "aiTransformerFilter_description",
        imageSrc: "/assets/mobileApps/aiTransformerFilter.webp",
        link: "https://play.google.com/store/apps/details?id=studio.videoplus.filter",
    },
    {
        title: "aiTransformerMultiStylizer",
        description: "aiTransformerMultiStylizer_description",
        imageSrc: "/assets/mobileApps/aiTransformerMulti-Stylizer.webp",
        link: "https://play.google.com/store/apps/details?id=studio.videoplus.multi_stylizer",
    },
    {
        title: "aiTransformerSketcher",
        description: "aiTransformerSketcher_description",
        imageSrc: "/assets/mobileApps/aiTransformerSketcher.webp",
        link: "https://play.google.com/store/apps/details?id=studio.videoplus.sketcher",
    },
];
  
const SingleApp = ({ app }) => {
    const { title, description, imageSrc, link } = app;
    return (
        <Card
            hoverable
            style={{ width: 430 }}
            className="m-4"
            cover={<img alt={title} src={imageSrc} />}
            onClick={link ? () => window.open(link, '_blank') : null}
        >
        <Meta title={t(title)} description={t(description)} />
      </Card>
    );
} 

export default function MobileApps(props) {
    const { setLastScreen } = props;
    useEffect(() => {  
        setLastScreen('Mobile_Apps');
    }, [setLastScreen]);

    return (
        <VpsHeader {...props}>
            <div>
                <div className="flex mx-2 mb-2">
                    { t("Mobile_Apps_info") }
                </div>
                
                <div className="mx-auto mb-4 flex flex-wrap items-center justify-center">
                    {appsData.map((app, i) => (
                        <SingleApp key={i} app={app} />
                    ))}
                </div>

                <div className="flex mx-2 mb-2">
                    { t("music_video_info") }
                </div>
                
                <div className="mx-auto mb-4 flex flex-wrap items-center justify-center">
                    <div className="overflow-hidden rounded-lg">
                        <div className="embed-responsive embed-responsive-16by9"> 
                            <LazyLoadComponent>
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/ymBzKsuC-9I" title={ t('Videooplus_Studio_demo_video') } allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>             
                            </LazyLoadComponent>
                        </div>
                    </div>
                </div>
            </div>
        </VpsHeader>
    );
}

