import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDashboardContext } from "./Provider.tsx";
import ThemeSwitch from '../components/ThemeSwitch.tsx';
import { names, getName } from '../i18n';
import { t } from 'react-i18nify';
import { Tooltip, Popconfirm, Select } from 'antd';
import { apiBaseUrl, openVideo, openDocument, openSubtitle, saveImagesAsGif } from '../utils';
import { MdOutlineVideoCameraBack, MdOutlineImageSearch, MdOutlineSubtitles, MdOutlineTask, MdOutlineSaveAs, MdOutlineSave,
   MdOutlineGTranslate, MdOutlineUndo, MdOutlineClearAll } from "react-icons/md";
import Dialog from '../components/Dialog';
import ExportSub from '../components/ExportSub';
import Translator from '../components/Translator';

export function TopBar(props) {
  const { openSidebar } = useDashboardContext();
  const navigate = useNavigate();

  return (
    <header className="relative z-10 h-24 w-full items-center bg-white shadow md:h-20">
      <div className="relative mx-auto flex h-full flex-col justify-center px-3">
        <div className="relative flex w-full items-center pl-1 sm:ml-0 sm:pr-1">
          <div className="relative left-0 flex w-3/4">
            <div className="group relative flex h-full w-12 items-center">
              <button
                type="button"
                aria-expanded="false"
                aria-label="Toggle sidenav"
                onClick={openSidebar}
                className="text-4xl text-black focus:outline-none"
              >
                &#8801;
              </button>
            </div>
          </div>

          <div className="relative flex flex-col md:flex-row">
            <div className="relative -ml-3 flex w-full items-center justify-start">
              {props.authenticated ? (
                <div className="flex flex-row items-center">
                  <em style={{ width: "150px" }}>{t('Welcome_back', {user: props.user.displayName.length < 15 ? props.user.displayName : props.user.displayName.slice(0, 15) + '...'})}</em>
                  <button
                    className="btn m-3 btn-info"
                    style={{ whiteSpace: "nowrap" }}
                    onClick={() => window.open(`${apiBaseUrl}/auth/logout`, "_self")}
                  >
                    {t('SIGN_OUT')}
                  </button>
                </div>
              ) : (
                  <button
                    className="btn m-3 btn-info"
                    style={{ whiteSpace: "nowrap" }}
                    onClick={() => navigate('/Signin')}
                  >
                    {t('SIGN_IN')}
                  </button>
              )}

              <ThemeSwitch />
              <Select popupMatchSelectWidth={false} value={getName(props.language)} onChange={value => props.updateLang(value, false)}>
                {Object.keys(names).map(key => (
                  <Select.Option key={key} value={key}>{names[key]}</Select.Option>
                ))}
              </Select>
            </div>
            
            <div className="relative -ml-16 sm:ml-0 md:ml-4 flex w-full items-center justify-end p-1 sm:right-auto sm:mr-0 -mt-5 md:mt-0">
              {props.lastScreen === 'Video_STS' && (
                <Tooltip title={t('OPEN_VIDEO')}>
                  <span className="block pt-2 pr-1 lg:pr-5">
                    <label htmlFor="openVideo1"><MdOutlineVideoCameraBack size={25} /></label>
                    <input type="file" id="openVideo1" style={{display: "none"}} 
                      accept="video/mp4,video/x-m4v,video/*" 
                      onChange={event => {if (event.target.files) openVideo(event.target.files[0], props)}} />
                  </span>
                </Tooltip>
              )}
              {props.lastScreen === 'Image_STS' && (
                <Tooltip title={t('OPEN_DOCUMENT')}>
                  <span className="block pt-2 pr-1 lg:pr-5">
                    <label htmlFor="openDocument1"><MdOutlineImageSearch size={25} /></label>
                    <input type="file" id="openDocument1" style={{display: "none"}} 
                      accept="application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, 
                      application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, 
                      application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, 
                      application/vnd.oasis.opendocument.presentation, application/vnd.oasis.opendocument.spreadsheet, application/vnd.oasis.opendocument.text, 
                      application/pdf, application/rtf, application/xml, text/csv, text/html, text/plain, image/*" 
                      onChange={event => {if (event.target.files) openDocument(event.target.files[0], props)}} />
                  </span>
                </Tooltip>
              )}
              {['Video_STS', 'Image_STS'].includes(props.lastScreen) && (
                <Tooltip title={t('IMPORT_SUB')}>
                  <span className="block pt-2 pr-1 lg:pr-5">
                    <label htmlFor="openSubtitle1"><MdOutlineSubtitles size={25} /></label>
                    <input type="file" id="openSubtitle1" style={{display: "none"}} 
                      accept=".ass, .vtt, .srt, .json" 
                      onChange={event => {if (event.target.files) openSubtitle(event.target.files[0], props)}} />
                  </span>
                </Tooltip>
              )}
              {['Video_STS', 'Image_STS'].includes(props.lastScreen) && (
                <Tooltip title={t('TRANSLATE_hint')}>
                  <span className="block pt-2 pr-1 lg:pr-5">
                    <MdOutlineGTranslate size={25} onClick={() => props.setOption({ translatorDialog: true })} />
                  </span>
                </Tooltip>
              )}
              {props.lastScreen === 'Image_STS' && (
                <Tooltip title={t('Save_Gif_hint')}>
                  <span className="block pt-2 pr-1 lg:pr-5">
                    <MdOutlineSaveAs size={25} onClick={() => saveImagesAsGif(props)} />
                  </span>
                </Tooltip>
              )}
              {['Video_STS', 'Image_STS'].includes(props.lastScreen) && (
                <Tooltip title={t('EXPORT_hint')}>
                  <span className="block pt-2 pr-1 lg:pr-5">
                    <MdOutlineSave size={25} onClick={() => props.setOption({ exportSubDialog: true })} />
                  </span>
                </Tooltip>
              )}
              {['Video_STS', 'Image_STS'].includes(props.lastScreen) && (
                <Tooltip title={t('UNDO_hint')}>
                  <span className="block pt-2 pr-1 lg:pr-5">
                    <MdOutlineUndo size={25} onClick={props.undoSubs} />
                  </span>
                </Tooltip>
              )}
              {['Video_STS', 'Image_STS'].includes(props.lastScreen) && (
                <Tooltip title={t('CLEAR_hint')}>
                  <span className="block pt-2 pr-1 lg:pr-5">
                    <Popconfirm
                      title={t('CLEAR_hint')}
                      description={t('CLEAR_confirm')}
                      onConfirm={() => {
                        props.clearSubs(props.lastScreen === 'Video_STS');
                        props.setOption({ subtitleUrl: undefined });
                        props.setSubtitle([
                          props.newSub({
                              start: '00:00:00.000',
                              end: '00:00:01.000',
                              text: t('SUB_TEXT'),
                              presenter: '1'
                          }),
                        ]);
                        //window.location.reload();
                      }}
                      okText={t('Yes')}
                      cancelText={t('No')}
                    >
                      <MdOutlineClearAll size={25} />
                    </Popconfirm>
                  </span>
                </Tooltip>
              )}
              {props.lastScreen === 'Video_STS' && (
                <Tooltip title={t('Submit_Task')}>
                  <span className="block pt-2 pr-1 lg:pr-5">
                    <MdOutlineTask size={25} onClick={() => navigate('/SubmitVideoSTSTask')} />
                  </span>
                </Tooltip>
              )}
              {props.lastScreen === 'Image_STS' && (
                <Tooltip title={t('Submit_Task')}>
                  <span className="block pt-2 pr-1 lg:pr-5">
                      <MdOutlineTask size={25} onClick={() => navigate('/SubmitImageSTSTask')} />
                    </span>
                </Tooltip>
              )}
              {/*props.lastScreen === 'Presenter' && (
                <span className="block pt-2 pr-1 lg:pr-5" title={t('New_Presenter')}>
                  <MdOutlineCreateNewFolder size={25} onClick={() => navigate('/NewPresenter')} />
                </span>
              )*/}
            </div>

          </div>
        </div>
      </div>

      {props.options.exportSubDialog && (
        <Dialog title={t('EXPORT')} width={400} onClose={() => props.setOption({ exportSubDialog: false })}>
          <ExportSub {...props} />
        </Dialog>
      )}
      {props.options.translatorDialog && (
        <Dialog title={t('TRANSLATE')} width={400} onClose={() => props.setOption({ translatorDialog: false })}>
          <Translator {...props} />
        </Dialog>
      )}
    </header>
  );
}
