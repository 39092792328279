import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';
import { t } from 'react-i18nify';
import Faq from "react-faq-component";
import { MAX_TEXT_SIZE1, MAX_TEXT_SIZE2 } from '../utils';
import VpsHeader from './VpsHeader';

export default function Help(props) {
    const { setLastScreen, ttsLanguages, ttsVoices } = props;
    useEffect(() => {  
        setLastScreen('HELP');
    }, [setLastScreen]);
    const [theme] = useLocalStorage('theme', 'light');
    const [isDarkTheme, setIsDarkTheme] = useState(false);
    useEffect(() => {  
        setIsDarkTheme(theme === 'dark');
    }, [theme]);
    const navigate = useNavigate();

    const [countLanguages, setCountLanguages] = useState(80);
    const [countVoices, setCountVoices] = useState(458);
    useEffect(() => { 
        if (ttsLanguages.length > 0) {
            setCountLanguages(ttsLanguages.length);
        }
        if (ttsVoices.current.length > 0) {
            setCountVoices(ttsVoices.current.length);
        }
    }, [ttsLanguages, ttsVoices]);
  
    const genericTopics = {
        title: t("Generic_Topics"),
        rows: [
            { title: t("FAQ1_question"), content: t("FAQ1_answer") },
            { title: t("FAQ2_question"), content: t("FAQ2_answer") },
            { title: t("FAQ3_question"), content: t("FAQ3_answer") },
            { title: t("FAQ4_question"), content: t("FAQ4_answer", { 'count_languages': countLanguages, 'count_voices': countVoices }) },
            { title: t("FAQ5_question"), content: t("FAQ5_answer", { 'max_text_size1': MAX_TEXT_SIZE1, 'max_text_size2': MAX_TEXT_SIZE2 }) },
            { title: t("FAQ6_question"), content: t("FAQ6_answer") },
        ],
    };
    
    const tutorials = {
        title: t("Tutorials"),
        rows: [
            { title: t("tutorial1_title"), content: t("tutorial1_summary") },
            { title: t("tutorial2_title"), content: t("tutorial2_summary") },
            { title: t("tutorial3_title"), content: t("tutorial3_summary", { 'post_url': 'https://aitransformer.blogspot.com/2024/07/how-to-make-dynamic-explainer-videos.html' }) },
            { title: t("tutorial4_title"), content: t("tutorial4_summary", { 'post_url': 'https://aitransformer.blogspot.com/2024/07/how-to-turn-documents-into-dynamic.html' }) },
            { title: t("tutorial5_title"), content: t("tutorial5_summary", { 'post_url': 'https://aitransformer.blogspot.com/2024/07/how-to-dub-movie-all-by-yourself-using.html' }) },
            { title: t("tutorial6_title"), content: t("tutorial6_summary", { 'post_url': 'https://aitransformer.blogspot.com/2024/07/how-to-combine-pictures-and-words-into.html' }) },
            { title: t("tutorial7_title"), content: t("tutorial7_summary", { 'post_url': 'https://aitransformer.blogspot.com/2024/03/crafting-personalized-stories-with-ai.html' }) },
        ],
    };
    
    const styles = {
        bgColor: isDarkTheme ? 'deepblue' : 'white',
        titleTextColor: isDarkTheme ? 'lightgrey' : 'darkblue',
        titleTextSize: '20px',
        rowTitleColor: isDarkTheme ? 'white' : 'black',
        //rowTitleTextSize: 'medium',
        rowContentColor: isDarkTheme ? 'orange' : 'brown',
        rowContentTextSize: '16px',
        rowContentPaddingTop: '5px',
        rowContentPaddingBottom: '25px',
        rowContentPaddingLeft: '20px',
        rowContentPaddingRight: '20px',
        arrowColor: isDarkTheme ? 'white' : 'black',
        //transitionDuration: "1s",
        // timingFunc: "ease"
    };
    
    const config = {
        // animate: true,
        // arrowIcon: "V",
        // tabFocus: true
    };
    
    //const showHowtoVideo = () => {
    //    setOption({ helpDialog: false, showHowtoVideoDialog: true })
    //}
    
    return (
        <VpsHeader {...props}>
            {/*
            <div className="row m-2">
                <div className="col-12">
                    <button className='btn btn-info' onClick={() => showHowtoVideo()}>{t('Howto_Videos')}</button>
                </div>
            </div>*/}
            <div className="flex mx-2 mb-2">
                <Faq
                    data={genericTopics}
                    styles={styles}
                    config={config}
                />
            </div>
            <div className="flex mx-2 mb-2">
                <Faq
                    data={tutorials}
                    styles={styles}
                    config={config}
                />
            </div>
            <div className="row m-2">
                <div className="col-sm-3">
                    { t("Questions_suggestions") }
                </div>
                <div className="col-sm-3">
                    <button className="btn btn-info" onClick={() => navigate('/Contact')}>{t('Contact_Support')}</button>
                </div>
            </div>
        </VpsHeader>
    );
}
