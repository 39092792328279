import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { MdClose } from "react-icons/md";

const Style = styled.div`
    position: fixed;
    z-index: 99;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.8);

    .dialog-inner {
        min-height: 200px;
        max-height: 100vh;
        background-color: #2F4F4F;  /* #483D8B; */
        border-radius: 3px;
        overflow: auto;
        opacity: 0;
        margin-top: -10px;
        transition: all 0.2s ease 0s;
        box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.5);

        .dialog-title {
            position: relative;
            border-bottom: 1px solid #000;
            background-color: #556B2F;
            text-shadow: 0 1px 0 rgba(0, 0, 0, 0.5);
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px 0;
            margin-bottom: 10px;
            font-size: 16px;
        }

        .dialog-cancel {
            position: absolute;
            right: 10px;
            top: 10px;
            cursor: pointer;
            opacity: 0.5;
            transition: all 0.2s ease 0s;

            &:hover {
                opacity: 1;
            }
        }

        .dialog-content {
            margin: 5px;
            font-size: 15px;
        }

        &.dialog-active {
            opacity: 1;
            margin-top: 0;
        }
    }
`;

export default function Dialog(props) {
    const [activeName, setActiveName] = useState('');

    useEffect(() => {
        setTimeout(() => setActiveName('dialog-active'), 100);
    }, []);

    const keyDownHandler = (event) => {
        if (event.key === 'Escape') {
            props.onClose();
        }
    } 
    
    useEffect(() => {
        document.addEventListener("keydown", keyDownHandler);
        return () => document.removeEventListener("keydown", keyDownHandler);
    }, [keyDownHandler]);
    
    return (
        <Style tabIndex="-1">
            <div
                style={{ width: props.width || "90%" }}
                className={`dialog-inner ${activeName}`}
                onClick={event => event.stopPropagation()}
            >
                <div className="dialog-title">
                    {props.title || 'Title'}{' '}
                    <i className="dialog-cancel" onClick={() => props.onClose()}><MdClose size={20}/></i>
                </div>
                <div className="dialog-content">{props.children}</div>
            </div>
        </Style>
    );
}
