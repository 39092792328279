import React, { useState, useEffect } from 'react';
import NProgress from 'nprogress';
import { t } from 'react-i18nify';
import { apiBaseUrl, SUBSCRIPTION_PLANS } from '../utils';
import VpsHeader from './VpsHeader';

export default function Account(props) {
    const { setLastScreen, setOption, authenticated, sid, language, user, notify } = props;
    useEffect(() => {  
        setLastScreen('ACCOUNT');
    }, [setLastScreen]);

    const [credit4Presenter, setCredit4Presenter] = useState(undefined);
    const [credit4Transcription, setCredit4Transcription] = useState(undefined);
    const [credit4Task, setCredit4Task] = useState(undefined);
    const [timeToExpire, setTimeToExpire] = useState(undefined);
    const [extraCredit4Presenter, setExtraCredit4Presenter] = useState(undefined);
    const [extraCredit4Task, setExtraCredit4Task] = useState(undefined);
    const [sending, setSending] = useState(false);

    useEffect(() => {
        if (sid) {
            NProgress.start().set(0.5);
            fetch(apiBaseUrl + "/userCredits", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ 
                    sid: sid, 
                    locale: language.includes('fr') ? 'fr-FR' : language.includes('es') ? 'es-ES' : 'en-US', 
                }),
            })
            .then(async response => {
                if (!response.ok) {
                    const result = await response.json();
                    notify({
                        message: result.err,
                        level: 'error',
                    });
                } else {
                    const data = await response.json();
                    setCredit4Presenter(data.credit4Presenter);
                    setCredit4Transcription(data.credit4Transcription);
                    setCredit4Task(data.credit4Task);
                    setTimeToExpire(data.timeToExpire);
                    setExtraCredit4Presenter(data.extraCredit4Presenter);
                    setExtraCredit4Task(data.extraCredit4Task);
                }
                NProgress.done();
            })
            .catch(err => {
                console.log(err);
                notify({
                    message: t('GENERIC_ERROR_MESSAGE'),  //err.message,
                    level: 'error',
                });
                NProgress.done();
            })
        }
    }, [sid, language]);
    
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        setSending(true);
        const { name, coupon } = e.target.elements;
        const details = {
            displayName: name.value,
            coupon: coupon?.value,
            sid: sid,
            locale: language.includes('fr') ? 'fr-FR' : language.includes('es') ? 'es-ES' : 'en-US', 
        };
        fetch(apiBaseUrl + "/updateUserAccountInfo", {
            method: "POST",
            headers: {
                'Accept': 'application/json, text/plain, */*',
                "Content-Type": "application/json;charset=utf-8",
            },
            body: JSON.stringify(details),
        })
        .then(async response => {
            if (!response.ok) {
                const result = await response.json();
                notify({
                    message: result.err,
                    level: 'error',
                });
            } else {
                const data = await response.json();
                notify({
                    message: data.message,
                    level: 'success',
                });
                //setOption({ accountDialog: false, reloadUserAccount: true });
                window.location.reload();
            }
            setSending(false);
        })
        .catch(err => {
            console.log(err);
            notify({
                message: t('GENERIC_ERROR_MESSAGE'),  //err.message,
                level: 'error',
            });
            setSending(false);
        })
    };
    
    return (
        <VpsHeader {...props}>
            {!authenticated ? (
                <div className="flex flex-col max-w-[480px] mx-auto">
                    <div className="title">
                        { t("Sign_in_to_edit_account") }
                    </div>
                </div>
            ) : (
                <div className="flex flex-col max-w-[680px] mx-auto">
                    <form className="form-horizontal card p-3" onSubmit={handleSubmit}>
                        <div className="form-group row m-2">
                            <label className="control-label col-sm-3" htmlFor="name">{ t('Origin') }</label>
                            <div className="col-sm-9">
                                <input className="form-control" type="text" id="provider" defaultValue={user.provider} disabled />
                            </div>
                        </div>
                        <div className="form-group row m-2">
                            <label className="control-label col-sm-3" htmlFor="name">{ t('Email') }</label>
                            <div className="col-sm-9">
                                <input className="form-control" type="text" id="email" defaultValue={user.email} disabled />
                            </div>
                        </div>
                        <div className="form-group row m-2">
                            <label className="control-label col-sm-3" htmlFor="name">{ t('Display_Name') }</label>
                            <div className="col-sm-9">
                                <input className="form-control" type="text" id="name" defaultValue={user.displayName} required />
                            </div>
                        </div>
                        <div className="form-group row m-2">
                            <label className="control-label col-sm-3" htmlFor="name">{ t('Coupon') }</label>
                            <div className="col-sm-9">
                                <input className="form-control" type="text" id="coupon" />
                            </div>
                        </div>
                        <div className="form-group row m-3">
                            <div className="col-sm-12 text-center">
                                <button className="btn mb-3 mr-3 btn-info" type="submit" disabled={sending}>
                                    { sending ? t('sending') : t('Update') }
                                </button>
                            </div>
                        </div>

                        {user.subscriptionPlanId && (
                            <div className="form-group row m-2 card">
                                <div className="card-body">{ t('Subscription_Plan') }: &nbsp;&nbsp;
                                    { SUBSCRIPTION_PLANS.find(x => x.id === user.subscriptionPlanId)?.title + (user.nextSubscriptionPlanId && user.nextSubscriptionPlanId !== user.subscriptionPlanId ? 
                                    ' ('+ t('current') + '), ' + SUBSCRIPTION_PLANS.find(x => x.id === user.nextSubscriptionPlanId)?.title + ' (' + t('next') + ')' : '')
                                    }
                                </div>
                            </div>
                        )}

                        <div className="form-group row m-2 card">
                            <div className="card-body">{ t('Available_Credits', { num: timeToExpire, unit: user.serviceType > '0' ? t('days') : t('hours') }) }</div>
                                <ul>
                                    <li>{ t('Presenter') }: {credit4Presenter}</li>
                                    <li>{ t('Transcription') }: {credit4Transcription} {t('minutes')}</li>
                                    <li>{ t('Other_Tasks') }: {credit4Task} {t('minutes')}</li>
                                </ul>
                        </div>

                        {(extraCredit4Presenter > 0 || extraCredit4Task > 0) && (
                            <div className="form-group row m-2 card">
                                <div className="card-body">{ t('Extra_Credits') }</div>
                                <ul>
                                    {extraCredit4Presenter > 0 && (
                                        <li>{ t('Presenter') }: {extraCredit4Presenter}</li>
                                    )}
                                    {extraCredit4Task > 0 && (
                                        <li>{ t('Task') }: {extraCredit4Task} {t('minutes')}</li>
                                    )}
                                </ul>
                            </div>
                        )}
                    </form>
                </div>
            )}
        </VpsHeader>
    );
}
