import React, { useState, useEffect, useRef } from 'react';
import { t } from 'react-i18nify';
import HashMap from 'hashmap';
import{ Select, Card, Col, Row } from 'antd';
import { compareLanguages, compareVoices, GENDER_OPTIONS } from '../utils';
import VpsHeader from './VpsHeader';

export default function VoiceSamples(props) {
    const { setLastScreen, language, ttsLanguages, ttsVoices } = props;
    useEffect(() => {  
        setLastScreen('Voice_Samples');
    }, [setLastScreen]);

    const [selectedGender, setSelectedGender] = useState("male");
    const [selectedLanguage, setSelectedLanguage] = useState("ara");
  
    const languageMap = useRef();
    const languageOptions = useRef();
    const [voiceOptions, setVoiceOptions] = useState([]);
    
    useEffect(() => { 
        if (ttsLanguages.length > 0 && !languageMap.current) {
            languageMap.current = new HashMap();
            languageOptions.current = [];
            languageOptions.current.push({ value: "", text: "GENDER_OPTIONS_" });
            ttsLanguages.sort(compareLanguages).forEach(x => {
                languageMap.current.set(x.code, { "name": x.name, "test_text": x.test_text });
                languageOptions.current.push({ value: x.code, text: x.name });
            })
        }
    
        if (ttsVoices.current.length > 0 && languageMap.current && language) {
            const voiceOptions = [];
            ttsVoices.current.sort(compareVoices).forEach(v => {
            const voiceLanguage = v.voice_id.substring(0, 3);
            if ((!selectedGender || selectedGender === v.sex) && (!selectedLanguage || selectedLanguage === voiceLanguage)) {
                let detail = v.voice_id;
                detail += ' (' + t(languageMap.current.get(voiceLanguage)?.name);
                if (v.sex)
                    detail +=  ', ' + t(v.sex);
                if (v.clonable) 
                    detail +=  ', ' + t(`clonable_${v.clonable}`);
                detail +=  ')';
                voiceOptions.push({ value: v.voice_id, text: detail });
            }
            });
            setVoiceOptions(voiceOptions);
        }
    }, [ttsLanguages, ttsVoices, selectedGender, selectedLanguage, language]);
  
    return (
        <VpsHeader {...props}>
            <div>
                <div className="row mx-2 my-3">
                    <div className="col-md-3">
                        { t('Gender') }
                    </div>
                    <div className="col-md-9">
                        <Select popupMatchSelectWidth={false} value={selectedGender} onChange={(value) => setSelectedGender(value)}>
                            {GENDER_OPTIONS.map(item => 
                                (<Select.Option key={item.value} value={item.value}>{ t('GENDER_OPTIONS_' + item.value) }</Select.Option>)
                            )}
                        </Select>
                    </div>
                </div>
                
                <div className="row mx-2 my-3">
                    <div className="col-md-3">
                        { t('Language') }
                    </div>
                    <div className="col-md-9">
                        <Select popupMatchSelectWidth={false} value={selectedLanguage} onChange={(value) => setSelectedLanguage(value)}>
                            {languageOptions.current?.map(item => 
                                (<Select.Option key={item.value} value={item.value}>{ t(item.text) }</Select.Option>)
                            )}
                        </Select>
                    </div>
                </div>
                
                {voiceOptions.length > 0 ? (
                    <div>
                        {voiceOptions.map((item, index) => {
                            return index % 3 === 0 ? voiceOptions.length > index + 2 ?
                                (<Row gutter={16}>
                                    <Col span={8}>
                                        <Card title={voiceOptions[index].text} bordered={false}>
                                            <audio controls className="card-img-top" src={'/assets/voiceSamples/aiTransformer-synthesized_' + voiceOptions[index].value + '.mp3'} />
                                        </Card>
                                    </Col> 
                                    <Col span={8}>
                                        <Card title={voiceOptions[index+1].text} bordered={false}>
                                            <audio controls className="card-img-top" src={'/assets/voiceSamples/aiTransformer-synthesized_' + voiceOptions[index+1].value + '.mp3'} />
                                        </Card>
                                    </Col>
                                    <Col span={8}>
                                        <Card title={voiceOptions[index+2].text} bordered={false}>
                                            <audio controls className="card-img-top" src={'/assets/voiceSamples/aiTransformer-synthesized_' + voiceOptions[index+2].value + '.mp3'} />
                                        </Card>
                                    </Col>
                                </Row>) 
                                : voiceOptions.length > index + 1 ? 
                                (<Row>
                                    <Col span={8}>
                                        <Card title={voiceOptions[index].text} bordered={false}>
                                            <audio controls className="card-img-top" src={'/assets/voiceSamples/aiTransformer-synthesized_' + voiceOptions[index].value + '.mp3'} />
                                        </Card>
                                    </Col> 
                                    <Col span={8}>
                                        <Card title={voiceOptions[index+1].text} bordered={false}>
                                            <audio controls className="card-img-top" src={'/assets/voiceSamples/aiTransformer-synthesized_' + voiceOptions[index+1].value + '.mp3'} />
                                        </Card>
                                    </Col>
                                </Row>) 
                                : voiceOptions.length > index ? 
                                (<Row>
                                    <Col span={8}>
                                        <Card title={voiceOptions[index].text} bordered={false}>
                                            <audio controls className="card-img-top" src={'/assets/voiceSamples/aiTransformer-synthesized_' + voiceOptions[index].value + '.mp3'} />
                                        </Card>
                                    </Col> 
                                </Row>) : ""
                            : ""
                        })}
                    </div>
                ) : (
                    <div>
                        { t('No_results') }
                    </div>
                )}

            </div>
        </VpsHeader>
    );
}
