import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { t } from 'react-i18nify';
import{ Space, Tooltip, Select, InputNumber } from 'antd';
import { MdHelpOutline } from "react-icons/md";
//import debounce from 'lodash/debounce';
import { MAX_VIDEO_FILE_SIZE, OUTPUT_LANGUAGE_OPTIONS, axiosInstance } from '../utils';
import VpsHeader from './VpsHeader';

export default function NewTranscriptionTask(props) {
    const { setResultMessage, setLastScreen, authenticated, sid, language, notify } = props;
    useEffect(() => {  
        setLastScreen('Transcription');
    }, [setLastScreen]);
    const navigate = useNavigate();
    
    const outputFormats = {'srt': 'srt', 'vtt': 'vtt', 'txt': 'txt'}; 
    const [mediaFile, setMediaFile] = useState(undefined);
    const [outputLanguage, setOutputLanguage] = useState('auto');
    const [outputFormat, setOutputFormat] = useState('srt');
    const [description, setDescription] = useState('');
    const [creating, setCreating] = useState(false);
    const [outputLength, setOutputLength] = useState(undefined);

    const selectMediaFile = async event => {
        let currentFile = event.target.files[0];
        if (currentFile.size < 100 || currentFile.size > MAX_VIDEO_FILE_SIZE) {  //a too small file is probably invalid
            setMediaFile(undefined);
            notify({
                message: t('ERROR_MESSAGE_FILE_FORMAT_WRONG_OR_SIZE_TOO_LARGE', { filename: currentFile.name }),
                level: 'warning',
            });
            return;
        }
        setMediaFile(currentFile);
        setDescription(t('To_transcribe', {filename: currentFile.name}));
    }
  
    const onChangeOfDescription = event => {
        setDescription(event.target.value);
    } 

    const saveTask = async () => {
        if (!mediaFile) {
            notify({
                message: t('ERROR_MESSAGE_MISSING_MEDIA_FILE'),
                level: 'error',
            });
            return;
        }
        /*
        if (!outputLength || outputLength < minLength || outputLength > maxLength.current) {
            notify({
                message: t('ERROR_MESSAGE_INVALID_OUTPUT_LENGTH', {'minLength': minLength, 'maxLength': maxLength.current}),
                level: 'error',
            });
            return;
        }
        */
        setCreating(true);

        let formData = new FormData();
        formData.append("sid", sid);
        formData.append("locale", language.includes('fr') ? 'fr-FR' : language.includes('es') ? 'es-ES' : 'en-US');
        formData.append("videoTranscription", 1);
        formData.append("outputLanguage", outputLanguage);
        formData.append("outputFormat", outputFormat);
        if (!outputLength || outputLength < 1) {
            formData.append("outputLength", document.getElementById('mymedia').duration);
        } else {
            formData.append("outputLength", outputLength);
        }
        formData.append("description", description);
        formData.append("file", mediaFile);

        axiosInstance.post("/saveTask", formData, {  
            headers: {
                "Content-Type": "multipart/form-data",
            },
        }).then((response) => {
            //notify({
            //    message: response.data.message,
            //    level: 'success',
            //});
            setCreating(false);
            //setOption({ newTranscriptionTaskDialog: false, taskDialog: true });
            setResultMessage(response.data.message);
            navigate('/TaskResultSuccess');
        })
        .catch((reason) => {
            if (typeof reason.response != 'undefined' && typeof reason.response.data != 'undefined') {
                notify({
                    message: reason.response.data.err ? reason.response.data.err : reason.response.data.message,
                    level: 'error',
                });
            } else {
                notify({
                    message: t('ERROR_MESSAGE_UNKNOWN_REASON', { reason: reason }),
                    level: 'error',
                });
            }
            setCreating(false);
        })
    }
    //const saveTask2 = debounce(saveTask, 3000);
    //const saveTask3 = useCallback(saveTask2, []);   //to prevent duplicate submits
    
    return (
        <VpsHeader {...props}>
            <div>
                <div className="flex mx-2 mb-2">
                    { t("Save_transcription_task_info") }&nbsp;{ t('Save_task_info_extra') }
                </div>
                {/*<div className="row m-2">
                    { t("Save_transcription_task_instruction") }
                </div>*/}
                
                <div className="row mx-2 my-3">
                    <div className="col-md-3">
                        { t('Select_Media_File', {maxSize: MAX_VIDEO_FILE_SIZE/1048576}) }
                    </div>
                    <div className="col-md-9">
                        <label className="btn mb-3 mr-3 btn-light">
                            <input type="file" accept="audio/*,video/*" onChange={selectMediaFile} />
                        </label>
                        {mediaFile && mediaFile.type.startsWith('audio') && (
                            <div>
                                <audio id="mymedia" controls src={URL.createObjectURL(mediaFile)} />
                            </div>
                        )}
                        {mediaFile && mediaFile.type.startsWith('video') && (
                            <div>
                                <video id="mymedia" width="320" height="240" controls src={URL.createObjectURL(mediaFile)} />
                            </div>
                        )}
                    </div>
                </div>

                <div className="row m-2">
                    <div className="col-md-3">
                        { t('Output_Language') }
                    </div>
                    <div className="col-md-9">
                        <Select popupMatchSelectWidth={false} value={outputLanguage} onChange={(value) => setOutputLanguage(value)}>
                            {OUTPUT_LANGUAGE_OPTIONS.map(item => (
                                <Select.Option key={item.value} value={item.value}>{ t('OUTPUT_LANGUAGE_OPTIONS_' + item.value) }</Select.Option>
                            ))}
                        </Select>
                    </div>
                </div>

                <div className="row m-2">
                    <div className="col-md-3">
                        { t('Output_Format') }
                    </div>
                    <div className="col-md-9">
                        <Select popupMatchSelectWidth={false} value={outputFormat} onChange={(value) => setOutputFormat(value)}>
                            {Object.keys(outputFormats).map(key => (
                                <Select.Option key={key} value={key}>{outputFormats[key]}</Select.Option>
                            ))}
                        </Select>
                    </div>
                </div>

                <div className="row m-2">
                    <div className="col-md-3">
                        <Space wrap>
                            { t('Duration_To_Transcribe') }
                            <Tooltip title={t('Duration_To_Transcribe_tip')} color='purple'><MdHelpOutline /></Tooltip>
                        </Space>
                    </div>
                    <div className="col-md-9">
                        <InputNumber
                            min={1.0} step={0.01}
                            value={outputLength}
                            addonAfter={t('seconds')}
                            onChange={(value) => setOutputLength(value)}
                        />
                    </div>
                </div>

                <div className="row m-2">
                    <div className="col-md-3">
                        <Space wrap>
                            { t('Description') }
                            <Tooltip title={t('Description_tip')} color='purple'><MdHelpOutline /></Tooltip>
                        </Space>
                    </div>
                    <div className="col-md-9">
                        <input type="text" className="form-control w-75" value={description} onChange={onChangeOfDescription} />
                    </div>
                </div>

                <div className="row m-3">
                    <div className="col-12 text-center">
                        <button
                            className="btn mb-3 mr-3 btn-info btn-lg"
                            onClick={() => saveTask()}
                            disabled={!authenticated || creating}
                        >
                            { creating ? t('sending') : t('Submit') }
                        </button><br/>
                        {!authenticated && (
                            t("Sign_in_to_submit_task")
                        )}
                    </div>
                </div>
            </div>
        </VpsHeader>
    );
}
