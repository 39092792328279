import React, {useState, useEffect} from 'react';
import { t } from 'react-i18nify';
import { apiBaseUrl } from '../utils';
import VpsHeader from './VpsHeader';
import VpsFooter from './VpsFooter';

export default function ActivateAccount(props) {
    const { setLastScreen, language, notify } = props;
    useEffect(() => {  
        setLastScreen('Activate_Account');
    }, [setLastScreen]);

    const [resendButtonText, setResendButtonText] = useState(undefined);
    const [userId, setUserId] = useState(undefined);
  
    useEffect(() => {
        if (!userId && language) {
            const token = window.location.pathname.split("/").pop();
            fetch(apiBaseUrl + "/auth/activateAccount", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ 
                    activateAccountToken: token, 
                    locale: language.includes('fr') ? 'fr-FR' : language.includes('es') ? 'es-ES' : 'en-US', 
                }),
            })
            .then(async response => {
                if (!response.ok) {
                    const result = await response.json();
                    notify({
                        message: result.err,
                        level: 'error',
                    });
                    if (response.status === 406) {
                        setUserId(result.id);
                        setResendButtonText(t('Resend_Account_Activation_Link'));
                    }
                } else {
                    const data = await response.json();
                    setUserId(data.id);
                    notify({
                        message: t('Account_activated_message'),   
                        level: 'info',
                    });
                }
            })
            .catch(err => {
                console.log(err);
                notify({
                    message: t('GENERIC_ERROR_MESSAGE'),   //err.message
                    level: 'error',
                });
            })
        }
    }, [userId, language, notify]);
  
    const handleResendLink = async (e) => {
        if (!userId) return;
        setResendButtonText(t('sending'));
        fetch(apiBaseUrl + "/auth/sendAccountActivationLink", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ 
                userId: userId, 
                locale: language.includes('fr') ? 'fr-FR' : language.includes('es') ? 'es-ES' : 'en-US',  
            }),
        })
        .then(async response => {
            setResendButtonText(t('Resend_Account_Activation_Link'));
            if (!response.ok) {
                const result = await response.json();
                notify({
                    message: result.err,
                    level: 'error',
                });
            } else {
                setResendButtonText(undefined);
                notify({
                    message: t('Account_activation_link_sent_message'),
                    level: 'info',
                });
            }
        })
        .catch(err => {
            setResendButtonText(t('Resend_Account_Activation_Link'));
            console.log(err);
            notify({
                message: t('GENERIC_ERROR_MESSAGE'),   //err.message
                level: 'error',
            });
        })
    };
    
    return (
        <VpsHeader {...props}>
            <section className="flex flex-col max-w-[480px] mx-auto">
                {resendButtonText ? (
                    <div className="form-group row m-3">
                        <button className="btn mb-3 mr-3 btn-info" onClick={handleResendLink}>{resendButtonText}</button>
                    </div>
                ) : (
                    <div className="form-group row m-3">
                        <form><p/>
                            <button className="btn mb-3 mr-3 btn-info" formaction="/Signin">{ t('Go_sign_in') }</button>
                        </form>
                    </div>
                )}
            </section>
            <VpsFooter {...props} />
        </VpsHeader>
    );
}
