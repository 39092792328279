import React, { useEffect, useState, useRef, useMemo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import debounce from 'lodash/debounce';
import { t } from 'react-i18nify';
import { SearchOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';
import { apiBaseUrl, getFileFromBuffer } from '../utils';
import Table from './Table';
import VpsHeader from './VpsHeader';

export default function Presenter(props) {
    const { setLastScreen, options, setOption, authenticated, sid, language, notify, ttsLanguages } = props;
    useEffect(() => {  
        setLastScreen('PRESENTER');
    }, [setLastScreen]);
    const navigate = useNavigate();

    const [pageCount, setPageCount] = useState(0);
    const [data, setData] = useState([]);
    const fetchIdRef = useRef(0);
    const [loading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");

    const fetchAPIData = async ({ limit, skip, search }) => {
        if (!sid)
            return;
        setLoading(true);
        fetch(apiBaseUrl + "/userPresenters", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ 
                sid: sid, 
                locale: language.includes('fr') ? 'fr-FR' : language.includes('es') ? 'es-ES' : 'en-US', 
                limit: limit,
                skip: skip,
                search: search,
            }),
        })
        .then(async response => {
            if (!response.ok) {
                const result = await response.json();
                notify({
                    message: result.err,
                    level: 'error',
                });
            } else {
                const data = await response.json();
                setData(data.data);
                setPageCount(data.paging.pages);
            }
            setLoading(false);
        })
        .catch(err => {
            console.log(err);
            notify({
                message: t('GENERIC_ERROR_MESSAGE'),  //err.message,
                level: 'error',
            });
            setLoading(false);
        })
    };

    const fetchData = useCallback(
    ({ pageSize, pageIndex }) => {
        // console.log("fetchData is being called")
        // This will get called when the table needs new data
        // Give this fetch an ID
        const fetchId = ++fetchIdRef.current;
        if (fetchId === fetchIdRef.current) {
            fetchAPIData({
                limit: pageSize,
                skip: pageSize * pageIndex,
                search: searchTerm,
            });
        }
    },
    [sid, searchTerm]
    );

    const handleSearch = debounce((search) => {setSearchTerm(search)}, 1500, {maxWait: 1500});

    const languageColumnSortFunction = React.useMemo(() => {
        return (rowA, rowB, columnId, desc) => {
            let voiceLanguage = rowA.values.voiceId.substring(0, 3);
            let ttsLanguage = ttsLanguages.filter(a => a.code === voiceLanguage)[0];
            const a = t(ttsLanguage.name);
            voiceLanguage = rowB.values.voiceId.substring(0, 3);
            ttsLanguage = ttsLanguages.filter(a => a.code === voiceLanguage)[0];
            const b = t(ttsLanguage.name);
            return (a < b ? -1 : (a > b ? 1 : 0)); 
        }
    }, [])

    const booleanColumnSortFunction = React.useMemo(() => {
        return (rowA, rowB, columnId, desc) => {
            const a = rowA.values[columnId] ? 1 : 0;
            const b = rowB.values[columnId] ? 1 : 0;
            return (a < b ? -1 : (a > b ? 1 : 0)); 
        }
    }, [])

    const columns = useMemo(() => [
        { Header: t("Action"), accessor: "", show: true, disableSortBy: true, 
            Cell: (cellInfo) => { return (
                <div>
                    <div className="row m-1">
                        {/*<button className="btn btn-info btn-sm" onClick={() => navigate('/EditPresenter/' + cellInfo.row.original.number)}>{t('Edit')}</button>*/}
                        <Button type="primary" size="small" onClick={() => navigate('/EditPresenter/' + cellInfo.row.original.number)}>{t('Edit')}</Button>
                    </div>
                    {/*<div className="row m-1">
                        <button className="btn btn-info btn-sm" onClick={() => deletePresenter(cellInfo.row.original.number)}>{t('Delete')}</button>
                    </div>*/}
                </div>
            ) }
        },
        { Header: t("Number"), accessor: "number", show: true },
        { Header: t("Name"), accessor: "name", show: true },
        { Header: t("Gender"), accessor: "sex", show: true },
        { Header: t("Language"), accessor: "language", show: true,
            Cell: (cellInfo) => { 
                const voiceLanguage = cellInfo.row.original.voiceId.substring(0, 3);
                const ttsLanguage = ttsLanguages.filter(a => a.code === voiceLanguage)[0];
                return t(ttsLanguage.name);
            },
            sortType: languageColumnSortFunction,
        },
        { Header: t("Voice_Id"), accessor: "voiceId", show: true },
        { Header: t("Clone_Voice"), accessor: "cloneVoice", show: true,
            Cell: (cellInfo) => { return cellInfo.row.original.cloneVoice ? t('yes') : t('no') },
            sortType: booleanColumnSortFunction,
        },
        { Header: t("Use_Avatar"), accessor: "useAvatar", show: true,
            Cell: (cellInfo) => { return cellInfo.row.original.useAvatar ? t('yes') : t('no') },
            sortType: booleanColumnSortFunction,
        },
        { Header: t("Avatar_Image"), accessor: "imageFile", show: true, disableSortBy: true, 
            Cell: (cellInfo) => {
                if (cellInfo.row.original.useAvatar && cellInfo.row.original.imageFile.data) {
                    return (
                        <img src={URL.createObjectURL(getFileFromBuffer(cellInfo.row.original.imageFile))} alt={cellInfo.row.original.name} width="64" />
                    );
                } else {
                    return '';
                }
            },
        },
        { Header: t("Use_Moving_Head"), accessor: "useMovingHead", show: true,
            Cell: (cellInfo) => { return cellInfo.row.original.useAvatar ? (cellInfo.row.original.useMovingHead ? t('yes') : t('no')) : '' },
            sortType: booleanColumnSortFunction,
        },
        { Header: t("Avatar_Shape"), accessor: "avatarShape", show: true,
            Cell: (cellInfo) => { return cellInfo.row.original.useAvatar ? cellInfo.row.original.avatarShape : '' },
        },
        { Header: t("Avatar_Location"), accessor: "avatarLocation", show: true, 
            Cell: (cellInfo) => { return cellInfo.row.original.useAvatar ? cellInfo.row.original.avatarLocation : '' },
        },
        { Header: t("Avatar_Height"), accessor: "avatarHeight", show: true, 
            Cell: (cellInfo) => { return cellInfo.row.original.useAvatar ? cellInfo.row.original.avatarHeight + "%" : '' }
        },
    ]);
/*
    const newPresenter = () => {
        setOption({ presenterDialog: false, newPresenterDialog: true })
    }

    const editPresenter = (number) => {
        setOption({ presenterDialog: false, editPresenterDialog: true, number: number })
    }
*/
    return (
        <VpsHeader {...props}>
            {!authenticated ? (
                <div className="row m-3">
                    { t("Sign_in_to_edit_presenter") }
                </div>
            ) : (
                <div className="flex mx-2 mb-2 flex-col">
                    <div className="row">
                        <div className="col-6">
                            {/*<button className="btn btn-info btn-sm" onClick={() => navigate('/NewPresenter')}>{t('New_Presenter')}</button>*/}
                            <Button type="primary" size="small" onClick={() => navigate('/NewPresenter')}>{t('New_Presenter')}</Button>
                        </div>
                        <div className="col-6">
                            <Input
                                id="searchTerm"
                                name="searchTerm"
                                type="text"
                                onChange={(e) => handleSearch(e.target.value)}
                                placeholder={ t("Search_by_Name") }
                                addonBefore={<SearchOutlined />}
                            />
                        </div>
                    </div>

                    <div className="flex justify-center mt-2">
                        <Table
                            pageCount={pageCount}
                            fetchData={fetchData}
                            columns={columns}
                            loading={loading}
                            data={data}
                            customPageSize={options.pageSize ?? 5}
                            setOption={setOption}
                        />
                    </div>
                </div>
            )}
        </VpsHeader>
    );

}
