import styled from 'styled-components';
import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Table } from 'react-virtualized';
import TextareaAutosize from 'react-textarea-autosize';
import unescape from 'lodash/unescape';
import debounce from 'lodash/debounce';
import { t } from 'react-i18nify';
import { MdAdd, MdRemove, MdPlayForWork, MdSettingsEthernet, MdKeyboardDoubleArrowDown } from "react-icons/md";
import { Select, TimePicker } from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { MAX_TEXT_SIZE1, PRESENTER_OPTIONS } from '../utils';

dayjs.extend(customParseFormat);

const Style = styled.div`
    position: relative;
    box-shadow: 0px 5px 25px 5px rgb(0 0 0 / 85%);
    background-color: rgb(0 0 0 / 75%);
    padding-bottom: 10px;

    .ReactVirtualized__Table {
        .ReactVirtualized__Table__Grid {
            outline: none;
        }

        .ReactVirtualized__Table__row {
            .item {
                height: 100%;
                padding: 10px;

                .textarea {
                    border: none;
                    width: 100%;
                    height: 100%;
                    color: #fff;
                    font-size: 15px;
                    padding: 10px;
                    text-align: start;
                    background-color: rgba(255, 255, 255, 0.15);
                    border: 1px solid rgba(255, 255, 255, 0.1);
                    transition: all 0.2s ease;
                    resize: none;
                    outline: none;

                    &.highlight {
                        background-color: rgb(0 87 158);
                        border: 1px solid rgba(255, 255, 255, 0.3);
                    }

                    &.illegal {
                        background-color: rgb(123 29 0);
                        border: 1px solid rgba(255, 255, 255, 0.3);
                    }
                }

                .op {
                    font-size: 12px;
                    cursor: pointer;
                    margin-top: 5px;
                    margin-right: 10px;
                    color: #fff;
                }
            }
        }
    }
`;

export default function VideoSubtitles({ currentIndex, subtitle, checkSub, videoPlayer, updateSub, addSub, removeSub, 
    mergeSub, shiftSubsToSolveConflict, authenticated, userPresenters }) {
        
    const [height, setHeight] = useState(100);
    const [width, setWidth] = useState(document.body.clientWidth * 0.42);
    const [myPresenters, setMyPresenters] = useState([]);
    const tableRef = useRef();
    const timeFormat = 'HH:mm:ss.SSS';
    
    useEffect(() => {
        document.getElementById('subtitle_panel');
    }, []);

    useEffect(() => {
        if (authenticated && userPresenters.length) {
            setMyPresenters(userPresenters);
        } else {
            setMyPresenters(PRESENTER_OPTIONS);
        }
    }, [authenticated, userPresenters]);

    const resize = useCallback(() => {
        //setHeight(document.body.clientHeight - (!carouselData ? 280 : 50));
        //setWidth(Math.floor(document.body.clientWidth * 0.45));    //45% of the screen width
        setHeight(document.body.clientHeight - 185);
        setWidth(Math.floor(document.getElementById('subtitle_panel').clientWidth - 48));
        tableRef.current?.recomputeRowHeights();
    }, [setHeight, setWidth, tableRef]);

    useEffect(() => {
        resize();
        if (!resize.init) {
            resize.init = true;
            const debounceResize = debounce(resize, 500);
            window.addEventListener('resize', debounceResize);
        }
    }, [resize]);

    const getCharPerSecond = (value) => {
        if (!value) value = "1";
        return myPresenters.filter(x => x.key === parseInt(value))[0]?.charPerSecond;
    }

    const onRowClick = (event, rowData) => {
        if (event.target.id !== "choose-file") {
            videoPlayer.current.pause();
            if (videoPlayer.current.duration >= rowData.startTime) {
                videoPlayer.current.currentTime = rowData.startTime + 0.001;
                //setCurrentTime(videoPlayer.current.currentTime);
            }
        }
    }
    //const onRowClick2 = debounce(onRowClick, 500);

    const getTimeString = (time) => {
        //console.log(time);
        return `${time.$H.toString().padStart(2, '0')}:${time.$m.toString().padStart(2, '0')}:${time.$s.toString().padStart(2, '0')}.${time.$ms}`
    }
  
    return (
        <Style className="subtitles">
            <Table ref={tableRef}
                headerHeight={20}
                width={width}
                height={height}
                rowHeight={180}
                scrollToIndex={currentIndex}
                rowCount={subtitle.length}
                rowGetter={({ index }) => subtitle[index]}
                headerRowRenderer={() => null}
                rowRenderer={(props) => {
                    return (
                        <div
                            key={props.key}
                            className={props.className}
                            style={props.style}
                            onClick={(event) => onRowClick(event, props.rowData)}
                        >
                            <div className="item">
                                <div className="flex flex-row justify-start">
                                    <span className='op' title={t('Add_Subtitle')}>
                                        <MdAdd size={20} onClick={() => addSub(props.index + 1, null, true)} />
                                    </span>
                                    <span className='op' title={t('Delete_Subtitle')}>
                                        <MdRemove size={20} onClick={() => removeSub(props.rowData, true)} />
                                    </span>
                                    <span className='op' title={t('Merge_Subtitle')}>
                                        <MdPlayForWork size={20} onClick={() => mergeSub(props.rowData)} />
                                    </span>
                                    <span className='op' title={t('Shift_Subtitles')}>
                                        <MdKeyboardDoubleArrowDown size={20} onClick={() => shiftSubsToSolveConflict(props.rowData)} />
                                    </span>
                                    {authenticated && (
                                        <span className='op' title={t('Fit_Subtitle')}>
                                            <MdSettingsEthernet size={20} onClick={() => 
                                                updateSub(props.rowData, {charPerSecond: getCharPerSecond(props.rowData.presenter)})} 
                                            />
                                        </span>
                                    )}
                                    {/*<MdOutlinePerson size={20} style={{ marginRight: "5px" }}/>*/}
                                    <Select popupMatchSelectWidth={false} showSearch value={String(props.rowData.presenter)} 
                                        onChange={value => {updateSub(props.rowData, { 
                                            presenter: value,
                                            charPerSecond: getCharPerSecond(value),
                                        })
                                    }}>
                                        {myPresenters.map((item) => (
                                            <Select.Option key={item.key} value={String(item.key)}>{item.key + ', ' + item.name + (item.voice ?  ', ' + item.voice : '')}</Select.Option>
                                        ))}
                                    </Select>
                                </div>
                                <div>
                                    <TimePicker.RangePicker format={timeFormat} 
                                    value={[dayjs(props.rowData.start, timeFormat), dayjs(props.rowData.end, timeFormat)]} 
                                    onChange={(t) => updateSub(props.rowData, {start: getTimeString(t[0]), end: getTimeString(t[1])}, true)} />
                                </div>
                                <TextareaAutosize
                                    maxLength={MAX_TEXT_SIZE1}
                                    minRows={3}
                                    maxRows={3}
                                    spellCheck={false}
                                    className={[
                                        'textarea',
                                        currentIndex === props.index ? 'highlight' : '',
                                        Math.floor(props.rowData.endTime) > videoPlayer.current?.duration || checkSub(props.rowData) ? 'illegal' : '',
                                    ].join(' ').trim()}
                                    value={unescape(props.rowData.text)}
                                    onChange = {(event) => {
                                        updateSub(props.rowData, {
                                            text: event.target.value,
                                            charPerSecond: getCharPerSecond(props.rowData.presenter),
                                        }, true);
                                    }}
                                />
                            </div>
                        </div>
                    );
                }}
            ></Table>
        </Style>
    );
}
