import React from 'react';
import { useNavigate } from 'react-router-dom';
import { t } from 'react-i18nify';
import { Result, Button } from 'antd';
import VpsHeader from './VpsHeader';
import VpsFooter from './VpsFooter';

export default function PresenterResultSuccess(props) {
    const navigate = useNavigate();
 
    return (
        <VpsHeader {...props}>
            <Result
                status="success"
                title={props.resultMessage}
                extra={[
                    <Button type="primary" key="console" onClick={() => navigate('/Presenter')}>
                        {t('Go_Presenter')}
                    </Button>,
                ]}
            />
            <VpsFooter {...props} />
        </VpsHeader>
    );
}
