import React from 'react';
import { t } from 'react-i18nify';
import { Result } from 'antd';
import VpsHeader from './VpsHeader';
import VpsFooter from './VpsFooter';

export default function NotFound(props) {
 
    return (
        <VpsHeader {...props}>
            <Result
                status="404"
                title="404"
                subTitle={t('PageNotFound')}
            />
            <VpsFooter {...props} />
        </VpsHeader>
    );
}
